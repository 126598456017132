import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

import { notification } from 'shared/ui/GlobalNotification';

export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => next => action => {
        if (isRejectedWithValue(action)) {
            const { status } = action.payload;
            const { errors } = action.payload.data;
            /*notification.error({
                message: `HTTP [${status}] - Ошибка`,
                // description: `<p>${errors}<p>`,
                description: (
                    <div>
                        <pre>{JSON.stringify(errors, null, 1)}</pre>
                    </div>
                ),
                style: { minWidth: '600px' },
            });*/
        }

        return next(action);
    };
