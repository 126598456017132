import { Checkbox, Flex, Space, Typography } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { GenericModal } from 'shared/ui/GenericModal';
import { usePaySubscriptionMutation } from 'features/subscriptions/services';

import { ISubscription } from '../../interfaces';

type TProps = {
    readonly open: boolean;
    readonly setOpen: Dispatch<SetStateAction<boolean>>;
    readonly expirationDate: number;
    readonly subscription: ISubscription;
};

export const PaymentConfirmation: React.FC<TProps> = props => {
    const { open, setOpen, subscription } = props;

    const [expDate, setExpDate] = useState(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [paySubscription, { isLoading }] = usePaySubscriptionMutation();

    const handlePaySubscription = async () => {
        try {
            const data: { data } = await paySubscription(
                subscription.id
            ).unwrap();
            window.location.replace(data.data.redirect);
        } catch (e) {
            console.error(e);
        } finally {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (props?.expirationDate) {
            const addDate = subscription?.period_days * 86400;
            const newDate = props?.expirationDate + addDate;
            setExpDate(dayjs.unix(newDate).format('DD.MM.YYYY'));
        }
    }, [props?.expirationDate]);

    return (
        <GenericModal
            title='Подтвердите подписку'
            width={380}
            open={open}
            okText='Оплатить'
            okButtonProps={{ disabled: !checked, loading: isLoading }}
            onCancel={() => {
                setOpen(false);
            }}
            onConfirm={handlePaySubscription}
        >
            <Typography.Text>
                Пакет услуг «{subscription?.name}» до {expDate || ''}
            </Typography.Text>
            <Typography.Title level={2}>
                {subscription?.price}₽
            </Typography.Title>

            <Flex align={'flex-start'}>
                <Checkbox
                    onChange={({ target }) => {
                        setChecked(target.checked);
                    }}
                    checked={checked}
                />
                <Typography.Text style={{ marginLeft: '.75rem' }}>
                    Оплачивая подписку, вы принимаете условия оплаты и её
                    автоматического продления, указанные в оферте
                </Typography.Text>
            </Flex>

            <Space
                direction={'vertical'}
                size={'large'}
                style={{
                    marginTop: '2rem',
                    marginLeft: '1.75rem',
                }}
            >
                <Typography.Text style={{ marginBottom: 0 }}>
                    Вы будете перенаправлены на страницу платёжной системы.
                </Typography.Text>
            </Space>
        </GenericModal>
    );
};
