export const messages = {
    success: 'Файл выгрузки прийдет вам на почту в течении 10 мин',
    error: 'Произошла ошибки при выгрузке',
    invalidDates: 'Сначала нужно выбрать даты',
};

export const page = {
    button: 'Выгрузить заказы',
    hint: 'Раздел находится в разработке, но пока вы можете выгрузить все ваши заказы.',
};
