import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    App,
    Button,
    Divider,
    Flex,
    Form,
    Input,
    InputNumber,
    Switch,
    Typography,
} from 'antd';

import { useCreateFeedMutation } from '../../services/shops';

const { Text } = Typography;

export const FeedsSettingsContainer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const [createFeed, { data, isLoading, isError, isSuccess, error }] =
        useCreateFeedMutation();

    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

    const [form] = Form.useForm();
    const values = Form.useWatch([], form);

    const initial = {
        feed_frequency: 24,
    };

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Фид успешно подключен',
                description: 'Загрузка может занять до суток.',
            });
            handleCancel();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            {
                Object.entries(error?.data.errors).map(([key, messages]) => (
                    <Fragment key={key}>
                        {messages.map((message, index) =>
                            notification.error({
                                message: message,
                            })
                        )}
                    </Fragment>
                ));
            }
        }
    }, [isError]);

    const handleSave = async () => {
        const payload = {
            id: +id,
            feed_url: values.feed_url,
            feed_frequency: values.feed_frequency,
        };

        await createFeed(payload);
    };
    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Flex vertical gap={24}>
            <Text style={{ marginTop: 24 }}>
                Система принимает фиды в формате yml.
            </Text>

            <Form
                wrapperCol={{
                    xs: 24,
                    md: 16,
                }}
                form={form}
                name='feeds'
                initialValues={initial}
                layout={'vertical'}
                autoComplete='off'
                requiredMark={false}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                    label='Ссылка на товарный фид'
                    name='feed_url'
                >
                    <Input required placeholder={'Укажите ссылку на фид'} />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                    label='Частота обновления (часы)'
                    name='feed_frequency'
                >
                    <InputNumber required maxLength={5} max={99999} min={1} />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }} name='feed_frequency'>
                    <Flex vertical={false} gap={8}>
                        <Switch defaultChecked /> <Text>Активировать фид</Text>
                    </Flex>
                </Form.Item>
            </Form>

            <Divider style={{ margin: 0 }} />

            <Flex vertical={false} gap={24}>
                <Button
                    disabled={!isSubmittable}
                    type={'primary'}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
                <Button onClick={handleCancel}>Отмена</Button>
            </Flex>
        </Flex>
    );
};
