import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authApi } from 'features/auth/services';
import { userApi } from 'features/user/services';
import { subscriptionsApi } from 'features/subscriptions/services';

import rootReducer from './rootReducer';
import { rtkQueryErrorLogger } from './errorMiddleware';
import { productsApi } from '../features/shops/services/products';
import { shopsApi } from '../features/shops/services/shops';
import { productsCategoriesApi } from '../features/shops/services/categories';
import { legalEntityApi } from '../features/user/services/legalEntity';
import { promocodeApi } from '../features/shops/services/promocode';

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            rtkQueryErrorLogger,
            authApi.middleware,
            userApi.middleware,
            subscriptionsApi.middleware,
            productsApi.middleware,
            shopsApi.middleware,
            productsCategoriesApi.middleware,
            legalEntityApi.middleware,
            promocodeApi.middleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
