import React, { FC, Fragment } from 'react';
import { Checkbox, Divider, Space } from 'antd';

type Props = {
    readonly setConfirm: (boolean) => void;
    readonly confirm: boolean;
};

export const Confirm: FC<Props> = props => {
    return (
        <Fragment>
            <Divider style={{ margin: '20px 0' }} />
            <Space size='large' direction='vertical'>
                <div>
                    <Checkbox
                        checked={props.confirm}
                        onChange={() => props.setConfirm(!props.confirm)}
                    >
                        Я понимаю последствия
                    </Checkbox>
                </div>
            </Space>
        </Fragment>
    );
};
