import React, { useEffect } from 'react';

import { Page } from '../../../ui/Page';

const PersonalDataPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={'Политика обработки персональных данных и файлов Cookie'}>
            <p>
                Настоящая Политика обработки персональных данных разработана во
                исполнение требований Федерального закона Российской Федерации
                от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;,
                Общего регламента ЕС о защите данных 2016/679, Европейского
                парламента и Европейского Совета от 27 апреля 2016 года о защите
                физических лиц в отношении обработки их Персональных данных и о
                свободном перемещении таких данных (GDPR) и принятыми в
                соответствии с ним иными нормативными правовыми актами.
            </p>
            <ul>
                <li>
                    <strong>
                        <strong>Термины и их определения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Политика</strong> &ndash; настоящая Политика
                    обработки персональных данных и файлов Cookie, размещенная в
                    сети Интернет по адресу{' '}
                    <a href='https://spodial.com/privacy_policy'>
                        https://spodial.com/privacy_policy
                    </a>
                </li>
                <li>
                    <strong>Мы</strong>, <strong>Нас</strong>,{' '}
                    <strong>Наше</strong> и пр., а также{' '}
                    <strong>Оператор</strong> &ndash;{' '}
                    <strong>Saas-провайдер </strong>&ndash; Общество с
                    ограниченной ответственностью &laquo;Фабрика информационных
                    технологий&raquo; (ООО &laquo;ФИТ&raquo;), ИНН: 3123164163,
                    ОГРН: 1073123023160, адрес для направления почтовой
                    корреспонденции: 308033, г. Белгород, ул. Королева, д 2А,
                    пом. 8, адрес электронной почты:{' '}
                    <a href='mailto:info@spodial.com'>info@spodial.com</a>.
                </li>
                <li>
                    <strong>Вы</strong>, <strong>Ваш</strong>,{' '}
                    <strong>Ваше</strong> и пр., а также{' '}
                    <strong>Субъект персональных данных</strong> &ndash; наш
                    контрагент по Пользовательскому соглашению, которым может
                    быть физическое лицо, которое использует Платформу, в
                    порядке и на условиях, установленных Соглашением.
                </li>
                <li>
                    <strong>Пользовательское соглашение</strong> &ndash; оферта,
                    договор на условиях которой считается заключенным между
                    Оператором и Посетителем / Пользователем / Покупателем после
                    выражения ими акцепта Соглашения, который регулирует условия
                    использования Платформы, размещенный по адресу в сети
                    Интернет:{' '}
                    <a href='https://spodial.com/user_agreement'>
                        https://spodial.com/user_agreement
                    </a>
                </li>
                <li>
                    <strong>Персональные данные</strong> &ndash; любая
                    информация, которая относится к идентифицированному или
                    идентифицируемому физическому лицу. Идентифицируемое лицо
                    &ndash; это лицо, которое может быть идентифицировано прямо
                    или косвенно, в частности, посредством ссылки на такие
                    идентификаторы, как имя, фамилия, отчество,
                    идентификационный номер, данные о местоположении, сетевой
                    идентификатор, или на один или несколько факторов,
                    специфичных для физической, физиологической, генетической,
                    психической, экономической, культурной или социальной
                    идентичности этого физического лица, а также информацию о
                    Пользователях, которую мы получаем от третьих лиц.
                </li>
                <li>
                    <strong>Покупатель</strong> &ndash; физическое лицо,
                    покупатель товара у Продавца.
                </li>
                <li>
                    <strong>Продавец</strong> &ndash; Пользователь Платформы,
                    который приобрел Пакет услуг &laquo;Онлайн магазин&raquo;, и
                    использует ставший доступным функционал разрешенным
                    настоящим Соглашением способом для реализации Товаров
                    Покупателям.
                </li>
                <li>
                    <strong>Обработка персональных данных</strong> &ndash; любое
                    действие (операция) или совокупность действий (операций),
                    совершаемых с использованием средств автоматизации или без
                    использования таких средств с персональными данными, включая
                    сбор, запись, систематизацию, накопление, хранение,
                    уточнение (обновление, изменение), извлечение,
                    использование, передачу (распространение, предоставление,
                    доступ), Обезличивание, блокирование, удаление, уничтожение
                    персональных данных. Обработка персональных данных ведется
                    методом смешанной (в том числе автоматизированной)
                    обработки.
                </li>
                <li>
                    <strong>
                        Автоматизированная обработка персональных данных
                    </strong>{' '}
                    &ndash; обработка персональных данных с помощью средств
                    вычислительной техники.
                </li>
                <li>
                    <strong>Конфиденциальность персональных данных</strong> -
                    обязательное для Нас или иным получившим доступ к
                    Персональным данным лицом требование не допускать их
                    распространения без согласия Субъекта персональных данных
                    или иного законного основания.
                </li>
                <li>
                    <strong>Обезличивание персональных данных</strong> &ndash;
                    действия, в результате которых становится невозможным без
                    использования дополнительной информации определить
                    принадлежность персональных данных к конкретному субъекту
                    персональных данных.
                </li>
                <li>
                    <strong>Платформа </strong>&ndash; принадлежащий
                    SaaS-провайдеру программно-аппаратный комплекс{' '}
                    <strong>&laquo;Spodial&raquo; </strong>по адресу:{' '}
                    <a href='https://spodial.com'>https://spodial.com</a>, а
                    также отдельный функционал в мессенджере Telegram
                    @SpodialBot.
                </li>
                <li>
                    <strong>Cookies </strong>&ndash; небольшой текстовый файл,
                    размещаемый Платформой на Вашем компьютере или устройстве,
                    когда Вы, например, посещаете определённые разделы Платформы
                    и/или когда Вы используете определенные функции Платформы.
                </li>
                <li>
                    <strong>ФЗ № 152-ФЗ </strong>- Федеральный закон Российской
                    Федерации от 27.07.2006 № 152-ФЗ &laquo;О персональных
                    данных&raquo;.
                </li>
                <li>
                    <strong>GDPR</strong> или <strong>Регламент</strong> &ndash;
                    Общий регламент ЕС о защите данных 2016/679, Европейского
                    парламента и Европейского Совета от 27 апреля 2016 года о
                    защите физических лиц в отношении обработки их Персональных
                    данных и о свободном перемещении таких данных, отменяющий
                    Директиву 95/46/ЕС с внесенными в нее поправками,
                    изменениями и дополнениями, применяемыми периодически и
                    включенными в национальное законодательство
                    стран&ndash;участниц.
                </li>
                <li>
                    В Политике могут быть использованы термины, не определенные
                    в Разделе 1 настоящей Политики. В случае отсутствия
                    однозначного толкования термина в тексте Политики следует
                    руководствоваться толкованием термина, определенным: в
                    первую очередь &ndash; Пользовательским соглашением, далее
                    &ndash; действующим законодательством Российской Федерации,
                    во вторую очередь - на Платформе, и, далее, - сложившимся
                    (общеупотребимым) в сети Интернет.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Предмет и основания Обработки персональных данных
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Предмет Политики</strong>. Положения настоящей
                    Политики применяются к отношениям между Нами и Вами,
                    связанным с Обработкой персональных данных в рамках
                    возникающих между Нами отношений по использованию Платформы,
                    складывающимся в результате принятия Вами условий
                    Пользовательского соглашения.&nbsp;
                </li>
                <li>
                    <strong>Основания обработки</strong>. Основанием Обработки
                    Ваших персональных данных всегда является Ваше согласие. Без
                    согласия с условиями настоящей Политики, мы не будем
                    способны в полной мере обеспечить исполнения обязательств по
                    заключаемым с Вами договорам (включая, но не ограничиваясь,
                    Пользовательским соглашением).
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Принятие условий Политики</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    В настоящем разделе Вы можете узнать о действиях, совершение
                    которых означает Ваше согласие на Обработку Ваших
                    персональных данных (далее - &laquo;
                    <strong>Согласие на обработку ПДн</strong>&raquo;).&nbsp;
                </li>
                <li>
                    <strong>Содержание Согласия на обработку ПДн</strong>.
                    Предоставляя Согласие на обработку ПДн, Вы подтверждаете,
                    что полностью ознакомились и согласны с Пользовательским
                    соглашением, приложениями к нему, Политикой обработки
                    персональных данных и файлов Cookie, и предоставляете
                    конкретное, предметное, информированное, сознательное и
                    однозначное согласие на обработку Ваших персональных
                    данных.&nbsp;
                </li>
                <li>
                    Пожалуйста, обратите внимание, что Согласие на обработку ПДн
                    означает, что Вы, помимо прочего, выражаете свое согласие на
                    Автоматизированную, а также без использования средств
                    Автоматизации, Обработку Ваших Персональных данных, на
                    условиях, указанных в Политике, а также в Согласии на
                    обработку персональных данных.
                </li>
                <li>
                    <strong>Регистрация</strong>. Если Вы проходите процедуру
                    Регистрации на Платформе, то согласием с условиями настоящей
                    Политики будет считаться предоставленным после того, как Вы
                    нажмете на кнопку &laquo;Зарегистрироваться&raquo;. После
                    этого процедура Регистрации считается завершенной, и Вы
                    получаете статус &laquo;Пользователь&raquo;, и доступ к
                    онлайн-интерфейсам Личного кабинета. Повторно обращаем Ваше
                    внимание, что в соответствии с условиями Соглашения, не
                    допускается завершение процедуры Регистрации до того, как Вы
                    ознакомитесь и примите в полном объеме положения Соглашения
                    и всех приложений к нему, включая настоящую Политику. Таким
                    образом, завершая процедуру Регистрации, Вы считаетесь
                    предоставившим Нам Согласие на обработку ПДн,в объеме,
                    указанном в настоящей Политике.
                </li>
                <li>
                    <strong>Заказ Товара</strong>. Если Вы являетесь
                    Покупателем, и желаете оформить заказ в отношении Товара, то
                    Вы считаетесь предоставившими Нам согласие на обработку ПДн
                    в объеме, указанном в настоящей Политике, если Вы в любой
                    форме использовали форму заказа Товаров (в отношении
                    IP-адреса и данных об устройстве), либо нажали кнопку в
                    разделе &laquo;Корзина&raquo;, завершающую оформление заказа
                    (в отношении прочих ПДн).
                </li>
                <li>
                    <strong>Ознакомление с содержимым Платформы. </strong>Если
                    Вы просто просматриваете Платформу, Вы признаетесь
                    Посетителем (Платформы), и считаетесь предоставляющими Нам
                    Согласие на обработку ПДн, в объеме, указанном в настоящей
                    Политике (технические данные, файлы Cookies (если иной
                    порядок согласия в отношении обработки файлов Cookies не
                    реализован на Платформе).
                </li>
                <li>
                    <strong>Предоставляемая Вами информация</strong>. В том
                    случае, если Вы предоставляете Нам свои Персональные данные
                    самостоятельно (например, заполняете соответствующие поля в
                    Личном кабинете, или пишите нам на адрес электронной почты),
                    то Вы считаетесь предоставившими Согласие на обработку ПДн в
                    момент такого предоставления Ваших Персональных данных
                    (например, когда Мы получили от Вас электронной
                    письмо).&nbsp;
                </li>
                <li>
                    <strong>Заказ обратного звонка</strong>. Если Вы заказываете
                    обратный звонок путем использования соответствующего
                    функционала Платформы (при наличии), то Вы считаетесь
                    предоставившими Согласие на обработку ПДн в момент клика на
                    кнопку &laquo;Заказать звонок&raquo; (или аналог).&nbsp;
                </li>
                <li>
                    <strong>Совершение исходящего звонка</strong>. Совершая
                    звонок Оператору и продолжая разговор с сотрудником
                    Оператора, Вы предоставляете Согласие на обработку ПДн.
                </li>
                <li>
                    <strong>Обмен сообщениями</strong>. Если Вы отправляете Нам
                    сообщение (в мессенджер или на Сайте, в том числе, заполняя
                    форму Сообщения о нарушениях), в том числе, с использованием
                    соответствующего функционала Платформы, то Ваше Согласие на
                    обработку ПДн считается предоставленным в момент отправления
                    в Наш адрес первого сообщения.
                </li>
                <li>
                    <strong>Хранение информации о согласии</strong>. Обратите
                    внимание, что Мы можем хранить данные о Ваших действиях
                    (путем системы логирования Ваших действий, или аналога),
                    подтверждающих Ваше Согласие с ПДн, как это указано в
                    настоящем разделе Политики, на протяжении всего срока
                    существования Личного кабинета, а также в течение 3 (Трех)
                    лет после его удаления, если иной срок не предусмотрен
                    законодательством.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Срок политики и обработки персональных данных
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Срок действия Политики</strong>. После принятия
                    условий Политики, Политика действует бессрочно. Однако это
                    не влияет на срок Обработки Ваших персональных данных - он
                    устанавливается настоящей Политикой.
                </li>
                <li>
                    <strong>Срок обработки персональных данных</strong>. Срок
                    Обработки Персональных данных зависит от категории
                    Персональных данных, и определяется в соответствующем
                    разделе настоящей Политики. В остальных случаях мы прекратим
                    обработку Ваших Персональных данных, если Вы возражаете
                    против такой обработки или если Вы отозвали свое ранее
                    данное согласие на обработку, в соответствии с условиями
                    настоящей Политики и положениями законодательства. Однако
                    при этом Мы можем оказаться неспособными исполнять
                    обязательства по договорам с Вами.
                </li>
                <li>
                    <strong>Изменение</strong>. Политика может быть изменена
                    Нами в любой момент. В том числе, это может быть связано с
                    изменением Наших бизнес&ndash;процессов, влиянием внешних
                    факторов и иными причинами. После внесения изменений мы
                    немедленно публикуем измененную Политику на Платформе, чтобы
                    у Вас была возможность изучить ее в новой редакции. В том
                    случае, если Вы продолжаете использование Платформы после
                    внесения изменений в Политику, мы безусловно считаем, что Вы
                    согласны с условиями Политики в новой редакции.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Основания разработки, цели, принципы Политики&nbsp;
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Основания разработки</strong>. Политика разработана
                    во исполнение требований законов и подзаконных актов,
                    определяющих случаи и особенности обработки персональных
                    данных Субъекта персональных данных, включая ФЗ № 152-ФЗ,
                    GDPR.
                </li>
                <li>
                    <strong>Цели</strong>. Политика преследует следующие цели:
                    (1) обеспечение требований защиты прав и свобод человека и
                    гражданина при обработке персональных данных, в том числе
                    защиты прав на неприкосновенность частной жизни, личную и
                    семейную тайну, (2) исключение несанкционированных действий
                    (неправомерный или случайный доступ) любых третьих лиц к
                    Вашим Персональным данным, а равно уничтожения, изменения,
                    блокирования, копирования и распространения персональных
                    данных, (3) обеспечение правового и нормативного режима
                    конфиденциальности и контроля Ваших Персональных данных, (4)
                    защита конституционных прав граждан на личную тайну,
                    конфиденциальность сведений, составляющих Персональные
                    данные, и предотвращение возникновения возможной угрозы
                    Вашей безопасности.&nbsp;
                </li>
            </ol>
            <p>
                Так, основной целью Политики является предоставить Вам полное и
                прозрачное понимание относительно: правовой основы для сбора и
                обработки Ваших Персональных данных; категорий Персональных
                данных, которые мы можем собирать о Вас; что происходит с
                Персональными данными, которые мы собираем; где мы обрабатываем
                Ваши Персональные данные; сколько мы храним Ваши Персональные
                данные; кому мы можем передавать Ваши Персональные данные; а
                также объяснить Ваши права как субъекта Персональных данных.
            </p>
            <ol>
                <li>
                    <strong>Принципы</strong>. Мы преследуем следующие принципы
                    Обработки персональных данных: (1) обработка Персональных
                    данных должна осуществляться на законной и справедливой
                    основе, (2) обработка персональных данных должна
                    ограничиваться достижением конкретных, заранее определенных
                    и законных целей; не допускается обработка Персональных
                    данных, несовместимая с целями сбора персональных данных,
                    (3) содержание и объем обрабатываемых персональных данных
                    должны соответствовать заявленным целям обработки;
                    обрабатываемые Персональные данные не должны быть
                    избыточными по отношению к заявленным целям их обработки,
                    (4) при Обработке персональных данных должны быть обеспечены
                    точность Персональных данных, их достаточность и
                    актуальность по отношению к целям Обработки персональных
                    данных, (5) хранение Персональных данных должно
                    осуществляться не дольше, чем этого требуют цели Обработки
                    персональных данных, если иной срок хранения персональных
                    данных (либо порядок его определения) не предусмотрен
                    Политикой.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Состав и срок хранения собираемых Персональных
                            данных
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Регистрационные данные. </strong>При прохождении
                    процедуры Регистрации на Платформе, Мы попросим Вас
                    предоставить следующие Ваши Регистрационные данные: (1)
                    адрес электронной почты, (2) имя. Пожалуйста, обратите
                    внимание, что Вам не обязательно указывать ваше настоящее
                    имя в момент Регистрации. Эти данные нам необходимы для
                    того, чтобы исполнить свои обязательства по
                    Пользовательскому соглашению (например, создать Личный
                    кабинет). Мы храним эти данные на протяжении всего срока
                    существования Вашего Личного кабинета на Платформе, а также
                    в течение 3 (Трех) лет после его удаления.
                </li>
                <li>
                    <strong>Указываемые Вами данные на Платформе. </strong>Если
                    после Регистрации Вы указываете Ваши Персональные данные в
                    Личном кабинете, Вы тем самым предоставляете Нам Согласие на
                    обработку ПДн в отношении таких данных. Такими ПДн могут
                    быть: (1) адрес аккаунта в Telegram; (2) Абонентский номер
                    мобильного телефона; (3) Пол; (4) Дата рождения; (5) Страна.
                    Мы храним эти данные на протяжении всего срока существования
                    Вашего Личного кабинета на Платформе, а также в течение 3
                    (Трех) лет после его удаления.
                </li>
                <li>
                    <strong>Данные покупателя</strong>. В том случае, если Вы
                    являетесь покупателем Товаров Продавца, то следующие Ваши
                    ПДн передаются изначально Нам, для того, чтобы Мы могли
                    впоследствии передать такие ПДн Продавцу для исполнения
                    обязательств последним перед Вами по правоотношениям,
                    связанным с реализацией / запроса сообщения о Товаре: (1)
                    Адрес аккаунта в Telegram; (2) Адрес доставки; (3)
                    Абонентский номер мобильного телефона; (4) Адрес электронной
                    почты. Мы храним эти данные до оформления Вами заказа, а
                    также в течение 3 (Трех) лет после совершения Вами заказа на
                    Платформе. Также мы предоставляем указанные ПДн нашим
                    Пользователям, с которыми Вы взаимодействовали в целях
                    заказа / приобретения Товара.
                </li>
                <li>
                    <strong>Предоставляемая Вами информация</strong>. Если Вы
                    предоставляете Нам свои ПДн, способами, указанными в разделе
                    3 настоящей Политики, или аналогичными способами, то мы
                    храним такие ПДн в течение срока, необходимого для связи с
                    Вами, либо обработки поступившего сообщения. Мы можем
                    хранить Ваши ПДн в течение 3 (Трех) месяцев для того, чтобы
                    обеспечить связь с Вами и(или) обработать полученное от Вас
                    сообщение.
                </li>
                <li>
                    <strong>Технические данные</strong>. Во время использования
                    Платформы Мы автоматически собираем некоторые Ваши
                    Персональные данные. К таким данным относятся, например,
                    техническая информация, в том числе адрес
                    Интернет&ndash;протокол (IP), используемый для подключения
                    Вашего компьютера к Интернету, данные для входа в систему,
                    тип и версия браузера, настройка часового пояса, типы и
                    версии плагинов для браузера, операционная система и
                    платформа, информация о Вашем посещении веб&ndash;сайтов и
                    др. Мы храним эти данные на протяжении Вашей сессии.&nbsp;
                </li>
                <li>
                    <strong>Иные данные</strong>. В исключительных случаях Мы
                    можем попросить Вас предоставить дополнительные Персональные
                    данные. Мы обязательно заранее предупредим Вас об этом и
                    попросим предоставить дополнительное согласие на Обработку
                    дополнительных Персональных данных.
                </li>
                <li>
                    <strong>Персональные данные третьих лиц</strong>. Просим Вас
                    учитывать, что, если Вы предоставляете нам Персональные
                    данные третьих лиц, то гарантируете, что получили от таких
                    лиц все необходимые согласия (включая согласие на передачу
                    персональных данных), иные документы, необходимые для
                    реализации положений настоящей Политики в полном объеме,
                    оформленные ими по форме и в соответствии с их личным
                    применимым законодательством (
                    <strong>Согласие третьих лиц</strong>), а если Вы выступаете
                    оператором персональных данных таких лиц &ndash; то также
                    гарантируете, что обеспечиваете порядок передачи и защиту их
                    персональных данных в объеме не меньшем, чем предусмотрено
                    настоящей Политикой. При этом Согласие третьих лиц должно
                    быть оформлено ими в письменном виде, оригинал которого
                    должен быть предоставлен Вами в срок, не позднее 7 (Семи)
                    календарных дней от дня отправления соответствующего запроса
                    от Оператора. Предоставление Персональных данных третьих лиц
                    без соблюдения настоящей гарантии недопустимо, и Вы
                    полностью принимаете на себя всю ответственность за
                    нарушение настоящей гарантии.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Права Субъекта персональных данных</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Получение информации</strong>. Вы вправе получить
                    сведения о Нас, о Нашем месте нахождения, о наличии у Нас
                    Ваших Персональных данных, а также на ознакомление с такими
                    Персональными данными.&nbsp;
                </li>
                <li>
                    <strong>Уточнение</strong>. Вы вправе требовать от Нас
                    уточнения своих Персональных данных, их блокирования или
                    уничтожения в случае, если Персональные данные являются
                    неполными, устаревшими, недостоверными, незаконно
                    полученными или не являются необходимыми для заявленной цели
                    обработки, а также принимать предусмотренные законом меры по
                    защите своих прав.
                </li>
                <li>
                    <strong>Форма предоставления</strong>. Сведения о наличии у
                    Нас Персональных данных будут Вам предоставлены в доступной
                    форме, и в них не будет содержаться Персональные данные,
                    относящиеся к другим Субъектам персональных данных.
                </li>
                <li>
                    <strong>Порядок доступа</strong>. Вы (либо Ваш законный
                    представитель) можете получить доступ к своим Персональным
                    данным при личном обращении, либо путем направления
                    письменного обращения. При этом запрос должен содержать
                    номер основного документа, удостоверяющего Вашу личность или
                    Вашего законного представителя, сведения о дате выдачи
                    указанного документа и выдавшем его органе и
                    собственноручную подпись. Запрос может быть направлен в
                    электронной форме. Мы обязаны ответить на Ваш запрос в
                    течение 30 (Тридцати) дней от даты его получения, который с
                    учетом сложности и количества запросов может быть продлен до
                    60 (Шестидесяти) дней.
                </li>
                <li>
                    <strong>Содержание ответа</strong>. В запросе на
                    предоставление доступа к Персональным данным Вы вправе
                    требовать получения от Нас информации, касающейся Обработки
                    Ваших Персональных данных, в том числе содержащей: (1)
                    подтверждение факта обработки Персональных данных, а также
                    цель такой обработки, (2) способы обработки Персональных
                    данных, (3) наименование и местонахождение Оператора,
                    сведения о лицах, которые имеют доступ к Персональным данным
                    или которым может быть предоставлен такой доступ, (4)
                    перечень обрабатываемых Персональных данных и источник их
                    получения, (5) сроки обработки Персональных данных, в том
                    числе сроки их хранения, (6) сведения о том, какие
                    юридические последствия для Субъекта персональных данных
                    может повлечь за собой Обработка его Персональных данных.
                </li>
                <li>
                    <strong>Отзыв согласия</strong>. Вы вправе отозвать согласие
                    на обработку Персональных данных, ограничить способы и формы
                    обработки Персональных данных, запретить распространение
                    Персональных данных без Вашего согласия.
                </li>
                <li>
                    <strong>Право на обжалование</strong>. Вы вправе обжаловать
                    Наши действия или бездействие в уполномоченный орган по
                    защите прав Субъектов персональных данных или в судебном
                    порядке.
                </li>
                <li>
                    <strong>Право на защиту</strong>. Вы имеете право на защиту
                    своих прав и законных интересов, в том числе на возмещение
                    убытков и компенсацию морального вреда в судебном порядке.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Права Субъекта персональных данных (GDPR)
                        </strong>
                    </strong>
                </li>
            </ul>
            <p>
                <strong>
                    <em>
                        Если Вы являетесь гражданином государства - члена
                        Европейской экономической зоны или Великобритании (либо
                        на законном основании предоставляете нам Персональные
                        данные такого лица), Вам также предоставляются
                        нижеуказанные права. Воспользоваться ими Вы можете,
                        направив соответствующее электронное письмо по Нашим
                        Контактным данным.
                    </em>
                </strong>
            </p>
            <ol>
                <li>
                    <strong>Право быть информированным </strong>
                    <em>(ст. 12&ndash;14 Регламента).</em> Вы имеете право
                    получать информацию о сборе и использовании ваших
                    Персональных данных, в частности о целях обработки этих
                    Персональных данных, сроках хранения и о том, кому они будут
                    переданы. Эта информация должна быть предоставлена в момент
                    сбора нами Ваших Персональных данных. Если мы получаем
                    Персональные данные из других источников, мы сообщим Вам об
                    этом в разумный срок после получения данных и не позднее
                    одного месяца, если только Вы уже не располагаете такой
                    информацией и если это не потребует непропорциональных
                    усилий для ее предоставления. Информация должна быть
                    краткой, прозрачной, понятной, легкодоступной, а также быть
                    выражена ясным и понятным языком, поэтому мы стараемся
                    подробно объяснить нашу политику обработки данных. Мы
                    доведем до Вашего сведения информацию о любом новом факте
                    использования Ваших Персональных данных до начала их
                    обработки.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Право на доступ </strong>
                    <em>(ст. 15 Регламента).</em> Вы имеете право получить от
                    Оператора подтверждение того, обрабатываются ли Персональные
                    данные, касающиеся Вас, и, в случае необходимости, доступ к
                    персональным данным и следующей информации: цели обработки;
                    категории соответствующих Персональных данных; получатели
                    или категории получателей, которым Персональные данные были
                    или будут раскрыты, в частности получатели из третьих стран
                    или международных организаций; если это возможно,
                    предусмотренный срок, в течение которого будут храниться
                    Персональные данные, или, если это невозможно, критерии,
                    используемые для определения такого срока; наличие права
                    требовать от Оператора исправления или удаления Персональных
                    данных или ограничения обработки Персональных данных в
                    отношении субъекта данных или возражать против такой
                    обработки; право подавать жалобу в надзорный орган; наличие
                    автоматизированного процесса принятия решений, включая
                    профилирование, упомянутое в статьях 22(1) и (4) Регламента,
                    и, по крайней мере, в этих случаях, значимая информация об
                    алгоритме, лежащем в основе, а также о значении и
                    предполагаемых последствиях такой обработки для субъекта
                    данных. Если Персональные данные передаются в третью страну
                    или международную организацию, Вы имеете право получать
                    информацию об имеющихся гарантиях, связанных с такой
                    передачей. По запросу Оператор также предоставляет копию
                    обрабатываемых Персональных данных. За все дополнительные
                    копии, запрошенные субъектом Персональных данных, Оператор
                    может взимать разумную плату, основанную на административных
                    расходах. Если Вы делаете запрос с помощью электронных
                    средств, и если не требуется иное, информация должна быть
                    предоставлена в общепринятой электронной форме.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Право на корректировку</strong>
                    <em> (ст. 16 Регламента)</em>. Вы имеете право на
                    исправление неверной или неточной информации относительно
                    Персональных данных по запросу об исправлении, сделанному
                    устно или письменно. У Оператора есть один календарный
                    месяц, чтобы ответить на запрос субъекта Персональных
                    данных.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>
                        Право на удаление (&laquo;право на забвение&raquo;)
                    </strong>
                    <em> (ст.17 Регламента)</em> Регламент предоставляет
                    физическим лицам право на удаление персональных данных. Вы
                    можете сделать запрос на удаление, связавшись с Нами. У Нас
                    будет один месяц, чтобы ответить на Ваш запрос. Обратите
                    внимание, что это право не является абсолютным и применяется
                    только при определенных обстоятельствах, предусмотренных
                    статьей 17 Регламента.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Право на ограничение обработки </strong>
                    <em>(ст. 18 Регламента).</em> Вы имеете право требовать
                    ограничения или прекращения обработки своих Персональных
                    данных. Если обработка была ограничена, такие Персональные
                    данные, за исключением хранения, обрабатываются только с
                    согласия субъекта данных или для обоснования, исполнения или
                    ведения защиты по судебным искам, или для защиты прав
                    другого физического или юридического лица, или в целях
                    защиты публичного интереса союза или государства-члена.
                    Обратите внимание, что это право не является абсолютным и
                    применяется только при определенных обстоятельствах,
                    предусмотренных статьей 18 Регламента.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Право на переносимость данных </strong>
                    <em>(ст. 20 Регламента).</em> Право на переносимость данных
                    позволяет Вам получать и повторно использовать Ваши
                    Персональные данные для Ваших собственных целей в рамках
                    различных сервисов. Оно позволяет легко перемещать,
                    копировать или передавать Персональные данные из одной
                    IT-среды в другую безопасным и надежным способом, не влияя
                    на удобство использования Персональных данных. Пожалуйста,
                    обратите внимание, что Вы имеете право на передачу
                    Персональных данных непосредственно от одного Оператора к
                    другому, в случаях, где это технически возможно.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Право на возражение </strong>
                    <em>(ст. 21 Регламента).</em> Вы имеете право в любое время
                    возразить против обработки Ваших персональных данных на
                    основаниях, относящихся к Вашей конкретной ситуации. Мы не
                    будем далее обрабатывать Ваши Персональные данные, за
                    исключением случаев, когда у нас имеются достаточные
                    законные основания для обработки, которые являются более
                    значимыми, чем интересы, права и свободы субъекта данных или
                    для установления, осуществления или защиты законных
                    требований. Если Ваши Персональные данные обрабатываются для
                    целей прямого маркетинга, Вы имеете право на возражение
                    против обработки относящихся к Вам Персональных данных для
                    целей указанного маркетинга, включая формирование профиля в
                    той мере, в какой это связано с прямым маркетингом.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>
                        Права, связанные с автоматизированным принятием решений,
                        в том числе с профилированием
                    </strong>
                    <em>(ст. 22 Регламента).</em> Вы имеете право не подпадать
                    под действие решения, основанного исключительно на
                    автоматической обработке, включая формирование профиля,
                    которое порождает юридические последствия в отношении него
                    или нее или существенно воздействует на него или на нее.
                    Обратите внимание, что это право не является абсолютным и
                    применяется только при определенных обстоятельствах,
                    предусмотренных статьей 22 Регламента. Вы можете отозвать
                    свое согласие на обработку Персональных данных в любое
                    время. Пожалуйста, помните, что отзыв согласия
                    распространяет свое действие только на будущее. Любые
                    действия, связанные с обработкой, осуществленные до такого
                    отзыва, не затрагиваются им.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Передача Персональных данных</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Передача Персональных данных</strong>. Для
                    достижения целей Обработки персональных данных, нам может
                    потребоваться предоставление Ваших Персональных данныхнашим
                    контрагентам. Ваши Персональные данные могут быть переданы
                    государственным органам, в частности, органам исполнительной
                    власти, на основании их запроса.
                </li>
                <li>
                    <strong>Обязательная и факультативная передача.</strong>{' '}
                    Передача сведений может носить обязательный характер,
                    например, в части сведений о пользовательском оборудовании:
                    IP-адрес, ОС, географические данные, ID/тип оборудования,
                    используемый канал: браузер/приложение, платежная
                    авторизация, идентификация/верификация, или факультативный
                    характер, например, в части сведений об индикаторах
                    совпадения адресов, информации об аккаунте и пр.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Хранение Персональных данных</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Локализация Персональных данных</strong>. Если Вы
                    гражданин Российской Федерации, то Мы храним Ваши данные на
                    серверах, расположенных на территории Российской Федерации.
                    Если Вы гражданин одного из государств-членов Европейской
                    экономической зоны, Великобритании, или другой страны,
                    законодательство о персональных данных которой требует,
                    чтобы Персональные данные Обрабатывались на территории такой
                    страны, то Ваши Персональные данные собираются и
                    Обрабатываются на территории Европейской экономической зоны
                    или такой страны соответственно.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Принципы и меры защиты Персональных данных
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Принципы защиты</strong>. Мы применяем правовые,
                    организационные, административные, технические и физические
                    меры защиты Персональных данных- для обеспечения защиты
                    Ваших Персональных данных.&nbsp;
                </li>
                <li>
                    <strong>Меры защиты</strong>. Меры по обеспечению
                    безопасности Персональных данных при их обработке
                    планируются и реализуются в целях обеспечения соответствия
                    требованиям законодательства в области Персональных данных и
                    принятым в соответствии с ним нормативным правовым актам. Мы
                    обеспечиваем безопасность Ваших Персональных данных, в том
                    числе, следующими способами: (1) ограничением и указанием
                    состава сотрудников Оператора, имеющих доступ к Персональным
                    данным, (2) назначением ответственного за организацию
                    обработки Персональных данных, (3) разработкой и внедрением
                    локальных актов по вопросам обработки Персональных данных,
                    (4) определением угроз безопасности Персональных данных при
                    их обработке в информационных системах Персональных данных,
                    (5) применением организационных и технических мер по
                    обеспечению безопасности Персональных данных, (6)
                    применением прошедших в установленном порядке процедуру
                    оценки соответствия средств защиты информации, (7) учетом
                    электронных носителей информации, (8) установлением правил
                    доступа к Персональным данным, (9) ограничением доступа в
                    помещения, где размещены технические средства,
                    осуществляющие обработку Персональных данных, а также
                    хранятся носители информации, (10) обнаружением фактов
                    несанкционированного доступа к Персональным данным и
                    принятием мер по предотвращению такого доступа, (11)
                    внесением Персональных данных (не являющиеся общедоступными,
                    требующими соблюдения конфиденциальности) в перечень
                    конфиденциальной информации Оператора, (12) получением
                    обязательства о неразглашении сведений конфиденциального
                    характера, в том числе Персональных данных, со всех
                    работников Оператора, непосредственно участвующих в
                    обработке Персональных данных, (13) восстановлением
                    Персональных данных, модифицированных или уничтоженных
                    вследствие несанкционированного доступа к ним, (14)
                    ознакомлением работников Оператора, непосредственно
                    осуществляющих обработку Персональных данных, с положениями
                    законодательства о персональных данных, в том числе
                    требованиями к защите Персональных данных, локальными актами
                    по вопросам обработки Персональных данных, (15) контролем за
                    принимаемыми мерами по обеспечению безопасности Персональных
                    данных.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Файлы Cookies</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Использование Cookies. </strong>Платформа использует
                    файлы Cookies. При посещении Платформы Ваш интернет-браузер
                    передает на Наш сервер определенные сведения: (1) дату и
                    время посещения, (2) тип браузера, (3) языковые настройки,
                    (4) операционную систему. Данные сведения сохраняются в
                    журналах подключений в течение ограниченного времени (от
                    сессии до года) для обеспечения безопасности и надлежащей
                    работы Платформы, а также для сбора статистической
                    информации. Помимо прочих, Мы используем следующие Cookie:
                </li>
            </ol>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p>
                                <strong>Наименование</strong>
                            </p>
                        </td>
                        <td>
                            <p>
                                <strong>Назначение</strong>
                            </p>
                        </td>
                        <td>
                            <p>
                                <strong>Срок жизни</strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Amplitude</p>
                        </td>
                        <td>
                            <p>Сбор метрики</p>
                        </td>
                        <td>
                            <p>до 90 дней</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>VK/Mail</p>
                        </td>
                        <td>
                            <p>Сбор метрики</p>
                        </td>
                        <td>
                            <p>до 90 дней</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Spodial</p>
                        </td>
                        <td>
                            <p>Технические</p>
                        </td>
                        <td>
                            <p>до 30 дней</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Яндекс</p>
                        </td>
                        <td>
                            <p>Сбор метрики</p>
                        </td>
                        <td>
                            <p>до 30 дней</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ol>
                <li>
                    <strong>Функции Cookies.</strong> Существуют различные
                    группы Cookies, которые используются на Платформе. Первая
                    группа это функциональные и технические Cookies. Главная
                    функция таких файлов позволить серверу сайта, на котором
                    размещена Платформа, получить информацию о Вашей сессии,
                    используемом языке, браузере и т.п., а также обеспечить
                    полноценную работу Платформы. Данные файлы нужны для того,
                    чтобы распознать Вас, когда Вы снова заходите на Платформу.
                    Это позволяет нам персонализировать содержание Платформы под
                    Ваши нужды, запоминать Ваши предпочтения. Вторая группа это
                    аналитические Cookies, они позволяют нам оценить и
                    подсчитать число посетителей, а также понять, как они
                    перемещаются по Платформе при работе с ним. Это помогает нам
                    вносить улучшения в работу Платформы, например, оптимизируя
                    поиск нужных разделов, делая его простым и эффективным. Вы
                    вправе отказаться от использования аналитических Cookies,
                    сделав соответствующие настройки в своем веб-браузере.
                </li>
                <li>
                    <strong>Время хранения Cookies. </strong>По времени хранения
                    на устройствах пользователей Cookies разделяются на
                    Постоянные и Сессионные: &ldquo;Сессионные Cookies&rdquo;
                    &ndash; это файлы, которые хранятся на вашем устройстве до
                    момента закрытия вами браузера. &ldquo;Постоянные
                    Cookies&rdquo; хранятся на вашем устройстве до окончания их
                    срока действия или пока вы их не удалите.
                </li>
                <li>
                    <strong>Отключение Cookies</strong>. У Вас есть возможность
                    принять или отклонить все Cookies на всех сайтах, которые Вы
                    посещаете, изменив настройки в Вашем веб-браузере. Например,
                    когда Вы используете Internet Explorer версии 11.0 вы должны
                    сделать следующее: (1) выбрать &laquo;Настройки&raquo;,
                    затем &ldquo;Свойства обозревателя&rdquo;, (2) перейдите на
                    вкладку &ldquo;Конфиденциальность&rdquo;, (3) используя
                    мышку, выберете предпочтительные настройки. Для каждого
                    браузера необходимо использовать свои настройки для
                    изменения и удаления Cookies. Пожалуйста, обратите внимание,
                    что определенные функциональные возможности Платформы могут
                    быть недоступны при отключении Cookies. Чтобы узнать
                    подробнее о том, как отрегулировать или изменить настройки
                    своего браузера, обратитесь к инструкциям браузера или по
                    адресу www.aboutcookies.org либо www.allaboutcookies.org.
                    Если Вы используете разные устройства для доступа к
                    Платформе (напр., смартфон, планшет, компьютер и т.д.), Вам
                    следует позаботиться о том, чтобы каждый браузер на каждом
                    устройстве был настроен в соответствии с вашими
                    предпочтениями относительно файлов Cookies.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Адреса для обмена юридически значимыми
                            сообщениями&nbsp;
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Реквизиты Сторон</strong>. В ходе исполнения
                    обязательств в рамках настоящей Политики и(или) иных
                    обязательств, как Оператор Персональных данных, между Нами
                    может быть (а в некоторых случаях &ndash; обязан быть) обмен
                    юридически значимыми сообщениями. Такой обмен происходит по
                    официальным контактам Сторон, как в письменной, так и в
                    электронной форме (равнозначно). При этом заключение
                    отдельного соглашения об использовании электронного
                    документооборота не требуется. Электронная почта Оператора
                    для обращений по вопросам персональных данных:{' '}
                    <a href='mailto:info@spodial.com'>info@spodial.com</a>
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Заключительные положения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Оговорка о делимости. </strong>В том случае, если
                    одно или несколько положений Политики будут признаны
                    недействительными / непримиримыми и т.п., то такие положения
                    считаются замененными на максимально приближенные по своему
                    смыслу действительные положения. При этом Политика не может
                    быть признана недействительной в полном объеме ни при каких
                    обстоятельствах.&nbsp;
                </li>
            </ol>
        </Page>
    );
};

export default PersonalDataPolicy;
