import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import styled from 'styled-components';

import {
    ICountries,
    ICountry,
} from '../../../../interfaces/additionalFields.interface';
import { countriesUrl, countryPlaceholder } from '../options';

export const Country: React.FC<ICountry> = props => {
    const [value, setValue] = useState<string>(
        props.formik.initialValues.country || props.formik.values.country
    );
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const readCountries = async () => {
        setLoading(true);
        const response = await fetch(countriesUrl, {
            cache: 'force-cache',
        });
        const data = await response.json();

        if (data && response.status === 200) {
            setCountries(
                data.map(
                    (country, index): ICountries => ({
                        key: index,
                        value: country.name_ru,
                    })
                )
            );
            setLoading(false);
        }
    };

    const handleSelect = country => {
        props.formik.setFieldValue('country', country);
        setValue(country);
    };
    return (
        <Form layout='vertical'>
            <Form.Item name='country' label='Страна' initialValue={value}>
                <StyledSelect
                    value={value}
                    loading={loading}
                    onFocus={readCountries}
                    onChange={(value: string) => handleSelect(value)}
                    showSearch
                    placeholder={countryPlaceholder}
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        (option?.value ?? '').includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.value ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.value ?? '').toLowerCase())
                    }
                    options={countries}
                />
            </Form.Item>
        </Form>
    );
};

const StyledSelect = styled(Select)`
    width: 100%;
`;
