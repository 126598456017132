import React from 'react';
import styled from 'styled-components';

type TProps = {
    readonly title: string;
    readonly children?: React.ReactNode;
};

export const Page: React.FC<TProps> = props => {
    const { title, children } = props;

    return (
        <StyledLayout>
            <StyledHead>{title}</StyledHead>
            <StyledContent>{children}</StyledContent>
        </StyledLayout>
    );
};

const StyledLayout = styled.main``;
const StyledHead = styled.h1`
    font-weight: 600;
    font-size: 2.375rem;
    line-height: 2.875rem;
`;
const StyledContent = styled.div``;
