import React, { useState } from 'react';
import { Select, Form } from 'antd';
import styled from 'styled-components';

import { IGender } from '../../../../interfaces/additionalFields.interface';
import { gender, genderPlaceholder } from '../options';

export const GenderSelector: React.FC<IGender> = props => {
    const [value, setValue] = useState<string | null>(
        props.formik.initialValues.gender || props.formik.values.gender
    );

    const handleSelect = gender => {
        props.formik.setFieldValue('gender', gender);
        setValue(gender);
    };

    return (
        <Form layout='vertical'>
            <Form.Item name='gender' label='Пол' initialValue={value}>
                <StyledSelect
                    value={value}
                    placeholder={genderPlaceholder}
                    onChange={(value: string) => handleSelect(value)}
                    options={gender}
                />
            </Form.Item>
        </Form>
    );
};

const StyledSelect = styled(Select)`
    width: 100%;
`;
