import { RouteObject } from 'react-router';

import { GuestLayout } from 'shared/layouts';
import { Recover, Reset, SignIn, SignUp } from 'features/auth/pages';
import getEnvConfig from 'utils/config/env';

const routes: RouteObject[] = [
    {
        path: `${getEnvConfig().PUBLIC_PATH}/auth`,
        element: <GuestLayout />,
        children: [
            {
                path: 'sign-in',
                element: <SignIn />,
            },
            {
                path: 'sign-up',
                element: <SignUp />,
            },
            {
                path: 'recover',
                element: <Recover />,
            },
            {
                path: 'reset',
                element: <Reset />,
            },
        ],
    },
];

export default routes;
