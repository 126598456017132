import { useEffect, useLayoutEffect, useState } from 'react';

interface IWindow {
    width: number;
    height: number;
}

export enum Size {
    extraSmall = 'extraSmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
}
export const useWindowSize = (): { windowSize: IWindow; size: Size } => {
    const [windowSize, setWindowSize] = useState<IWindow>({
        width: 0,
        height: 0,
    });
    const [size, setSize] = useState<Size | undefined>(undefined);

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        if (windowSize.width <= 768 && windowSize.width > 329) {
            setSize(Size.small);
        }
        if (windowSize.width > 768 && windowSize.width <= 992) {
            setSize(Size.medium);
        }
        if (windowSize.width > 992) {
            setSize(Size.large);
        }
    }, [windowSize]);

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            handleSize();
        }

        window.addEventListener('resize', handleSize);

        return () => window.removeEventListener('resize', handleSize);
    }, []);

    return {
        windowSize,
        size,
    };
};
