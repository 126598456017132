import React from 'react';
import { Input } from 'antd';
import { FormikProps } from 'formik';

import { Form, Field } from 'shared/ui/Form';
import { useRecoverPasswordMutation } from 'features/auth/services';
import { AuthForm } from 'features/auth/components';

import validationSchema from './validationSchema';

export const Recover: React.FC = () => {
    const [recoverPassword, { isSuccess }] = useRecoverPasswordMutation();

    const handleSubmit = async (
        formValues: { email: string },
        formikProps: FormikProps<{ email: string }>
    ) => {
        try {
            await recoverPassword(formValues.email).unwrap();
        } catch (e) {
            formikProps.setErrors({
                email: e.data.message,
            });
        }
    };

    if (isSuccess) {
        return (
            <AuthForm
                title='Проверьте почту! 📧'
                description='Мы отправили вам ссылку для восстановления'
                meta={{
                    link: '../sign-in',
                    linkText: 'Войти',
                    text: 'Вспомнили пароль?',
                }}
            />
        );
    }

    return (
        <AuthForm
            title='Забыли пароль?'
            description='Введите свою почту, и мы вышлем вам ссылку для смены пароля.'
            meta={{
                text: 'Вспомнили пароль?',
                link: '../sign-in',
                linkText: 'Войти',
            }}
        >
            <Form
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={{
                    email: '',
                }}
            >
                <Field required name='email' label='Почта'>
                    <Input autoFocus placeholder='john@example.com' />
                </Field>
            </Form>
        </AuthForm>
    );
};
