import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { validateUrl } from '../../../../../utils/common/validateUrl';

const { Title, Text, Link } = Typography;
export const LegalThanksContainer = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const link = searchParams.get('url');
    return (
        <Flex vertical gap={'1.5rem'}>
            <Flex vertical>
                <Title style={{ marginBottom: 12 }} level={1}>
                    Спасибо
                </Title>
                <Text>
                    Мы отправили счет на указанную вами почту, он будет
                    действовать в течение 3 дней
                </Text>
                {link && validateUrl(link) ? (
                    <Link href={link} target={'_blank'}>
                        Просмотреть счет
                    </Link>
                ) : null}
            </Flex>
            <div>
                <Button
                    onClick={() => navigate('/app/profile')}
                    type={'primary'}
                >
                    Перейти в профиль
                </Button>
            </div>
        </Flex>
    );
};
