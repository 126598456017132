import React, { useEffect, useState } from 'react';
import { Flex, Input } from 'antd';

import { Page } from '../../../../shared/ui/Page';
import { ShopList } from '../../views/Shops/ShopList';
import { ShopCreateContainer } from './ShopCreateContainer';
import { useLazyReadShopsQuery } from '../../services/shops';
import { shopsFilters } from './utils/shopList.filters';
import { useProfileData } from '../../../../hooks/useProfileData';
import { Shop } from '../../interfaces/shop.interface';
import { Warning } from '../../../../shared/ui/Warning';

export const ShopsContainer = () => {
    const [filters, setFilters] = useState<Shop>(shopsFilters);
    const { user } = useProfileData();

    const isTelegramAccount = user?.telegram_accounts?.length > 0;

    useEffect(() => {
        if (filters) {
            getShops(filters, true);
        }
    }, [filters]);

    const [getShops, { data: shops, isFetching, isLoading }] =
        useLazyReadShopsQuery();

    const loading = isLoading || isFetching;
    const isShops = !!shops?.items;

    return (
        <Page title={'Магазины'}>
            <Flex
                style={{ marginBottom: '1.5rem' }}
                justify={isTelegramAccount ? 'space-between' : 'space-between'}
                align={'center'}
            >
                {isTelegramAccount && (
                    <>
                        <div>
                            <Input
                                allowClear
                                onChange={e =>
                                    setFilters({
                                        ...filters,
                                        name: e.target.value,
                                    })
                                }
                                placeholder={'Поиск магазина'}
                            />
                        </div>

                        <ShopCreateContainer loading={loading} />
                    </>
                )}
            </Flex>

            {!isTelegramAccount && isShops && (
                <Warning
                    text={
                        'Чтобы создать магазин необходимо привязать Telegram к своему профилю'
                    }
                />
            )}

            {!isTelegramAccount && !isShops ? null : (
                <ShopList
                    filters={filters}
                    setFilters={setFilters}
                    loading={loading}
                    data={shops}
                />
            )}
        </Page>
    );
};
