import { ProductStatus } from '../../interfaces/products.interface';

export const options: Array<{ value: ProductStatus; label: string }> = [
    {
        value: ProductStatus.active,
        label: 'Виден в магазине',
    },
    {
        value: ProductStatus.archived,
        label: 'В архиве (не виден в магазине)',
    },
    {
        value: ProductStatus.disabled,
        label: 'Нет в наличии ',
    },
];
