import React, { Fragment } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface IPureHtml {
    readonly dirtyHtml: string | Element | Element[];
}
export const PurifiedHtml: React.FC<IPureHtml> = props => {
    const { dirtyHtml } = props;

    const cleanHTML = DOMPurify.sanitize(dirtyHtml, {
        USE_PROFILES: { html: true },
    });

    return <Fragment>{parse(cleanHTML)}</Fragment>;
};
