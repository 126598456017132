import React, { useEffect, useState } from 'react';
import { App, Button, DatePicker, Flex, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { useLazyReadOrdersExportQuery } from '../../services/shops';
import { messages, page } from '../../utils/orders';

const { RangePicker } = DatePicker;

const { Text } = Typography;
const OrdersContainer = () => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const [dates, setDates] = useState<string[]>([]);

    const [getOrdersExport, { isLoading, isSuccess, isError }] =
        useLazyReadOrdersExportQuery();

    const handleGetOrders = async (id: number) => {
        const payload = {
            shop_id: id,
            start_at: dates[0],
            end_at: dates[1],
        };

        if (dates.length === 0) {
            return notification.error({
                message: messages.invalidDates,
            });
        }

        await getOrdersExport(payload);
    };

    const handleSelectDate = (dates: string[]) => {
        setDates(dates);
    };

    useEffect(() => {
        if (isSuccess) {
            return notification.success({
                message: messages.success,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            return notification.error({
                message: messages.error,
            });
        }
    }, [isError]);

    return (
        <Flex gap={24} vertical>
            <Flex justify={'flex-end'} gap={16}>
                <RangePicker
                    onChange={(_, dates) => handleSelectDate(dates)}
                    format={'DD-MM-YYYY'}
                />

                <Button
                    loading={isLoading}
                    onClick={() => handleGetOrders(+id)}
                    type={'default'}
                    icon={<UploadOutlined />}
                >
                    {page.button}
                </Button>
            </Flex>
            <Text>{page.hint}</Text>
        </Flex>
    );
};

export default OrdersContainer;
