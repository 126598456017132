import React, { useState } from 'react';
import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import styled from 'styled-components';
import type { RangePickerProps } from 'antd/es/date-picker';

import { IAge } from '../../../../interfaces/additionalFields.interface';
import { birthdateFormat, disabledYear } from '../options';

export const Age: React.FC<IAge> = props => {
    const [value, setValue] = useState<string>(
        props.formik.initialValues.birthdate || props.formik.values.birthdate
    );

    const handleSelect = dateString => {
        props.formik.setFieldValue('birthdate', dateString);
        setValue(dateString);
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current.year() >= disabledYear;
    };

    return (
        <Form layout='vertical'>
            <Form.Item
                name='age'
                label='Дата рождения'
                initialValue={value ? dayjs(value, birthdateFormat) : null}
            >
                <StyledSelect
                    locale={locale}
                    format={birthdateFormat}
                    onChange={(date, dateString: string) =>
                        handleSelect(dateString)
                    }
                    showNow={false}
                    showToday={false}
                    disabledDate={disabledDate}
                />
            </Form.Item>
        </Form>
    );
};

const StyledSelect = styled(DatePicker)`
    width: 100%;
`;
