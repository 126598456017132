import React, { useEffect } from 'react';
import { Button, Flex, Typography, Popconfirm, App } from 'antd';
import { useParams } from 'react-router-dom';

import { useCreateShopAllProductsBuyableMutation } from '../../services/shops';

const { Text } = Typography;

const Buyable = props => {
    const { id } = useParams();
    const { notification } = App.useApp();

    const [createBuyableAll, { data, isSuccess, isLoading }] =
        useCreateShopAllProductsBuyableMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: data?.message || 'Успешно',
            });
        }
    }, [isSuccess]);

    const onClick = async (buyable: number) => {
        await createBuyableAll({ id: +id, buyable: buyable });
    };

    return (
        <div style={{ marginTop: '-1.5rem' }}>
            <Text>Перевести все товары:</Text>
            <Flex vertical={false} gap={'1.5rem'} style={{ marginTop: '1rem' }}>
                <Popconfirm
                    title='Перевести статус всех товаров магазина в покупаемые?'
                    onConfirm={() => onClick(1)}
                    // onCancel={cancel}
                    okText='Да'
                    cancelText='Нет'
                >
                    <Button
                        loading={isLoading}
                        disabled={!props?.payment_system || isLoading}
                    >
                        Покупка
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title='Перевести статус всех товаров магазина "по предзаказу"?'
                    onConfirm={() => onClick(0)}
                    //onCancel={cancel}
                    okText='Да'
                    cancelText='Нет'
                >
                    <Button
                        loading={isLoading}
                        disabled={!props?.payment_system || isLoading}
                    >
                        По предзаказу
                    </Button>
                </Popconfirm>
            </Flex>
        </div>
    );
};

export default Buyable;
