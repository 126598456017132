import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Введите минимум 8 символов')
        .max(30, 'Введите максимум 30 символов')
        .required('Введите пароль'),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Пароли должны совпадать'
    ),
});

export default validationSchema;
