import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NotFoundPage = () => {
    return (
        <StyledWrapper>
            <Result
                status='404'
                title='404'
                subTitle='Такой страницы нет'
                extra={
                    <Link to='/'>
                        <Button type='primary'>На главную</Button>
                    </Link>
                }
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
