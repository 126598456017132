import { App, Button, Form, Input } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    useConfirmPhoneMutation,
    useRequestPhoneConfirmationMutation,
} from '../../../../services';

type TProps = {
    readonly phone: string;
    readonly onCancel: () => void;
    readonly onPhoneChange: () => void;
};

export const PhoneConfirmation: React.FC<TProps> = props => {
    const [counter, setCounter] = useState<number>(30);

    const [confirmPhone, { isLoading, isSuccess }] = useConfirmPhoneMutation();
    const [requestPhoneConfirmation, { isError }] =
        useRequestPhoneConfirmationMutation();
    const { notification } = App.useApp();

    const [form] = Form.useForm();

    const handleConfirmation = () => {
        const code = form.getFieldValue('sms_code');
        confirmPhone({ code: code, phone: `${7}${props.phone}` });
    };

    const handleCodeRepeat = () => {
        setCounter(30);
        requestPhoneConfirmation({ phone: `${7}${props.phone}` });
    };

    const handleCancel = () => {
        props.onCancel();
    };

    useEffect(() => {
        if (isSuccess) {
            props.onCancel();
            notification.open({
                placement: 'top',
                message: 'Уведомление',
                description: 'Телефон был изменен',
                duration: 4.5,
                type: 'success',
                onClick: () => {},
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Неверный номер телефона',
            });
        }
    }, [isError]);

    useEffect(() => {
        const timer =
            counter > 0 &&
            setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        return () => clearInterval(timer as unknown as number);
    }, [counter]);

    return (
        <Fragment>
            <Container>
                <p>Мы отправили СМС на номер {`+7 ${props.phone}`}</p>
                <ChangePhoneButton type='link' onClick={props.onPhoneChange}>
                    Сменить номер
                </ChangePhoneButton>
                <Form form={form}>
                    <Form.Item name='sms_code'>
                        <CodeInput autoFocus />
                    </Form.Item>
                </Form>
                {counter > 0 && (
                    <ResendHint>
                        Повторная отправка доступна через 00:
                        {counter.toLocaleString('ru-RU', {
                            minimumIntegerDigits: 2,
                        })}
                    </ResendHint>
                )}
                {counter === 0 && (
                    <ChangePhoneButton type='link' onClick={handleCodeRepeat}>
                        Отправить повторно
                    </ChangePhoneButton>
                )}
                <Actions>
                    <Button
                        block
                        type='primary'
                        loading={isLoading}
                        onClick={handleConfirmation}
                    >
                        Подтвердить
                    </Button>
                    <Button block onClick={handleCancel}>
                        Отмена
                    </Button>
                </Actions>
            </Container>
        </Fragment>
    );
};

const Container = styled.div`
    width: 100%;
`;

const ChangePhoneButton = styled(Button)`
    padding-left: 0;
    padding-right: 0;
`;

const CodeInput = styled(Input)`
    margin-top: 16px;
`;

const ResendHint = styled.p`
    margin-top: 16px;
`;

const Actions = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
