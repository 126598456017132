import { RouteObject } from 'react-router';

import { Profile } from 'features/user/pages';

import { LegalThanksContainer } from './components/LegalEntity/containers/LegalThanksContainer';
import { LegalErrorContainer } from './components/LegalEntity/containers/LegalErrorContainer';

const routes: RouteObject[] = [
    {
        path: 'profile',
        element: <Profile />,
    },
    {
        path: 'legal-success',
        element: <LegalThanksContainer />,
    },
    {
        path: 'legal-error',
        element: <LegalErrorContainer />,
    },
];

export default routes;
