import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';

const path = '/promocode';

export const promocodeApi = createApi({
    reducerPath: 'promocode',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),

    tagTypes: ['Promocode'],
    endpoints: builder => ({
        createPromocode: builder.mutation<any, PromocodeValues>({
            query: body => ({
                url: `${path}`,
                method: 'POST',
                body: { ...body },
            }),
            invalidatesTags: ['Promocode'],
        }),

        readPromocodes: builder.query<PromocodeResponse[], PromocodeValues>({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
                return {
                    url: `${path}?${queryArgs}`,
                };
            },
            transformResponse: (res, meta) => {
                return {
                    items: res.data,
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Promocode'],
        }),
    }),
});

export const { useCreatePromocodeMutation, useLazyReadPromocodesQuery } =
    promocodeApi;
