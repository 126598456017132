import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    fb_message: Yup.string()
        .trim()
        .required('Введите тело сообщения')
        .max(10000, 'Допустим лимит в 10000 знаков'),
});

export default validationSchema;
