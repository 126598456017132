import React from 'react';
import { ConfigProvider, App as Antd } from 'antd';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';

import { store } from 'store';
import { router } from 'routes';
import { GlobalNotification } from 'shared/ui/GlobalNotification';

import themeConfig from './antd.theme-config';
import { NormalizeStyle } from './normalize.style';
import { GlobalStyle } from './global.style';
import { LandingStyle } from '../Landing/fontStyles';

export const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ConfigProvider locale={ru_RU} theme={themeConfig}>
                <Antd>
                    <GlobalNotification />
                    <NormalizeStyle />
                    <GlobalStyle />
                    <LandingStyle />
                    <RouterProvider router={router} />
                </Antd>
            </ConfigProvider>
        </Provider>
    );
};
