import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, List } from 'antd';
import { useNavigate } from 'react-router-dom';

import getEnvConfig from '../../../../utils/config/env';
import { IShop, Shop } from '../../interfaces/shop.interface';
import { PaginatedResponse } from '../../../communities/interfaces/PaginatedResponse';
import { ShopCard } from './ShopCard';

interface ShopList {
    readonly data: PaginatedResponse<IShop>;
    readonly filters: Shop;
    readonly setFilters: Dispatch<SetStateAction<Shop>>;
    readonly loading?: boolean;
}
export const ShopList: React.FC<ShopList> = props => {
    const { data, setFilters, filters, loading } = props;

    const navigate = useNavigate();

    const pagination = {
        total: data?.total,
        showTotal: () => `Магазинов: ${data?.total || 0}`,
        onChange: (page, pageSize) => {
            setFilters({
                ...filters,
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        defaultPageSize: 3,
        showQuickJumper: false,
        showSizeChanger: false,
    };

    return (
        <Flex vertical gap={'1.5rem'}>
            <List
                loading={loading}
                pagination={pagination}
                dataSource={data?.items}
                renderItem={(item: IShop, index) => (
                    <ShopCard
                        source={item}
                        loading={loading}
                        actions={
                            <Button
                                disabled={loading}
                                onClick={() =>
                                    navigate(
                                        `${getEnvConfig().PUBLIC_PATH}/shops/${
                                            item.id
                                        }/products`
                                    )
                                }
                            >
                                Перейти в магазин
                            </Button>
                        }
                    />
                )}
            />
        </Flex>
    );
};
