import React, { Fragment, useEffect, useState } from 'react';
import { App, Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import { useToggle } from '../../../../hooks/useToggle';
import { Confirm } from './components/Confirm';
import { useDeleteAccountMutation } from '../../services';

const { Text } = Typography;

export const DeleteAccount: React.FC = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const { open, setOpen } = useToggle(false);
    const [confirm, setConfirm] = useState(false);

    const [deleteAccount, { error, isError, isLoading, isSuccess }] =
        useDeleteAccountMutation();

    const toggleDelete = () => setOpen((x: boolean) => !x);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Аккаунт удален успешно',
                placement: 'top',
            });
            localStorage.removeItem('accessToken');
            setOpen(false);
            localStorage.removeItem('accessToken');
            navigate('/');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Произошла ошибка при удалении аккаунта',
                placement: 'top',
            });
        }
    }, [isError]);

    return (
        <Fragment>
            <Button
                onClick={toggleDelete}
                style={{ paddingLeft: 0 }}
                type={'link'}
                danger
            >
                Удалить аккаунт
            </Button>
            {open && (
                <GenericModal
                    onCancel={toggleDelete}
                    okButtonProps={{
                        danger: true,
                        disabled: !confirm || isLoading,
                    }}
                    onConfirm={() => deleteAccount()}
                    okText='Удалить'
                    open={open}
                    title={'Внимание!'}
                >
                    <StyledText>
                        <Text>
                            Вы собираетесь <Text type={'danger'}>удалить </Text>
                            свою учётную запись. Это происходит моментально и
                            данные будут удалены безвозвратно.
                        </Text>
                    </StyledText>
                    <Confirm confirm={confirm} setConfirm={setConfirm} />
                </GenericModal>
            )}
        </Fragment>
    );
};

const StyledText = styled.div`
    margin: 20px 0;
`;
