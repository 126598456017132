import React, { useEffect, useState } from 'react';
import { App, Input } from 'antd';
import { useParams } from 'react-router-dom';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import {
    useCreateProductCategoryMutation,
    useUpdateProductCategoryMutation,
} from '../../services/categories';

const categoryLength = 100;
export const CategoryEditorModal = props => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const { open, setOpen, setCategoryName, categoryName, isEdit } = props;

    const [isCategoryExist, setIsCategoryExist] = useState(false);

    const [createCategory, { isLoading, isSuccess }] =
        useCreateProductCategoryMutation();
    const [
        updateCategory,
        { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate },
    ] = useUpdateProductCategoryMutation();

    useEffect(() => {
        if (categoryName && props?.categories && !isEdit) {
            const categoryNames = Array.from(
                new Set(props?.categories.map(category => category.name))
            );

            if (categoryNames.includes(categoryName)) {
                setIsCategoryExist(true);
                notification.error({
                    message: 'Категория с таким названием уже существует',
                });
            } else setIsCategoryExist(false);
        }
    }, [categoryName]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Категория успешно добавлена',
                duration: 1,
            });
            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message: 'Категория успешно изменена',
            });
            setOpen(false);
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (categoryName.length >= categoryLength) {
            notification.error({
                message: 'Слишком длинное название',
            });
        }
    }, [categoryName]);

    useEffect(() => {
        if (!open) {
            setCategoryName('');
        }
    }, [open]);

    const onSave = async () => {
        const data = {
            shop_id: isEdit ? props.categoryId : +id,
            name: categoryName,
            parent_id: 0,
        };

        (await isEdit) ? updateCategory(data) : createCategory(data);
    };

    return (
        <GenericModal
            okText={isEdit ? 'Изменить' : 'Добавить'}
            title={isEdit ? 'Изменить категорию' : 'Добавить категорию'}
            width={360}
            open={open}
            onConfirm={onSave}
            onCancel={() => {
                setOpen(false);
            }}
            okButtonProps={{
                loading: isLoading || isLoadingUpdate,
                disabled:
                    isCategoryExist ||
                    isLoading ||
                    isLoadingUpdate ||
                    categoryName.length < 1 ||
                    categoryName.length >= categoryLength,
            }}
            cancelButtonProps={{
                loading: isLoading || isLoadingUpdate,
                disabled: isLoading || isLoadingUpdate,
            }}
        >
            <Input
                maxLength={categoryLength}
                style={{ marginTop: '1.5rem' }}
                placeholder={'Название категории'}
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
                count={{
                    show: true,
                    max: categoryLength,
                }}
            />
        </GenericModal>
    );
};
