import React from 'react';
import styled from 'styled-components';

type TProps = {
    readonly title: string;
    readonly description?: string | JSX.Element;
    readonly children: React.ReactElement;
    readonly error?: boolean;
};

export const Settings: React.FC<TProps> = props => {
    return (
        <Wrapper>
            <Heading>{props.title}</Heading>
            {props.description && (
                <Description error={props.error}>
                    {props.description}
                </Description>
            )}
            {props.children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-bottom: 28px;
`;

const Heading = styled.h3`
    font-weight: 600;
    margin-bottom: 8px;
`;

const Description = styled.div<{ error?: boolean }>`
    margin-bottom: 8px;
    font-size: 14px;
    color: ${props => (props.error ? '#f06e69' : 'rgba(37, 33, 41, 0.45)')};
`;
