import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnvConfig from 'utils/config/env';

import { ISignInCredentials, ISignUpCredentials } from '../interfaces';

export const authApi = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
    }),
    endpoints: builder => ({
        recoverPassword: builder.mutation<void, string>({
            query: email => ({
                url: '/user/password/forgot',
                method: 'POST',
                body: { email },
            }),
        }),
        resetPassword: builder.mutation<
            {
                data: { token: string };
            },
            {
                token: string;
                email: string;
                password: string;
                password_confirmation: string;
            }
        >({
            query: payload => ({
                url: '/user/password/reset',
                method: 'POST',
                body: payload,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                const {
                    data: {
                        data: { token },
                    },
                } = await queryFulfilled;
                localStorage.setItem('accessToken', token);
            },
        }),

        signIn: builder.mutation<
            { data: { token: string } },
            ISignInCredentials
        >({
            query: (credentials: ISignInCredentials) => ({
                url: '/user/login',
                method: 'POST',
                body: credentials,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                const {
                    data: {
                        data: { token },
                    },
                } = await queryFulfilled;

                localStorage.setItem('accessToken', token);
            },
        }),
        signUp: builder.mutation<
            { data: { token: string } },
            ISignUpCredentials
        >({
            query: credentials => ({
                url: 'user/register',
                method: 'POST',
                body: credentials,
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                const {
                    data: {
                        data: { token },
                    },
                } = await queryFulfilled;

                localStorage.setItem('accessToken', token);
            },
        }),
    }),
});

export const {
    useRecoverPasswordMutation,
    useResetPasswordMutation,
    useSignInMutation,
    useSignUpMutation,
} = authApi;
