import React from 'react';

export const ArchiveSvg = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
        >
            <path
                d='M7.92985 6.63435C7.93816 6.64447 7.94878 6.65265 7.96091 6.65828C7.97304 6.66391 7.98635 6.66683 7.99984 6.66683C8.01333 6.66683 8.02664 6.66391 8.03877 6.65828C8.05089 6.65265 8.06151 6.64447 8.06983 6.63435L9.3141 5.13509C9.35965 5.08007 9.31855 4.9986 9.24411 4.9986H8.6665V1.41814C8.6665 1.37159 8.62651 1.3335 8.57763 1.3335H7.42205C7.37317 1.3335 7.33317 1.37159 7.33317 1.41814V4.99754H6.75556C6.68113 4.99754 6.64002 5.07901 6.68557 5.13403L7.92985 6.63435Z'
                fill='#252129'
                fillOpacity='0.88'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.00016 2.08154H4.39429C4.12867 2.08154 3.89273 2.25811 3.81773 2.51279L2.17867 6.92217L2.17398 6.93311L2.17085 6.94561C2.15054 7.02217 2.14429 7.10029 2.15523 7.17686C2.15367 7.20185 2.1521 7.22686 2.1521 7.25186V12.969C2.15252 13.2209 2.25274 13.4623 2.43081 13.6403C2.60888 13.8184 2.85027 13.9186 3.1021 13.919H12.9021C13.4255 13.919 13.8521 13.4925 13.8537 12.969V7.25186C13.8537 7.23154 13.8537 7.21123 13.8521 7.19404C13.8584 7.11748 13.8521 7.04404 13.8318 6.97373L13.8287 6.96123L12.0755 2.50654C11.9974 2.25498 11.7646 2.08154 11.5005 2.08154H10.0002V3.28154H11.0662L12.3427 6.30186H9.20992L9.20523 6.54717C9.19273 7.24873 8.70835 7.72061 8.00054 7.72061C7.65523 7.72061 7.35835 7.60967 7.14429 7.39873C6.93023 7.18779 6.81304 6.89404 6.80679 6.54717L6.8021 6.30186H3.58648L4.82867 3.28154H6.00016V2.08154ZM5.80835 7.50186H3.35054V12.719H12.6505V7.50186H10.2052C10.0302 7.91123 9.75992 8.25185 9.41304 8.49561C9.01773 8.77373 8.52867 8.92061 8.0021 8.92061C6.99585 8.92061 6.18804 8.39404 5.80835 7.50186Z'
                fill='#252129'
                fillOpacity='0.88'
            />
        </svg>
    );
};
