import { Avatar, Card } from 'antd';
import React from 'react';
import styled from 'styled-components';

import type { ITelegramAccount } from 'features/user/interfaces';

import { RemoveTelegramAccount } from '../RemoveTelegramAccount';

type TProps = {
    readonly account: ITelegramAccount;
};

const TelegramAccount: React.FC<TProps> = props => {
    const { first_name, last_name } = props.account;

    const name = `${first_name ?? ''} ${last_name ?? ''}`;
    const img_url =
        new URL(window.ENV.API_URL).origin + props.account.photo_url;

    return (
        <Card type='inner'>
            <Container>
                <Avatar size={'large'} src={img_url} />
                <div>
                    <Name>{name}</Name>
                    <RemoveTelegramAccount
                        name={name}
                        photo_url={img_url}
                        telegram_id={props.account.telegram_id}
                    />
                </div>
            </Container>
        </Card>
    );
};

export default TelegramAccount;

const Container = styled.div`
    min-width: 240px;
    width: 100%;
    display: flex;
    align-items: start;
    gap: 24px;
`;

const Name = styled.div`
    font-size: 16px;
    font-weight: 600;
`;
