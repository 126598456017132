import React from 'react';

import { Settings } from '../Settings';

type TProps = {
    readonly email: string;
};

export const Email: React.FC<TProps> = props => {
    return (
        <Settings title='Почта'>
            <div>{props.email}</div>
        </Settings>
    );
};
