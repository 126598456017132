import React, {
    Component,
    ErrorInfo,
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

type TProps = {
    hasError: boolean;
    setHasError: (hasError: boolean) => void;
} & PropsWithChildren;

type TState = {
    hasError: boolean;
};

export const ErrorBoundary: React.FC<PropsWithChildren> = props => {
    const [hasError, setHasError] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (hasError) {
            setHasError(false);
        }
    }, [location.key]);

    return (
        <CErrorBoundary hasError={hasError} setHasError={setHasError}>
            {props.children}
        </CErrorBoundary>
    );
};

class CErrorBoundary extends Component<TProps, TState> {
    public state: TState = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): TState {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.setHasError(true);
        //console.error('Uncaught error:', error, errorInfo);
    }

    componentDidUpdate(prevProps: TProps, _previousState: TState) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ hasError: false });
        }
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorBoundaryFallback />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
