import { Button, Space } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
    useAddBankCardMutation,
    useListBankCardsQuery,
} from 'features/user/services';

import { Settings } from '../Settings';
import { BankCard } from './BankCard';

type TProps = {
    readonly isBankCardPrimary: boolean;
};
export const BankCards: React.FC<TProps> = props => {
    const bankCards = (useListBankCardsQuery().data || []).filter(
        card => card.Status === 'A'
    );

    const [getAddBankCardAPI, { data, isLoading }] = useAddBankCardMutation();

    const handleAddBankCardRequest = async () => {
        const redirectUrl = await getAddBankCardAPI().unwrap();
        window.open(redirectUrl.data.redirect);
    };

    /*const redirectUrl = data?.data.redirect || null;

    if (redirectUrl) {
        window.open(redirectUrl);
    }
*/
    return (
        <Settings
            title='Банковские карты'
            error={!bankCards.length}
            description='Для вывода средств необходимо привязать карту.'
        >
            <Fragment>
                {!!bankCards.length && (
                    <Wrapper>
                        {bankCards.map((card, index) => (
                            <React.Fragment key={index}>
                                <BankCard card={card} />
                            </React.Fragment>
                        ))}
                    </Wrapper>
                )}
                {!bankCards.length && (
                    <Actions>
                        <Space>
                            <Button
                                disabled={isLoading}
                                type={
                                    props.isBankCardPrimary &&
                                    bankCards?.length === 0
                                        ? 'primary'
                                        : 'default'
                                }
                                onClick={handleAddBankCardRequest}
                            >
                                Добавить карту
                            </Button>
                        </Space>
                    </Actions>
                )}
            </Fragment>
        </Settings>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

const Actions = styled.div`
    margin-top: 8px;
`;
