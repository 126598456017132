import React, { useState } from 'react';
import { App, Button, Flex, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLocation } from 'react-router';

import { IProducts } from '../../interfaces/products.interface';
import { CategoryFilter } from '../Categories/CategoryFilter';
import { ProductStatusSwitcher } from '../../components/ProductStatusSwitcher';
import { CANCEL, SAVE } from '../../../../shared/contants/actions';
import { HeaderScenarios } from '../../../../shared/ui/HeaderScenarios/HeaderScenarios';
import { Price } from '../../../../shared/ui/Price/Price';
import { ProductUploader } from '../../../../shared/ui/ProductUploader/ProductUploader';
import { BuyableSwitcher } from '../../../../shared/ui/BuyableSwitcher/BuyableSwitcher';
import { DescriptionEditor } from '../../../../shared/ui/DescriptionEditor/DescriptionEditor';

const inputSize = 260;

interface IProductForm {
    readonly onSave: (values: IProducts) => Promise<void>;
    readonly initialValues: IProducts;
    readonly loading?: boolean;
    readonly shopId: number;
}
export const ProductForm: React.FC<IProductForm> = props => {
    const { pathname } = useLocation();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);

    const { onSave, loading, initialValues, shopId } = props;

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    const disabled =
        loading ||
        !formik.values.price ||
        formik.values.title === '' ||
        formik.values.images?.length < 1 ||
        loadingDeleteImage;

    const isEdit = pathname.includes('edit');

    const onChange = (value: string) => {
        if (value.match(/^[ ]+$/)) {
            return notification.error({
                message: 'Название товара обязательно для заполнения',
            });
        }

        if (value.length > 100) {
            return notification.error({
                message: 'Название товара не может быть длиннее 100 символов',
            });
        }

        if (value.length === 0) {
            formik.setFieldValue('title', value);
        } else formik.setFieldValue('title', value);
    };

    const handlerClearTitle = () => {
        formik.setFieldValue('title', '');
    };

    return (
        <Flex vertical gap={'middle'}>
            <Typography.Title
                level={1}
                style={{ margin: 0, marginBottom: '40px' }}
            >
                Товар
            </Typography.Title>

            <HeaderScenarios
                goBack={() => navigate(-1)}
                inputValue={formik?.values?.title || ''}
                onChangeHeaderInput={({ target }) => {
                    onChange(target.value);
                }}
                tooltip={formik?.values.title}
                onExtraEdit={!isEdit ? handlerClearTitle : null}
            />

            <ProductUploader
                loadingDeleteImage={loadingDeleteImage}
                setLoadingDeleteImage={setLoadingDeleteImage}
                isEdit={isEdit}
                formik={formik}
                type={'images'}
            />

            <DescriptionEditor formik={formik} />

            <Price formik={formik} width={inputSize} />

            <BuyableSwitcher
                shopId={shopId}
                formik={formik}
                width={inputSize}
            />

            <ProductStatusSwitcher formik={formik} width={inputSize} />

            <Flex gap={8} vertical>
                <Typography.Text>Категория товара</Typography.Text>
                <CategoryFilter
                    shopId={shopId}
                    formik={formik}
                    width={inputSize}
                />
            </Flex>

            <Space wrap style={{ marginTop: '2rem' }}>
                <Button
                    disabled={disabled}
                    onClick={() => formik.submitForm()}
                    type={'primary'}
                >
                    {SAVE}
                </Button>
                <Button onClick={() => navigate(-1)}>{CANCEL}</Button>
            </Space>
        </Flex>
    );
};
