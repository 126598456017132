import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Flex, Skeleton, Tag, Typography } from 'antd';

import { useTheme } from '../../../../hooks/useTheme';
import { Uncategorized } from '../../views/Categories/categoryFilters';
import { useDimensions } from '../../../../hooks/useDimensions';
import { ShopOrderType } from '../../interfaces/shop.interface';

type TProductImage = {
    readonly loading?: boolean;
    readonly src: string;
    readonly gutter?: number;
    readonly type?: string;
    readonly orderType?: boolean;
    readonly categoryName?: string;
    readonly style?: CSSProperties;
};
export const ProductImage: React.FC<TProductImage> = props => {
    const imageRef = useRef(null);
    const [width, setWidth] = useState(0);
    const { width: imageWidth } = useDimensions(imageRef);
    const { colorBgLayout } = useTheme();

    useEffect(() => {
        setWidth(imageWidth);
    }, [imageWidth]);

    const token = useTheme();
    const { loading, src, gutter = 0, type, orderType, categoryName } = props;

    return loading ? (
        <Skeleton.Image
            style={{ width: width - gutter, height: width - gutter }}
            active
        />
    ) : (
        <div ref={imageRef} style={{ width: '100%' }}>
            {/*{orderType === false && (
                <Tag
                    style={{
                        position: 'absolute',
                        top: type === 'list' ? '.5rem' : '1.25rem',
                        left: '1.5rem',
                    }}
                    color={token.colorPrimary}
                >
                    {MarketOrderType.preorder}
                </Tag>
            )}*/}
            <StyledImage
                style={{
                    ...props.style,
                    width: width - gutter,
                    height: width - gutter,
                }}
                back={src}
                type={type}
            >
                <Flex
                    vertical={true}
                    justify={'space-between'}
                    style={{ height: '100%', margin: 0 }}
                >
                    {orderType === false && (
                        <div>
                            <Tag
                                style={{
                                    marginTop: '1rem',
                                    marginLeft: '1rem',
                                }}
                                color={token.colorPrimary}
                            >
                                {ShopOrderType.preorder}
                            </Tag>
                        </div>
                    )}
                    <StyledCategory style={{ padding: '1rem' }}>
                        {(type === 'product' || type === 'web') &&
                        categoryName !== Uncategorized ? (
                            <Typography.Paragraph
                                style={{
                                    marginBottom: 0,
                                    borderRadius: 5,
                                    background: colorBgLayout,
                                    padding: '0 0.25rem',
                                }}
                                ellipsis={true}
                            >
                                {categoryName}
                            </Typography.Paragraph>
                        ) : null}
                    </StyledCategory>
                </Flex>
            </StyledImage>
        </div>
    );
};

const StyledImage = styled.div<{ back: string; type: string }>`
    background-color: rgba(240, 240, 240, 0.5);
    background-image: url(${props => props.back});
    background-blend-mode: multiply, normal;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    border-radius: 0.5rem;
    border-bottom-left-radius: ${props =>
        props.type === 'web' ? 0 : '0.5rem'};
    border-bottom-right-radius: ${props =>
        props.type === 'web' ? 0 : '0.5rem'};
`;

const StyledCategory = styled.div`
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-content: flex-end;
    flex-wrap: wrap;
`;
