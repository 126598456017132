import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    App,
    Button,
    DatePicker,
    Flex,
    Form,
    Input,
    InputNumber,
    Switch,
    Typography,
} from 'antd';
import dayjs from 'dayjs';

import { useCreateFeedMutation } from '../../services/shops';
import { PromocodesHistoryContainer } from './PromocodesHistoryContainer';
import {
    useCreatePromocodeMutation,
    useLazyReadPromocodesQuery,
} from '../../services/promocode';

const { Text } = Typography;

export const PromocodesSettingsContainer = props => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { notification } = App.useApp();
    const endDateFormat = 'DD.MM.YYYY';

    const [createPromocode, { isError, isSuccess, data }] =
        useCreatePromocodeMutation();

    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

    const [form] = Form.useForm();
    const values = Form.useWatch([], form);

    const initial = {
        discount: 10,
        quantity: 5,
        usage_limit: 20,
        is_active: true,
        disable_usage_limit: true,
    };

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Промокод успешно создан',
            });
            props.setActiveTab('history');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            {
                Object.entries(error?.data.errors).map(([key, messages]) => (
                    <Fragment key={key}>
                        {messages.map((message, index) =>
                            notification.error({
                                message: message,
                            })
                        )}
                    </Fragment>
                ));
            }
        }
    }, [isError]);

    const onSwitch = (value: boolean, fieldName: string) => {
        form.setFieldValue(fieldName, value);
    };

    const handleSave = async () => {
        const payload = {
            ...values,
            shop_id: +id,
            end_date:
                values.end_date && dayjs(values.end_date).format('YYYY-MM-DD'),
        };

        await createPromocode(payload);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Flex vertical gap={24}>
            <Form
                style={{
                    width: 326,
                }}
                form={form}
                name='promocodes'
                initialValues={initial}
                layout={'vertical'}
                autoComplete='off'
                requiredMark={false}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                    label='Размер скидки, %'
                    name='discount'
                >
                    <Input required placeholder={'Укажите значение'} />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                    label='Количество промокодов'
                    name='quantity'
                >
                    <InputNumber required maxLength={5} max={99999} min={1} />
                </Form.Item>

                <Form.Item name='disable_usage_limit' valuePropName={'checked'}>
                    <Flex vertical={false} gap={8}>
                        <Switch
                            defaultChecked
                            onClick={value =>
                                onSwitch(value, 'disable_usage_limit')
                            }
                        />
                        <Text>Ограничить количество использований</Text>
                    </Flex>
                </Form.Item>

                {form.getFieldValue('disable_usage_limit') && (
                    <Form.Item
                        label={'Количество использований промокода'}
                        name='usage_limit'
                    >
                        <Input placeholder={'Укажите значение'} />
                    </Form.Item>
                )}

                <Form.Item name='is_active'>
                    <Flex vertical={false} gap={8}>
                        <Switch
                            onClick={value => onSwitch(value, 'is_active')}
                            defaultChecked
                        />
                        <Text>Ограничить время действия</Text>
                    </Flex>
                </Form.Item>

                {form.getFieldValue('is_active') && (
                    <Form.Item label={'Срок окончания'} name='end_date'>
                        <DatePicker
                            style={{
                                width: 326,
                            }}
                            disabledDate={current =>
                                current &&
                                current <
                                    dayjs().endOf('day').subtract(1, 'day')
                            }
                            format={endDateFormat}
                        />
                    </Form.Item>
                )}
            </Form>

            <Flex vertical={false} gap={24}>
                <Button
                    disabled={!isSubmittable}
                    type={'primary'}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
                <Button onClick={handleCancel}>Отмена</Button>
            </Flex>
        </Flex>
    );
};
