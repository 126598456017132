import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import {
    Formik,
    Form as FormikForm,
    FormikConfig,
    FormikHelpers,
} from 'formik';

type TProps<T> = {
    readonly onCancel?: () => void;
    readonly onSubmit?: (values: T, formikHelpers?: FormikHelpers<T>) => void;
    readonly submitButtonText?: string;
} & Omit<FormikConfig<T>, 'onSubmit'>;

export const Form = <T,>(props: TProps<T>) => {
    return (
        <Formik
            {...props}
            onSubmit={props.onSubmit ? props.onSubmit : () => {}}
        >
            {formikProps => {
                return (
                    <FormikForm>
                        <React.Fragment>
                            {typeof props.children === 'function'
                                ? props.children(formikProps)
                                : props.children}
                        </React.Fragment>
                        <StyledActions>
                            {props.onSubmit && (
                                <Button
                                    loading={formikProps.isSubmitting}
                                    type='primary'
                                    htmlType='submit'
                                >
                                    {props.submitButtonText || 'Отправить'}
                                </Button>
                            )}
                            {props.onCancel && (
                                <Button
                                    onClick={() => {
                                        formikProps.handleReset();
                                        props.onCancel();
                                    }}
                                >
                                    Отмена
                                </Button>
                            )}
                        </StyledActions>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

const StyledActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
`;
