import React from 'react';
import { Flex, Table, Typography } from 'antd';

const { Text, Title } = Typography;

export const FeedsHistoryContainer = () => {
    const dataSource = [
        {
            key: '1',
            date: '2 июня 2024, 12:00',
            time: '2 июня 2024, 15:10',
            status: 'В обработке',
        },
        {
            key: '2',
            date: '2 июня 2024, 12:00',
            time: '2 июня 2024, 15:10',
            status: 'Ошибка',
        },
        {
            key: '3',
            date: '2 июня 2024, 12:00',
            time: '2 июня 2024, 15:10',
            status: 'Ошибка',
        },
    ];

    const columns = [
        {
            title: 'Дата по расписанию',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Фактическое время запуска',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Статус обновления',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    return (
        <div>
            <Flex vertical gap={4}>
                <Flex vertical={false}>
                    <Text>Ссылка на фид:</Text>
                    <Text></Text>
                </Flex>

                <Flex vertical={false}>
                    <Text>Частота обновления (часы):</Text>
                    <Text></Text>
                </Flex>

                <Flex vertical={false}>
                    <Text>Дата активации:</Text>
                    <Text></Text>
                </Flex>
            </Flex>

            <Title level={4}>Обновление фида</Title>

            <Table dataSource={dataSource} columns={columns} />
        </div>
    );
};
