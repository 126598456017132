import { App, Button, Modal } from 'antd';
import React, { useState } from 'react';
import TelegramLoginButton from 'react-telegram-login';
import styled from 'styled-components';

import type { ITelegramAccount } from 'features/user/interfaces';
import { useAttachTelegramMutation } from 'features/user/services';

type TProps = {
    isAccountPrimary: boolean;
};
export const AddTelegramAccount: React.FC<TProps> = props => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const { notification } = App.useApp();

    const [attachTelegram] = useAttachTelegramMutation();

    const handleTelegramResponse = async (response: ITelegramAccount) => {
        try {
            await attachTelegram(response).unwrap();
            notification.success({
                message: 'Успех',
                description: `Телеграм аккаунт привязан.`,
                placement: 'top',
            });
        } catch (e) {
            notification.error({
                message: 'Ошибка',
                description: `${e.data.spodial_php_error.errors.id[0]}`,
                placement: 'top',
            });
        } finally {
            closeDialog();
        }
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                type={props.isAccountPrimary ? 'primary' : 'default'}
                onClick={openDialog}
            >
                Добавить аккаунт Telegram
            </Button>
            <Modal
                title='Добавление аккаунта Telegram'
                width={380}
                footer={null}
                onCancel={closeDialog}
                destroyOnClose
                open={isOpen}
            >
                <Container>
                    <TelegramLoginButton
                        dataOnauth={handleTelegramResponse}
                        botName={window.ENV.TELEGRAM_BOT}
                    />
                </Container>
                <Button block onClick={closeDialog}>
                    Отмена
                </Button>
            </Modal>
        </React.Fragment>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    text-align: center;
`;
