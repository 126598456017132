import { useSearchParams } from 'react-router-dom';

import { PasswordChangeForm } from 'shared/widgets/PasswordChangeForm';
import { AuthForm } from 'features/auth/components';
import { useResetPasswordMutation } from 'features/auth/services';

export const Reset = () => {
    const [searchParams] = useSearchParams();

    const [resetPassword] = useResetPasswordMutation();

    const handleSubmit = async (values: {
        password: string;
        password_confirmation: string;
    }) => {
        const payload = {
            token: searchParams.get('token'),
            email: searchParams.get('email'),
            password: values.password,
            password_confirmation: values.password_confirmation,
        };

        try {
            await resetPassword(payload).unwrap();
            window.location.replace('/');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <AuthForm title='Сброс пароля' description='Введите новый пароль'>
            <PasswordChangeForm onSubmit={handleSubmit} />
        </AuthForm>
    );
};
