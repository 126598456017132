import React, { Fragment } from 'react';
import { Button, Flex, Typography } from 'antd';

import { Settings } from '../Settings';
import { useToggle } from '../../../../hooks/useToggle';
import { LegalCreateContainer } from './containers/LegalCreateContainer';
import { LegalEntityValues } from '../../interfaces/legalEntity.interface';
import { LegalEditContainer } from './containers/LegalEditContainer';

const { Text } = Typography;

interface AddLegalInfo extends LegalEntityValues {
    text?: string;
}

export const AddLegalInfo: React.FC<AddLegalInfo> = props => {
    const { open, setOpen } = useToggle();
    const {
        text,
        name,
        email,
        phone,
        inn,
        kpp,
        id,
        ogrn,
        address,
        additionally,
    } = props;

    const toggle = {
        open: open,
        setOpen: setOpen,
    };

    return (
        <Fragment>
            <Settings
                title='Сведения юрлиц'
                description={
                    !id
                        ? 'Заполните сведения о своей организации, чтобы принимать оплату через Telegram'
                        : null
                }
            >
                <>
                    {id && (
                        <Flex vertical gap={10}>
                            <Text>{name}</Text>
                            <Flex vertical={false} gap={'.5rem'}>
                                {inn && <Text>ИНН {inn}</Text>}
                                {kpp && <Text>КПП {kpp}</Text>}
                                {ogrn && <Text>ОГРН {ogrn}</Text>}
                            </Flex>
                            <Text>{email}</Text>
                            <Text>{address}</Text>
                            {additionally && <Text>{additionally}</Text>}

                            {phone && (
                                <Text>+{phone}</Text>
                            )}
                        </Flex>
                    )}
                    <Flex style={{ marginTop: '10px' }}>
                        <Button onClick={() => setOpen(true)}>{text}</Button>
                    </Flex>
                </>
            </Settings>
            {id ? (
                <LegalEditContainer {...toggle} {...props} />
            ) : (
                <LegalCreateContainer {...toggle} />
            )}
        </Fragment>
    );
};
