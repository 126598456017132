import { App, Flex, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Page } from 'shared/ui/Page';
import { SubscriptionCard } from 'features/subscriptions/components';

import { SubscriptionSlug } from '../../interfaces/subscription.interface';
import { useToggle } from '../../../../hooks/useToggle';
import {
    useCreateLegalBillMutation,
    useLazyReadLegalInfoQuery,
} from '../../../user/services/legalEntity';

export const Subscriptions: React.FC = () => {
    const { notification } = App.useApp();
    const [searchParams] = useSearchParams();
    const [subscriptionSlug, setSubscriptionSlug] = useState(
        SubscriptionSlug.payJuriPlan
    );

    useEffect(() => {
        if (searchParams && searchParams.get('type')) {
            setSubscriptionSlug(searchParams.get('type'));
        }
    }, [searchParams]);

    const [getLegalInfo, { data: legalInfo, isLoading: loadingReadLegal }] =
        useLazyReadLegalInfoQuery();

    const [
        createLegalBill,
        {
            data,
            isLoading: loadingCreateBill,
            isSuccess: successCreateBill,
            isError: errorCreateBill,
        },
    ] = useCreateLegalBillMutation();

    useEffect(() => {
        if (subscriptionSlug === SubscriptionSlug.payJuriPlan) {
            void getLegalInfo(null, true);
        }
    }, [subscriptionSlug]);

    const { open: openLegalInfo, setOpen: setOpenLegalInfo } = useToggle();

    const payment_result = searchParams.get('payment_result');

    useEffect(() => {
        if (payment_result === 'success') {
            notification.success({
                placement: 'top',
                message: 'Оплата',
                description: 'Вы успешно оплатили подписку.',
                duration: 4,
                type: 'success',
            });
        }
        if (payment_result === 'fail') {
            notification.error({
                placement: 'top',
                message: 'Ошибка',
                description: 'Произошла ошибка при оплате подписки',
                duration: 4,
                type: 'error',
            });
        }
    }, [payment_result]);

    return (
        <Page title='Подписка'>
            <Flex justify={'flex-end'} style={{ marginBottom: '1.5rem' }}>
                <Radio.Group
                    value={subscriptionSlug}
                    defaultValue={SubscriptionSlug.payJuriPlan}
                    onChange={e => {
                        setSubscriptionSlug(e.target.value);
                    }}
                >
                    {/* <Radio.Button
                        type={'link'}
                        value={SubscriptionSlug.payPlan}
                    >
                        Физлицам
                    </Radio.Button>*/}
                    <Radio.Button
                        type={'link'}
                        value={SubscriptionSlug.payJuriPlan}
                    >
                        Юрлицам
                    </Radio.Button>
                </Radio.Group>
            </Flex>
            <SubscriptionCard
                legalBillUrl={data?.url}
                loadingLegal={loadingReadLegal}
                createLegalBill={createLegalBill}
                successCreateBill={successCreateBill}
                errorCreateBill={errorCreateBill}
                openLegalInfo={openLegalInfo}
                setOpenLegalInfo={setOpenLegalInfo}
                subscriptionSlug={subscriptionSlug}
                {...legalInfo?.[0]}
            />
        </Page>
    );
};
