import { useEffect, useState } from 'react';

import { ISubscription } from 'features/subscriptions/interfaces';
import { IUser } from 'features/user/interfaces';
import { useReadSubscriptionsQuery } from 'features/subscriptions/services';

import { getUserSubscription } from './helpers';
import { useReadUserQuery } from '../../features/user/services';

export const useProfileData = () => {
    const [user, setUser] = useState<IUser>();
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>();
    const [subscription, setSubscription] = useState<ISubscription>();

    const { data: userData, refetch } = useReadUserQuery();
    const { data: subscriptionsData } = useReadSubscriptionsQuery();

    useEffect(() => {
        if (userData && subscriptionsData) {
            setSubscription(getUserSubscription(userData, subscriptionsData));
        }
        setUser(userData);
        setSubscriptions(subscriptionsData);
    }, [userData, subscriptionsData]);

    return {
        user,
        subscriptions,
        subscription,
        refetch,
    };
};
