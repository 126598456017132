import React from 'react';
import { Typography } from 'antd';

import { rub } from '../../../../shared/contants/styles';
import { useTheme } from '../../../../hooks/useTheme';

type TProductPrice = {
    readonly price: number | string;
};
export const ProductPrice: React.FC<TProductPrice> = props => {
    const { price } = props;
    const token = useTheme();

    return (
        <Typography.Text strong style={{ color: token.colorPrimary }}>
            {price}
            {rub}
        </Typography.Text>
    );
};
