import React, { Fragment, useState } from 'react';
import { Checkbox, Typography } from 'antd';

import { GenericModal } from '../GenericModal';

type Props = {
    readonly title: string;
    readonly description: string;
    readonly activator: React.ReactElement;
    readonly onConfirm: () => void;
    readonly withCheckbox?: boolean;
};
export const ConfirmationDialog: React.FC<Props> = props => {
    const [open, setOpen] = useState(false);
    const [checkBoxStatus, setCheckboxStatus] = useState<boolean>(true);

    const handleConfirm = () => {
        props.onConfirm();
        setOpen(false);
    };

    return (
        <Fragment>
            {React.cloneElement(props.activator, {
                onClick: () => setOpen(true),
            })}
            <GenericModal
                onConfirm={handleConfirm}
                onCancel={() => setOpen(false)}
                open={open}
                title={props.title}
                okButtonProps={{
                    disabled: props.withCheckbox ? !checkBoxStatus : false,
                    danger: true,
                }}
                okText='Удалить'
            >
                <Typography.Text>{props.description}</Typography.Text>

                {props.withCheckbox && (
                    <div style={{ marginTop: '1.5rem' }}>
                        <Checkbox
                            checked={checkBoxStatus}
                            onChange={() => setCheckboxStatus(!checkBoxStatus)}
                        >
                            Я понимаю последствия
                        </Checkbox>
                    </div>
                )}
            </GenericModal>
        </Fragment>
    );
};
