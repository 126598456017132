import React, { useEffect, useRef } from 'react';
import { Input } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import styled from 'styled-components';

import { Form, Field } from 'shared/ui/Form';

import validationSchema from './validationSchema';

type Props = {
    readonly email: string;
    readonly isLoading: boolean;
    readonly onCancel: () => void;
    readonly onSubmit: (message: string) => void;
};

type FormValues = {
    fb_message: string;
};

const FeedbackForm: React.FC<Props> = props => {
    const inputRef = useRef<TextAreaRef>();

    const handleSubmit = (values: FormValues) => {
        props.onSubmit(values.fb_message);
    };

    const handleCancel = () => {
        props.onCancel();
    };

    useEffect(() => {
        inputRef.current.focus();
    });

    return (
        <Form
            initialValues={{
                fb_message: '',
            }}
            onSubmit={handleSubmit}
            onCancel={props.onCancel}
            validationSchema={validationSchema}
            validateOnMount={false}
            validateOnBlur={false}
        >
            <Field name='fb_message' label='Сообщение'>
                <Input.TextArea ref={inputRef} />
            </Field>
            <Hint>Ответ придёт на {props.email}</Hint>
        </Form>
    );
};

export default FeedbackForm;

const Hint = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
`;
