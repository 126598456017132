import { combineReducers } from 'redux';

import { authApi } from 'features/auth/services';
import { userApi } from 'features/user/services';
import { subscriptionsApi } from 'features/subscriptions/services';

import { productsApi } from '../features/shops/services/products';
import { shopsApi } from '../features/shops/services/shops';
import { productsCategoriesApi } from '../features/shops/services/categories';
import { legalEntityApi } from '../features/user/services/legalEntity';
import { promocodeApi } from '../features/shops/services/promocode';

const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [shopsApi.reducerPath]: shopsApi.reducer,
    [productsCategoriesApi.reducerPath]: productsCategoriesApi.reducer,
    [legalEntityApi.reducerPath]: legalEntityApi.reducer,
    [promocodeApi.reducerPath]: promocodeApi.reducer,
});

export default rootReducer;
