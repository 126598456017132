import React, { useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';

import { useUpdateSubscriptionMutation } from '../../services';
import { useProfileData } from '../../../../hooks/useProfileData';
import { GenericModal } from '../../../../shared/ui/GenericModal';

export const Recurrent = props => {
    const { user, refetch = [] } = useProfileData();

    const [open, setOpen] = useState(false);

    const [updateSubscription, { isLoading, isSuccess }] =
        useUpdateSubscriptionMutation();

    useEffect(() => {
        if (isSuccess) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            refetch();
        }
    }, [isSuccess]);

    const isRecurrent = user?.subscription.isRecurrent;

    const handleUpdateSubscription = () => {
        if (isRecurrent) {
            setOpen(true);
        } else
            updateSubscription({})
                .unwrap()
                .finally(() => setOpen(false));
    };

    return (
        <Space direction='vertical' align='end'>
            <Button
                size={'small'}
                loading={isLoading}
                disabled={isLoading}
                type={isRecurrent ? 'link' : 'primary'}
                onClick={() => {
                    handleUpdateSubscription();
                }}
            >
                {isRecurrent ? 'Отключить' : 'Подключить автопродление'}
            </Button>

            <GenericModal
                title={'Отключить'}
                destroyOnClose
                width={360}
                open={open}
                okText={'Отключить'}
                onCancel={() => {
                    setOpen(false);
                }}
                onConfirm={() =>
                    updateSubscription({})
                        .unwrap()
                        .finally(() => setOpen(false))
                }
                okButtonProps={{
                    danger: true,
                }}
            >
                <Space direction={'vertical'} size={'large'}>
                    <Typography.Text>
                        Вы действительно хотите отключить подписку?
                    </Typography.Text>
                    <Typography.Text>
                        После {props.expirationDate} все платные функции станут
                        недоступны.
                    </Typography.Text>
                </Space>
            </GenericModal>
        </Space>
    );
};
