/**
 * Функция склонения окончания слов в зависимости от длины, например массива.
 * Возвращает слово в корркетном склонении в формате string
 * @param number длина
 * @param one склоенение слова в случае 1
 * @param two склоенение слова в случае 2
 * @param five склоенение слова в случае более 5
 */
export const getDeclension = (
    number: number,
    one: string,
    two: string,
    five: string
): string => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};
