export interface ISubscription {
    readonly commission: number;
    readonly deleted_at: Date;
    readonly description: string;
    readonly file_id: null;
    readonly is_active: true;
    readonly name: string;
    readonly period_days: number;
    readonly price: number;
    readonly slug: string;
    readonly id: number;

    readonly isRecurrent?: boolean;
    readonly sort_order?: number;
    readonly created_at?: Date;
    readonly updated_at?: Date;
}

export interface ICheckSubscription {
    readonly expired_status: boolean;
}

export interface ISubscriptions {
    data: {
        readonly commissions: number;
        readonly id: number;
        readonly name: string;
        readonly is_active: boolean;
        readonly price: number;
        slug: string;
    };
}

export enum SubscriptionSlug {
    trial = 'trial_plan',
    payPlan = 'pay_plan',
    payJuriPlan = 'pay_juri_plan',
}

export interface ActivatedSubscription {
    readonly name: string;
    readonly description: string;
    readonly price: number;
}
