import { Button, Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { PhoneConfirmation } from '../PhoneConfirmation';
import { PhoneInput } from '../PhoneInput';
import { PhoneEditorSteps } from './types';
import { useProfileData } from '../../../../../../hooks/useProfileData';

type TProps = {
    readonly isPhonePrimary: boolean;
};
export const PhoneEditor: React.FC<TProps> = props => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [step, setStep] = useState<PhoneEditorSteps>(PhoneEditorSteps.Input);

    const { user } = useProfileData();

    const handleCancel = () => {
        setOpen(false);
        setStep(PhoneEditorSteps.Input);
        setPhoneNumber('');
    };
    const handlePhoneInputChange = (phone: string) => {
        setPhoneNumber(phone);
        setStep(PhoneEditorSteps.Confirm);
    };
    const handlePhoneChange = () => {
        setStep(PhoneEditorSteps.Input);
    };

    const handleOpenEditModal = () => {
        setOpen(true);
    };

    return (
        <Fragment>
            <Wrapper>
                {user?.phone ? (
                    <Button onClick={handleOpenEditModal}>
                        Сменить телефон
                    </Button>
                ) : (
                    <Button
                        type={props.isPhonePrimary ? 'primary' : 'default'}
                        onClick={handleOpenEditModal}
                    >
                        Добавить телефон
                    </Button>
                )}
                {/*<Button onClick={handleOpenEditModal}>
                    {user?.phone ? 'Сменить телефон' : 'Добавить телефон'}
                </Button>*/}
                <Modal
                    width={380}
                    footer={null}
                    open={isOpen}
                    title='Номер телефона'
                    onCancel={handleCancel}
                >
                    {step === PhoneEditorSteps.Input && (
                        <PhoneInput
                            phone={phoneNumber}
                            onCancel={handleCancel}
                            onChange={handlePhoneInputChange}
                        />
                    )}
                    {step === PhoneEditorSteps.Confirm && (
                        <PhoneConfirmation
                            phone={phoneNumber}
                            onCancel={handleCancel}
                            onPhoneChange={handlePhoneChange}
                        />
                    )}
                </Modal>
            </Wrapper>
        </Fragment>
    );
};

const Wrapper = styled.div`
    margin-top: 8px;
`;
