import React, { useEffect } from 'react';
import { Card, Divider, GlobalToken, Spin } from 'antd';
import styled from 'styled-components';

import { Page } from 'shared/ui/Page';
import { IUser } from 'features/user/interfaces';
import {
    Author,
    BankCards,
    CurrentSubscription,
    Email,
    Password,
    Phone,
    TelegramAccounts,
    DeleteAccount,
    AddLegalInfo,
} from 'features/user/components';

import { useReadUserQuery } from '../../services';
import { useTheme } from '../../../../hooks/useTheme';
import { useLazyReadLegalInfoQuery } from '../../services/legalEntity';

export const Profile: React.FC = () => {
    const {
        data: user = {
            telegram_accounts: [],
        } as IUser,
    } = useReadUserQuery();

    const token = useTheme();

    const [getLegalInfo, { data: legalInfo, isLoading }] =
        useLazyReadLegalInfoQuery();

    const isAccountPrimary = user?.telegram_accounts?.length === 0;
    const isPhonePrimary =
        user?.telegram_accounts?.length > 0 && user?.phone === null;
    const isBankCardPrimary =
        user?.telegram_accounts?.length > 0 && user?.phone !== null;

    useEffect(() => {
        if (user) {
            void getLegalInfo(null, true);
        }
    }, [user]);

    return (
        <Page title='Профиль'>
            <StyledWrapper>
                <StyledCard token={token} type='inner' style={token.Card}>
                    <CurrentSubscription isEdit={true} />
                </StyledCard>

                <StyledCard token={token} type='inner' style={token.Card}>
                    <TelegramAccounts
                        isAccountPrimary={isAccountPrimary}
                        accounts={user.telegram_accounts}
                    />
                    <Phone
                        confirmed={user.phone_confirmed}
                        phone={user.phone}
                        isPhonePrimary={isPhonePrimary}
                    />
                    <BankCards isBankCardPrimary={isBankCardPrimary} />
                    <Author />
                    <Divider />
                    <Email email={user.email} />
                    <Password />
                    {isLoading ? (
                        <Spin />
                    ) : (
                        <AddLegalInfo
                            text={!legalInfo ? 'Заполнить' : 'Сменить сведения'}
                            {...legalInfo}
                        />
                    )}
                    <Divider />
                    <DeleteAccount />
                </StyledCard>
            </StyledWrapper>
        </Page>
    );
};

const StyledWrapper = styled.main`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const StyledCard = styled(Card)<{
    token: GlobalToken;
    style?: any;
}>`
    flex: 1;
    width: 100%;
`;
