import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

import Logo from 'static/images/logo.png';
import { useTheme } from 'hooks/useTheme';

type TProps = {
    readonly title: string;
    readonly description: string;
    readonly children?: React.ReactElement | React.ReactElement[];
    readonly meta?: {
        readonly text: string;
        readonly link: string;
        readonly linkText: string;
    };
};
export const AuthForm: React.FC<TProps> = props => {
    const token = useTheme();
    return (
        <Wrapper>
            <StyledCard type='inner' style={{ ...token.Card, width: 380 }}>
                <img src={Logo} alt='Logo' height={30} />
                <Heading>{props.title}</Heading>
                <Description>{props.description}</Description>
                {props.children}
                {props.meta && (
                    <Actions>
                        <span>{props.meta.text}</span>
                        <Link to={props.meta.link}>{props.meta.linkText}</Link>
                    </Actions>
                )}
            </StyledCard>
        </Wrapper>
    );
};

const StyledCard = styled(Card)`
    padding-top: 16px;

    @media (max-width: 575px) {
        box-shadow: none !important;
        border: none;
    }
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Heading = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(37, 33, 41, 0.88);
    margin-top: 24px;
`;

const Description = styled.p`
    margin-bottom: 32px;
`;

const Actions = styled.div`
    margin-top: 40px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 12px;
    text-decoration: none;
`;
