import { Drawer, GlobalToken, Space } from 'antd';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

import Logo from 'static/images/logo.png';
import { Container } from 'shared/ui/Container';
import { useTheme } from 'hooks/useTheme';
import getEnvConfig from 'utils/config/env';

import { Feedback } from './components/Feedback';
import UserMenu from './components/UserMenu';
import { useWindowSize } from '../../../../../hooks/useWindowSize';

export const Navbar: React.FC = () => {
    const token = useTheme();
    const { windowSize, size } = useWindowSize();
    const [open, setOpen] = useState<boolean>(false);

    const mainMenu = [
        {
            slug: 'shops',
            name: 'Магазины',
            path: `${getEnvConfig().PUBLIC_PATH}/shops`,
        },
    ];

    const handleMenuToggle = () => {
        setOpen(!open);
    };

    return (
        <StyledWrapper>
            <Container>
                <StyledNav>
                    {size === 'small' ? (
                        <>
                            <StyledMobileBurger onClick={handleMenuToggle}>
                                <MenuOutlined />
                            </StyledMobileBurger>

                            <Drawer
                                placement='left'
                                onClose={handleMenuToggle}
                                open={open}
                            >
                                <Space direction={'vertical'} size={'large'}>
                                    {mainMenu.map((menu, index) => (
                                        <Fragment key={menu.slug}>
                                            <StyledLink
                                                key={menu.slug}
                                                token={token}
                                                to={`${menu.path}`}
                                                onClick={handleMenuToggle}
                                            >
                                                {menu.name}
                                            </StyledLink>
                                        </Fragment>
                                    ))}
                                </Space>
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <NavLink to='/'>
                                <img height={22} alt='logo' src={Logo} />
                            </NavLink>
                            <StyledTabs>
                                <StyledLink
                                    token={token}
                                    to={`${getEnvConfig().PUBLIC_PATH}/shops`}
                                >
                                    Магазины
                                </StyledLink>
                            </StyledTabs>
                        </>
                    )}

                    <div>
                        <Space>
                            <Feedback />
                            <UserMenu />
                        </Space>
                    </div>
                </StyledNav>
            </Container>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    border-bottom: 1px solid rgba(37, 33, 41, 0.06);
`;

const StyledNav = styled.nav`
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 6px;
`;

const StyledTabs = styled.div`
    font-size: 14px;
    color: rgba(37, 33, 41, 0.25);
    display: flex;
    justify-content: center;
    gap: 40px;

    @media (max-width: 600px) {
        display: none;
    }
`;

const StyledLink = styled(NavLink)<{ token: GlobalToken; $active?: boolean }>`
    padding-bottom: 4px;
    position: relative;
    color: ${props => props.token.colorTextBase};

    &.active {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -8px;
            border-bottom: 2px solid ${props => props.token.colorPrimary};
        }
    }
`;

const StyledMobileBurger = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
