import { MutableRefObject, useMemo, useSyncExternalStore } from 'react';

const subscribe = (callback: {
    (this: Window, ev: UIEvent): unknown;
    (this: Window, ev: UIEvent): unknown;
}) => {
    window.addEventListener('resize', callback);
    return () => {
        window.removeEventListener('resize', callback);
    };
};

interface IDimension {
    offsetWidth: number;
    offsetHeight: number;
}
const useDimensions = (ref: MutableRefObject<IDimension>) => {
    const dimensions = useSyncExternalStore(subscribe, () =>
        JSON.stringify({
            width: ref.current?.offsetWidth ?? 0,
            height: ref.current?.offsetHeight ?? 0,
        })
    );
    return useMemo(() => JSON.parse(dimensions), [dimensions]);
};

export { useDimensions };
