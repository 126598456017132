import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import { useTheme } from '../../../../hooks/useTheme';

type TProductTitle = {
    readonly type: string;
    readonly title: string;
};

export enum ProductTitleType {
    list = 'list',
    card = 'card',
    checkout = 'checkout',
    page = 'page',
}

export const ProductTitle: React.FC<TProductTitle> = props => {
    const { type, title } = props;
    const token = useTheme();

    const [node, setNode] = useState(null);

    useEffect(() => {
        if (type) {
            switch (type) {
                case 'list':
                    return setNode(
                        <StyledParagraph
                            style={{
                                marginTop: '.5rem',
                                marginBottom: '.25rem',
                                color: token.colorTextBase,
                            }}
                            ellipsis={{
                                rows: 1,
                            }}
                        >
                            {title}
                        </StyledParagraph>
                    );
                case 'card':
                    return setNode(
                        <StyledParagraph
                            style={{
                                marginTop: '.5rem',
                                color: token.colorTextBase,
                            }}
                            size={4}
                        >
                            {title}
                        </StyledParagraph>
                    );
                case 'checkout':
                    return setNode(
                        <StyledParagraph
                            ellipsis={{
                                rows: 3,
                            }}
                            style={{ margin: 0, color: token.colorTextBase }}
                            size={4}
                        >
                            {title}
                        </StyledParagraph>
                    );
                case 'page':
                    return setNode(
                        <Typography.Title
                            ellipsis={{
                                rows: 1,
                            }}
                            level={3}
                            style={{
                                marginTop: 0,
                                marginBottom: '.5rem',
                                color: token.colorTextBase,
                                fontWeight: 600,
                            }}
                        >
                            {title}
                        </Typography.Title>
                    );
            }
        }
    }, [type, token, title]);

    return node;
};

const StyledParagraph = styled(Typography.Paragraph)<{ size?: number }>`
    font-size: ${props => (props.size === 4 ? '1.25rem' : '.75rem')};
    font-weight: 600;
`;
