import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { ProductForm } from '../../views/Products/ProductForm';
import { useCreateProductMutation } from '../../services/products';
import { createFormData } from '../../../../utils/common/createFormData';
import { IProducts, ProductStatus } from '../../interfaces/products.interface';

export const ProductCreateContainer = () => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [
        createProduct,
        {
            isSuccess: isSuccessCreate,
            isLoading: isLoadingCreate,
            isError: isErrorCreate,
        },
    ] = useCreateProductMutation();

    useEffect(() => {
        if (isSuccessCreate) {
            notification.success({
                message: `Товар успешно создан`,
            });
            navigate(-1);
        }
    }, [isSuccessCreate]);

    useEffect(() => {
        if (isErrorCreate) {
            notification.error({
                message: 'Произошла ошибка',
            });
        }
    }, [isErrorCreate]);

    const onSave = async (values: IProducts) => {
        if (values.title === '') {
            return notification.error({
                message: 'Нужно указать  название товара',
            });
        }

        if (values.images?.length < 1) {
            return notification.error({
                message: 'Нужно загрузить картинку',
            });
        }

        if (values.price === 0) {
            return notification.error({
                message: `Цена не может быть ${values.price}`,
            });
        }

        const data = {
            shop_id: +id,
            title: values.title,
            description: values.description,
            price: values.price,
            images: values.images,
            buyable: values.buyable,
            category_id: values.category_id,
            status: values.status,
        };

        await createProduct(createFormData(data));
    };

    const initialValues: IProducts = {
        title: 'Название товара',
        description: '',
        price: null,
        images: [],
        buyable: false,
        category_id: 0,
        status: ProductStatus.active,
    };

    return (
        <ProductForm
            shopId={+id}
            initialValues={initialValues}
            onSave={onSave}
            loading={isLoadingCreate}
        />
    );
};
