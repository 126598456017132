import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

export const format =
    (timestamp_unix: number) =>
    (pattern: string): string => {
        return dayjs.unix(timestamp_unix).format(pattern);
    };

export const formatExtended = (time: string) => {
    return dayjs(time).format('DD.MM.YY');
};

export const formatWithTime = time => {
    return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
};
