import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type TProps = unknown;

export const ErrorBoundaryFallback: React.FC<TProps> = () => {
    const navigate = useNavigate();

    return (
        <StyledWrapper>
            <Result
                status='500'
                title='Ой, что-то пошло не так...'
                subTitle='Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время.'
                extra={
                    <Button type='primary' onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                }
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
