import React, { Fragment } from 'react';
import { Button, Empty, Flex, Table, TableProps, Typography } from 'antd';

import { IProductCategory } from '../../interfaces/categories.interface';
import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { Uncategorized } from './categoryFilters';

export const CategoryTable = props => {
    const {
        selectedRows,
        setSelectedRows,
        loading,
        handleDeleteCategory,
        handleRenameCategory,
        source,
    } = props;

    const rowSelection = {
        selectedRows: selectedRows,
        onChange: setSelectedRows,
        getCheckboxProps: record => ({
            disabled: record.id === 0,
        }),
    };

    const columns: TableProps<IProductCategory>['columns'] = [
        {
            title: 'Название категории',
            dataIndex: 'name',
            ellipsis: true,
            width: 250,
            render: name => <Typography.Text>{name}</Typography.Text>,
        },

        {
            title: 'Действия',
            render: category =>
                category.id === 0 ? null : (
                    <Flex gap={10}>
                        <ConfirmationDialog
                            activator={
                                <Button
                                    loading={loading}
                                    disabled={
                                        loading || selectedRows?.length > 1
                                    }
                                    style={{ paddingLeft: 0 }}
                                    type='link'
                                >
                                    Удалить
                                </Button>
                            }
                            onConfirm={() => handleDeleteCategory(category.id)}
                            title='Удалить?'
                            description={`Вниамние! Товары будут перенесены в "${Uncategorized}"`}
                            withCheckbox
                        />

                        <Button
                            loading={loading}
                            disabled={loading || selectedRows?.length > 1}
                            type='link'
                            onClick={() => handleRenameCategory(category)}
                        >
                            Переименовать
                        </Button>
                    </Flex>
                ),
        },
    ];

    return (
        <Fragment>
            <Table
                rowKey={category => category.id}
                dataSource={source?.items}
                locale={{ emptyText: <Empty description='Нет категорий' /> }}
                columns={columns}
                rowSelection={rowSelection}
                loading={loading}
                pagination={false}
            />
            <Flex justify={'flex-end'}>
                <Typography.Text>Категорий: {source?.total}</Typography.Text>
            </Flex>
        </Fragment>
    );
};
