import React, { useEffect } from 'react';

import { Page } from '../../../ui/Page';

const UserAgreement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={'Пользовательское соглашение'}>
            <p>
                Настоящее Соглашение определяет порядок использования Платформы.
                Любое лицо, которое использует Платформу, должно соблюдать
                условия Соглашения. Помимо условий настоящего Соглашения, также
                должны соблюдаться условия приложений к нему.
            </p>
            <ul>
                <li>
                    <strong>
                        <strong>Термины и определения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Авторизационные данные </strong>&ndash;уникальная
                    пара &laquo;логин-пароль&raquo;, используемая для входа в
                    Личный кабинет после Регистрации Пользователя на
                    Платформе.&nbsp;
                </li>
                <li>
                    <strong>Контент </strong>&ndash; размещенная
                    (воспроизведенная) на Платформе совокупность результатов
                    интеллектуальной деятельности (РИД), а также каждый РИД в
                    отдельности, включая, но не ограничиваясь: любая текстовая
                    информация, изображения, фотографии, ссылки, видеозаписи,
                    аудиозаписи, и так далее.&nbsp;
                </li>
                <li>
                    <strong>Личный кабинет</strong> &ndash; персональный раздел
                    Платформы, доступный Пользователю после успешной
                    Регистрации. Доступ в Личный кабинет обеспечивается вводом
                    Пользователем Авторизационных данных.
                </li>
                <li>
                    <strong>Пользователь </strong>&ndash; физическое лицо,
                    достигшее возраста 18 лет и обладающее полной
                    правоспособностью, в том числе, зарегистрированное в
                    качестве индивидуального предпринимателя, либо являющееся
                    плательщиком налога на профессиональный доход (самозанятый),
                    а также юридическое лицо (действующее в лице уполномоченного
                    представителя), которое приняло условия Соглашения, и
                    получило доступ в Личный кабинет.&nbsp;
                </li>
                <li>
                    <strong>Посетитель </strong>&ndash; лицо, просматривающее /
                    использующее Платформу без Регистрации.
                </li>
                <li>
                    <strong>Платформа </strong>&ndash; принадлежащий
                    SaaS-провайдеру программно-аппаратный комплекс{' '}
                    <strong>&laquo;Spodial&raquo; </strong>по адресу:{' '}
                    <a href='https://spodial.com'>https://spodial.com</a>, а
                    также отдельный функционал в мессенджере Telegram
                    @SpodialBot.
                </li>
                <li>
                    <strong>
                        Политика обработки персональных данных и файлов Cookie{' '}
                    </strong>
                    &ndash; приложение к Соглашению, регулирующее порядок
                    обработки SaaS-провайдером персональных данных, размещенное
                    в сети Интернет по ссылке:{' '}
                    <a href='https://spodial.com/privacy_policy'>
                        https://spodial.com/privacy_policy
                    </a>
                </li>
                <li>
                    <strong>
                        Правила совершения платежей и возврата оплаты{' '}
                    </strong>
                    &ndash;приложение к Соглашению, регулирующие порядок
                    совершения платежей и возврата принятой оплаты, размещенное
                    в сети Интернет по ссылке:{' '}
                    <a href='https://spodial.com/payment_rules'>
                        https://spodial.com/payment_rules
                    </a>
                </li>
                <li>
                    <strong>Покупатель</strong> &ndash; физическое лицо,
                    покупатель товара у Продавца.
                </li>
                <li>
                    <strong>Продавец</strong> &ndash; Пользователь Платформы,
                    который приобрел Пакет услуг &laquo;Онлайн магазин&raquo;, и
                    использует ставший доступным функционал разрешенным
                    настоящим Соглашением способом для реализации Товаров
                    Покупателям.
                </li>
                <li>
                    <strong>Пароль </strong>&ndash; комбинация символов,
                    генерируемая в автоматическом порядке по поручению
                    Пользователя в ходе Регистрации, которая впоследствии может
                    быть изменена Пользователем в Личном кабинете
                    самостоятельно, и используемая для верификации Пользователя
                    на Платформе и получения доступа в Личный кабинет.
                </li>
                <li>
                    <strong>Пакет услуг </strong>&ndash; комплекс услуг,
                    оказываемых SaaS-провайдером Пользователю, заказ и оплата
                    которых была произведена Пользователем в соответствии с
                    условиями, указанными в Личном кабинете.
                </li>
                <li>
                    <strong>Регистрация </strong>&ndash; добровольная процедура,
                    совершаемая Пользователем с использованием
                    онлайн-интерфейсов Платформы, в ходе которой по поручению
                    Пользователя генерируется Пароль, и по завершении которой
                    создается учетная запись Пользователя, обеспечивающая доступ
                    в Личный кабинет.
                </li>
                <li>
                    <strong>Разрешенный вид использования </strong>&ndash;
                    следующий разрешенный вид использования Платформы, в
                    зависимости от целей такого использования: (1) для
                    посетителей, Пользователей, Продавцов и Покупателей -
                    просмотр Контента, (2) для Пользователей - использование
                    расширенного функционала Платформы, в соответствии с
                    условиями приобретаемого Пакета услуг, в том числе, для
                    получения статуса &laquo;Продавец&raquo;, (3) для
                    Покупателей - приобретение Товаров у Продавцов.&nbsp;
                </li>
                <li>
                    <strong>Регистрационные данные </strong>&ndash; указанная на
                    Платформе совокупность данных, предоставляемая Пользователем
                    при прохождении процедуры Регистрации.
                </li>
                <li>
                    <strong>Соглашение </strong>&ndash; настоящее
                    Пользовательское соглашение, размещенное в сети Интернет по
                    ссылке:{' '}
                    <a href='https://spodial.com/user_agreement'>
                        https://spodial.com/user_agreement
                    </a>
                </li>
                <li>
                    <strong>Технология SaaS </strong>&ndash; модель
                    предоставления программного обеспечения, при которой
                    Пользователи получают доступ к Платформе через интернет с
                    использованием специальных программ &ndash; браузеров, без
                    установки клиентской части на своих компьютерах. Указанный
                    доступ к Платформе предоставляется на условиях простой
                    (неисключительной), территориально неограниченной (всемирной
                    - за исключением стран, запрещающих использование Платформы)
                    лицензии, без возможности выдачи сублицензий третьим лицам,
                    без необходимости для Пользователя в предоставлении отчетов
                    об использовании лицензии. Если иное не указано в
                    Соглашении, срок и стоимость предоставляемой лицензии
                    определяется условиями приобретения Пакета услуг.
                </li>
                <li>
                    <strong>Saas-провайдер </strong>&ndash; Общество с
                    ограниченной ответственностью &laquo;Фабрика информационных
                    технологий&raquo; (ООО &laquo;ФИТ&raquo;), ИНН: 3123164163,
                    ОГРН: 1073123023160, адрес для направления почтовой
                    корреспонденции: 308033, г. Белгород, ул. Королева, д 2А,
                    пом. 8, адрес электронной почты:{' '}
                    <a href='mailto:info@spodial.com'>info@spodial.com</a>.
                </li>
            </ol>
            <ol>
                <li>
                    Термины, которые употребляются в настоящем документе, но
                    которым не было дано определение, понимаются в значении,
                    которое придается им приложениями к Соглашению, далее - в
                    соответствии с законодательством РФ, а в последнюю очередь -
                    в значении, которое придается им в сети Интернет.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Преамбула</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Продолжая использовать Платформу, Пользователь подтверждает,
                    что не пребывает в состоянии заблуждения (существенного или
                    несущественного) относительно характеристик и функционала
                    Платформы, условий Соглашения, приложений к ним и своих
                    собственных действий. Пользователь принимает, что в
                    соответствии с настоящим Соглашением Платформа для
                    использования в указанных Соглашением и/или иных документах
                    пределах предоставляется &laquo;как есть&raquo; (если прямо
                    не предусмотрено иное), включая без ограничений любую
                    документацию, улучшения и обновления, и SaaS-провайдер не
                    предоставляет никаких гарантий или условий (прямых или
                    подразумеваемых законодательством и/или обычаями делового
                    оборота) относительно чего бы то ни было, в том числе,
                    интегрируемости, удовлетворительного качества и годности к
                    использованию для выполнения конкретных задач.
                    SaaS-провайдер также не гарантирует, что Платформа не
                    содержат ошибок, будет удовлетворять требованиям
                    Пользователя, или что будет функционировать должным образом
                    при использовании совместно с любым другим программным или
                    аппаратным обеспечением. SaaS-провайдер не гарантирует и не
                    может гарантировать результаты, которые Пользователь может
                    получить путем использования Платформы. Все риски, связанные
                    с качеством работы и производительностью Платформы
                    возлагаются на Пользователя.
                </li>
                <li>
                    SaaS-провайдер стремится обеспечить постоянный доступ к
                    Платформе. Вместе с тем, SaaS-провайдер вправе приостановить
                    обеспечение Пользователю постоянного доступа либо отдельным
                    функциям Платформы, в объеме, порядке и на условиях,
                    предусмотренных Соглашением, а также в случае сбоев в работе
                    или ограничения доступа в связи с обслуживанием,
                    модификациями, удалением, расширениями или вводом
                    дополнительных функций, или в связи с системными нарушениями
                    в Интернете (в том числе на сервере), в случае
                    форс&ndash;мажорных или иных обстоятельств. Таким образом,
                    Исполнитель не гарантирует какой-либо определенной функции
                    Платформы и не несет ответственности за полный или частичный
                    отказ какой-либо определенной функции.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Предмет Соглашения&nbsp;</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    SaaS-провайдер, являясь владельцем, разработчиком и
                    обладателем исключительных прав на Платформу, предоставляет
                    на условиях настоящего Соглашения право использования
                    соответствующей части Платформы по Технологии SaaS, в
                    объеме, соответствующему Разрешенному виду использования, а
                    в отношении Продавцов - выбранному и оплаченному Пакету
                    услуг в Личном кабинете, в оговоренных ниже пределах и
                    способах.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Порядок акцепта Соглашения&nbsp;</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Перед тем, как использовать Платформу (включая просмотр
                    Контента Платформы), Посетитель / Пользователь / Покупатель
                    должен принять условия Соглашения и приложений к нему
                    (совершить акцепт). Порядок акцепта Соглашения для
                    Посетителя, Пользователя и Покупателя отличается, и
                    указывается в настоящем разделе Соглашения.
                </li>
                <li>
                    Акцепт означает, что Посетитель / Пользователь / Покупатель
                    согласен со всеми условиями Соглашения, как публичной
                    оферты, иными обязательными документами, размещенными на
                    Платформе, без каких-либо изъятий либо ограничений.
                </li>
                <li>
                    Все действия, совершенные на Платформе Посетителем /
                    Пользователем / Покупателем, признаются SaaS-провайдером и
                    таким Посетителем / Пользователем / Покупателем, как
                    конклюдентные действия, порождающие соответствующие
                    юридические последствия. При этом Посетитель / Пользователь
                    / Покупатель соглашается с тем, что исчерпывающим
                    доказательством совершения либо несовершения определенного
                    действия с использованием онлайн-интерфейсов Платформы может
                    быть доказано SaaS-провайдером путем предоставления выписки
                    из своей базы данных о наличии сведений, зафиксированных
                    путем логирования (сохранения истории) действий таким лицом
                    на Платформе, или иным аналогичным способом. При
                    возникновении спора, такая информация предоставляется в
                    форме по выбору SaaS-провайдера, и признается в качестве
                    исчерпывающего доказательства указанных в настоящем пункте
                    действий.
                </li>
                <li>
                    В том случае, если Посетитель / Пользователь / Покупатель не
                    согласен с условиями Соглашения, он обязан немедленно
                    покинуть Платформу.
                </li>
            </ol>
            <ol>
                <li>
                    Покупатель считается выразившим акцепт Соглашения, если он в
                    любой форме использовал форму заказа Товаров @SpodialBot.
                    Помимо указанных, от Покупателя не требуется совершения
                    каких-либо дополнительных действий для того, чтобы акцепт
                    Соглашения считался предоставленным.&nbsp;
                </li>
            </ol>
            <ol>
                <li>
                    Посетитель считается выразившим акцепт Соглашения, если он
                    просматривает содержимое Платформы, без прохождения
                    процедуры Регистрации. Просмотр содержимого Платформы
                    означает полный и безоговорочный акцепт Соглашения, и
                    Посетитель получает доступ к функционалу Платформы, за
                    исключением функционала, предназначенного исключительно для
                    зарегистрированных Пользователей. Помимо указанных, от
                    Посетителя не требуется совершения каких-либо дополнительных
                    действий для того, чтобы акцепт Соглашения считался
                    предоставленным.&nbsp;
                </li>
                <li>
                    В том случае, если Посетитель желает получить доступ к
                    дополнительному функционалу Платформы, открытому только для
                    Пользователей, то Посетитель может пройти процедуру
                    Регистрации, чтобы получить статус Пользователя Платформы.
                    Для того, чтобы Пользователь считался выразившим акцепт
                    (согласие) Соглашения, он должен успешно пройти процедуру
                    Регистрации и получить доступ в Личный кабинет. При этом
                    Посетитель имеет право получить статус Пользователя только в
                    том случае, если Посетитель отвечает требованиям к
                    Пользователю, с учетом гарантий и заверений Пользователя,
                    указанных в соответствующем разделе. Принятие условий
                    Соглашения иными лицами не допускается. При выявлении
                    указанного факта, SaaS-провайдер вправе немедленно
                    расторгнуть Соглашение и заблокировать доступ в Личный
                    кабинет.
                </li>
            </ol>
            <ol>
                <li>
                    Для инициирования процедуры Регистрации, Посетителю
                    необходимо нажать на кнопку &laquo;Создать аккаунт&raquo;,
                    после чего указать свои Регистрационные данные: свой адрес
                    электронной почты и имя (логин). После ввода указанных
                    данных, Посетитель должен внимательно ознакомиться с текстом
                    настоящего Соглашения, а также всех приложений к нему. С
                    содержанием указанных документов Посетитель может
                    ознакомиться, перейдя по прямым ссылкам на них,
                    расположенным над кнопкой &laquo;Зарегистрироваться&raquo;.
                    После того, как Посетитель нажмет на кнопку
                    &laquo;Зарегистрироваться&raquo;, процедура Регистрации
                    считается завершенной, Посетитель обретает статус
                    Пользователя и доступ к онлайн-интерфейсам Личного кабинета.
                </li>
                <li>
                    Для последующего доступа в Личный кабинет, Пользователь
                    вводит Авторизационные данные. В качестве логина
                    используется адрес электронной почты Пользователя, а в
                    качестве Пароля - изначально сгенерированная в
                    автоматическом порядке случайная последовательность букв /
                    чисел / символов, направляемая SaaS-провайдером по
                    указанному Пользователем в ходе Регистрации адресу
                    электронной почты.&nbsp;
                </li>
                <li>
                    Пользователь не вправе завершать процедуру Регистрации путем
                    на кнопку &laquo;Зарегистрироваться&raquo; до того, как он
                    ознакомиться с настоящим Соглашением и всеми приложениями к
                    нему, и полностью примет их условия. Успешное прохождение
                    Пользователем процедуры Регистрации означает полный и
                    безоговорочный акцепт Пользователем настоящего Соглашения и
                    всех приложений к нему.
                </li>
                <li>
                    После того, как Пользователь впервые получит доступ в Личный
                    кабинет, он обязуется немедленно изменить пароль, при этом
                    неся полную ответственность за его соответствие разумным
                    требованиям безопасности. Пользователь не вправе
                    использовать функционал Личного кабинета, пользоваться любым
                    функционалом Платформы, до того, как изменит пароль - в ином
                    случае, Пользователь будет нести персональную
                    ответственность за все последствия нарушения настоящего
                    пункта.
                </li>
                <li>
                    SaaS-провайдер вправе отказать Пользователю в Регистрации в
                    случае выявления нарушения Пользователем условий Соглашения.
                    При этом фактическое предоставление доступа в Личный кабинет
                    не препятствует SaaS-провайдеру в реализации указанного
                    права, и ранее предоставленный доступ может быть
                    заблокирован.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Гарантии и заверения Пользователя</strong>
                    </strong>
                </li>
            </ul>
            <p>
                Принимая условия настоящего Соглашения, как это установлено
                соответствующим разделом Соглашения, Пользователь предоставляет
                безусловную гарантию в отношении нижеследующего:&nbsp;
            </p>
            <ol>
                <li>
                    достижение возраста 18 лет либо иного возраста полной право-
                    и дееспособности, согласно законам страны, гражданином,
                    подданным которой он/она является;
                </li>
                <li>
                    наличие всех необходимых полномочий для присоединения к
                    настоящему Соглашению;
                </li>
                <li>
                    отсутствуют любые иные договорные или законные основания,
                    которые мешают присоединению и(или) исполнению Соглашения в
                    полном объеме;
                </li>
                <li>
                    введенные Пользователем Регистрационные данные будут полными
                    и достоверными;
                </li>
                <li>
                    Пользователь является и будет являться единоличным
                    обладателем и правомерным собственником адреса электронной
                    почты, указанной при Регистрации, а при утрате доступа к
                    электронной почте обязуется немедленно сообщить об этом
                    SaaS-провайдеру и действовать согласно полученным
                    инструкциям. Ввод чужого адреса электронной почты не
                    допускается;
                </li>
                <li>
                    понимает, что все действия на Платформе, совершенные с
                    использованием введенного адреса электронной почты при
                    Регистрации, считаются совершенными им самим, и Пользователь
                    несет все риски за соблюдение указанной гарантии. При
                    выбытии из владения адреса электронной почты Пользователь
                    обязуется немедленно известить об этом SaaS-провайдера;
                </li>
                <li>
                    Пользователь осведомлен о необходимости соблюдать должные
                    меры безопасности для того, чтобы не допускать доступа
                    третьих лиц к электронной почте, указанной при Регистрации,
                    а также Авторизационным данным;
                </li>
                <li>
                    Пользователь имеет достаточную информацию об объеме и
                    порядке исполнения обязательств перед налоговыми и иными
                    государственными органами страны, в которой он имеет / будет
                    иметь обязанность в будущем обязанность по уплате налогов,
                    взносов, отчислений и иных обязательных платежей, которые
                    имеются / возникнут в него в связи с использованием
                    Платформы (при наличии), заключения Соглашения, любых прочих
                    договоров с SaaS-провайдером, и гарантирует полную и
                    своевременную их оплату. Пользователь понимает, что
                    SaaS-провайдер ни при каких обстоятельствах не будет
                    выступать его налоговым агентом - Пользователь
                    самостоятельно оплачивает обязательные платежи.
                </li>
            </ol>
            <p>
                SaaS-провайдер вступает в правоотношения с Пользователем с
                учетом вышеуказанных гарантий, которые Сторонами признаются в
                качестве существенных условий. Использование Платформы,
                реализация прав и обязанностей по Соглашению в нарушение хотя бы
                одной гарантии категорически не допускается. При этом
                Пользователь не вправе допускать злоупотребления правом, в том
                числе, намеренно допуская нарушения вышеуказанных гарантий с
                целью признания Соглашения незаключенным.
            </p>
            <p>
                В том случае, если на момент Регистрации Пользователем
                соблюдались все вышеуказанные гарантии, но в процессе исполнения
                Соглашения Пользователем перестала соблюдаться одна или
                несколько вышеуказанных гарантий, он обязан немедленно
                прекратить использовать Платформу и обязан немедленно известить
                об этом SaaS-провайдера. Впоследствии такой Пользователь обязан
                действовать согласно полученным инструкциям от SaaS-провайдера,
                а последний вправе удалить Личный кабинет такого Пользователя и
                немедленно расторгнуть Соглашение.
            </p>
            <ul>
                <li>
                    <strong>
                        <strong>Личный кабинет</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пользователь получает доступ в Личный кабинет путем ввода
                    Авторизационных данных в специально отведенные поля на
                    Платформе. Доступ к вводу Авторизационных данных
                    Пользователь получает после перехода по кнопке
                    &laquo;Войти&raquo; на Платформе.
                </li>
                <li>
                    Личный кабинет состоит из отдельных разделов (функциональных
                    блоков), назначение которых обусловлено их явными
                    пользовательскими функциями. Помимо прочего, в Личном
                    кабинете Пользователь может заказть у SaaS-провайдера
                    оказание услуг.
                </li>
                <li>
                    Любая информация, указанная в Личном кабинете Пользователя,
                    признается Сторонами, как составляющая часть настоящего
                    Соглашения, и является неотъемлемым приложением к нему. В
                    случае противоречия условий настоящего Соглашения и
                    информации, указанной в Личном кабинете, приоритет отдается
                    последней.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Услуги, оказываемые SaaS-провайдером (общие
                            положения)
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Соглашение подразумевает оказание SaaS-провайдером для
                    Пользователя услуг, которые были выбраны и оплачены
                    Пользователем, в порядке и на условиях, указываемых в Личном
                    кабинете, в разделе соответствующего Пакета услуг. Услуги по
                    Пакету услуг оказываются по Технологии SaaS. Перечень
                    Пакетов услуг указывается в настоящем Соглашении, а также
                    непосредственно в Личном кабинете.
                </li>
                <li>
                    Функционал Пакетов услуг предоставляется за плату на
                    временной основе (подписка). Стоимость и период, на который
                    предоставляется функционал Пакетов услуг, указывается в
                    момент оплаты соответствующего Пакета услуг в Личном
                    кабинете. SaaS-провайдером может быть предусмотрен один или
                    несколько тарифов Пакетов услуг.
                </li>
                <li>
                    Использование расширенного функционала Платформы,
                    предоставляемого после оплаты соответствующего Пакета услуг,
                    допускается при условии строго соблюдения законодательства
                    Российской Федерации. В случае выявления SaaS-провайдером
                    случаев нарушения Пользователем законодательства при
                    использовании сервисов, предоставленных в рамках Пакетов
                    услуг, такому Пользователю может быть приостановлен доступ в
                    Личный кабинет, либо к такому сервису, полностью или в
                    части.
                </li>
                <li>
                    Услуги SaaS-провайдера по предоставлению доступа к
                    расширенному функционалу Платформы, в объеме,
                    соответствующем оплаченному Пакету услуг, считаются
                    оказанными SaaS-провайдером и принятыми Пользователем в
                    полном объеме и без замечаний, в том случае, если по
                    истечении 2 (Двух) календарных дней после успешной оплаты
                    Пользователем стоимости Пакета услуг, от Пользователя в
                    адрес SaaS-провайдера не поступила претензия, составленная в
                    указанном в настоящем пункте Соглашения порядке. Претензия
                    должны быть мотивированной, содержать сведения, позволяющие
                    SaaS-провайдеру установить факт совершения Пользователем
                    оплаты за Пакет услуг, и факт нарушения SaaS-провайдером
                    условий Соглашения по предоставлению такого доступа. Стороны
                    согласовали, что акты оказанных услуг / акты по
                    предоставлению доступа / иные акты не составляются, и услуга
                    считается оказанной, как это предусмотрено в настоящем
                    разделе Соглашения.
                </li>
                <li>
                    Пакеты услуг не предназначены для использования в личных,
                    семейных, домашних и иных нужд, не связанных с
                    осуществлением предпринимательской деятельности. Ввиду
                    указанного обстоятельства, Закон РФ от 07.02.1992 N 2300-1
                    "О защите прав потребителей" на правоотношения, возникающие
                    в связи с любым использованием Пакетов услуг на Пользователя
                    и SaaS-провайдера не распространяется.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Пакет услуг: Онлайн-магазин&nbsp;</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пакет услуг &laquo;Онлайн-магазин&raquo; (далее в рамках
                    настоящего раздела Соглашения - <strong>Сервис</strong>)
                    может быть приобретен только Пользователями, которые
                    являются физическими лицами, зарегистрированными в качестве
                    индивидуального предпринимателя, либо являющееся
                    плательщиком налога на профессиональный доход (самозанятый),
                    а также юридическим лицом (действующим в лице
                    уполномоченного представителя). Сервис не предоставляется
                    для физических лиц.
                </li>
                <li>
                    Сервис позволяет Пользователю получить доступ к функционалу
                    Платформы, предоставляющему техническую возможность по
                    созданию, настройке и управлению собственной витриной
                    интернет-магазина (далее - <strong>Интернет-магазин</strong>
                    ). Личный кабинет на Платформе позволяет Пользователю
                    заполнять карточки товара, следить за аналитикой, а также
                    пользоваться прочими функциями, как указанными в Пакете
                    услуг, так и ставшими фактически доступными Пользователю
                    после совершения оплаты Сервиса (за исключением
                    ошибок).&nbsp;
                </li>
                <li>
                    Сервис предоставляет собой техническое решение, и
                    предназначен для обеспечения возможности для покупателей
                    получить за прямую оплату Пользователю, как продавцу (далее
                    - <strong>Продавец</strong>), либо направить ему заявку на
                    приобретение товара / работы / услуги / результата
                    интеллектуальной деятельности (далее совокупно -{' '}
                    <strong>Товар</strong>), с целью последующего заключения
                    договора дистанционной купли-продажи, иного соглашения или
                    коммуникации с Продавцом.&nbsp;
                </li>
                <li>
                    Покупатели, используя Платформу для направления заявки на
                    приобретение Товара, или совершая оплату Товара, вступают в
                    прямые правоотношения с Продавцом, которые регулируются
                    офертой Продавца, ссылка на которую приводится в
                    Интернет-магазине. При подключении Сервиса, SaaS-провайдером
                    могут быть предоставлены шаблоны документов, которые
                    Продавец вправе использовать в своей деятельности, при
                    условии, что Продавец полностью ознакомился с ними,
                    принимает на себя указанные права и обязательства, условия
                    реализации Товаров и прочие условия соответствуют
                    волеизъявлению Продавца - ответственным за исполнение
                    обязательств по оферте является исключительно Продавец.
                    Продавец также вправе загрузить документацию,
                    регламентирующую деятельность Интернет-магазина, в своей
                    редакции.
                </li>
                <li>
                    Функционал приема оплаты за Товар предоставляется платежными
                    агрегаторами, банками, небанковскими финансовыми
                    организациями, иными лицами, с которыми Продавец вступает в
                    прямые правоотношения, без участия SaaS-провайдера. При
                    этом, учитывая необходимость интеграции платежного решения и
                    Сервиса, SaaS-провайдером могут быть предоставлены
                    рекомендации относительно выбора поставщика эквайринга, при
                    несоблюдении которых доступ к функционалу приема оплаты на
                    Сервисе может не быть обеспечен.
                </li>
                <li>
                    SaaS-провайдер не участвует в расчетах, не является
                    участником заключаемых сделок, и не несет обязательств по
                    таким сделкам в любом объеме. SaaS-провайдером принимаются
                    претензии исключительно по работоспособности Платформы, при
                    наличии его вины.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Пакет услуг: Управление сообществами Telegram
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пакет услуг &laquo;Управление сообществами Telegram&raquo;
                    (далее в рамках настоящего раздела Соглашения -{' '}
                    <strong>Сервис</strong>) позволяет Пользователю получить
                    доступ к функционалу Платформы, предоставляющему техническую
                    возможность подключить свои сообщества в Telegram к
                    Платформе, чтобы получить полезные инструменты для
                    модерации, аналитики и монетизации.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Пакет услуг: Страница автора</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пакет услуг &laquo;Страница автора&raquo; (далее в рамках
                    настоящего раздела Соглашения - <strong>Сервис</strong>)
                    позволяет Пользователю получить доступ к функционалу
                    Платформы, предоставляющему техническую возможность создать
                    персональную страницу для Пользователей на Платформе, на
                    которой Пользователи могут размещать собственный Контент и
                    устанавливать правила доступа к такому Контенту для
                    посетителей Платформы и других Пользователей. Правила
                    размещения такого пользовательского Контента устанавливаются
                    настоящим разделом Соглашения.&nbsp;
                </li>
                <li>
                    Пользователь, разместивший Контент Пользователя на Сервисе,
                    в настоящем Соглашении далее - <strong>Автор</strong>, а
                    весь Контент, созданный / загруженный Автором с
                    использованием Сервиса - <strong>Контент Автора</strong>;
                    Лицо (как зарегистрированные, так и не зарегистрированное в
                    качестве Пользователя Платформы), потребляющее
                    (просматривающее / комментирующее и пр.) Контент Автора на
                    Сервисе - <strong>Читатель</strong>, а Контент, созданный /
                    загруженный Читателем на Странице Автора (например, текст
                    или изображения в блоке &laquo;комментарии&raquo; к записям
                    Автора и т.п.) - <strong>Контент Читателя</strong>.
                </li>
                <li>
                    За исключением случаев прямого указания в Соглашении, (а)
                    как лицо, предоставляющее Сервис, SaaS-провайдер не владеет,
                    не создает, не продает, не перепродает, не предоставляет, не
                    контролирует, не управляет, не предлагает, не предоставляет
                    и не поставляет какой-либо Контент Автора и Контент
                    Читателя, и не участвует в каких-либо отношениях между
                    Автором и Читателем, (b) Авторы самостоятельно несут
                    ответственность за их Контент Автора, а Читатели
                    самостоятельно несут ответственность за их Контент Читателя,
                    (c) SaaS-провайдер не является и не становится стороной или
                    другим участником любых договорных отношений между Автором и
                    Читателем, (d) SaaS-провайдер не выступает в качестве агента
                    какого-либо рода для Автора или Читателя.
                </li>
                <li>
                    В то время как SaaS-провайдер может помочь в разрешении
                    споров, SaaS-провайдер не контролирует и не гарантирует (i)
                    наличие, качество, безопасность, пригодность или законность
                    любого Контента Автора или Контента Читателя (ii)
                    эффективность или поведение любого Автора или Читателя или
                    третьего лица. SaaS-провайдер не поддерживает какого-либо
                    Автора или Контент Автора или Читателя или Контент Читателя.
                    Все Пользователи всегда должны проявлять должную
                    осмотрительность и заботу при принятии решения о том,
                    следует ли общаться или вступать в какие-либо отношения с
                    Авторами или Читателями.
                </li>
                <li>
                    Контент Автора и Контент Читателя совместно в настоящем
                    пункте (включая подпункты) именуется как{' '}
                    <strong>Контент, </strong>а Автор и Читатель -{' '}
                    <strong>Пользователь</strong>. Если SaaS-провайдер позволяет
                    Пользователю размещать Контент, то Пользователь несет
                    единоличную ответственность за размещаемый Контент и
                    последствия его размещения. Загружая / публикуя /
                    предоставляя Контент, Пользователь заверяет и гарантирует,
                    что: (1) Пользователь является создателем и обладателем
                    Контента или иным образом обладает достаточными правами и
                    полномочиями на предоставление прав в соответствии с
                    настоящим Соглашением; (2) Контент всегда соответствует
                    настоящему Соглашению; и (3) Контент Пользователя не будет
                    (a) нарушать какой-либо применимый закон, правило или
                    нормативный акт или не будет (b) нарушать или незаконно
                    присваивать какое-либо право третьего лица, включая любое
                    авторское право, товарный знак, патент, коммерческую тайну,
                    моральное право, право на конфиденциальность, право на
                    гласность или иное право интеллектуальной собственности или
                    право собственности, или (c) оскорблять, оговаривать или
                    дискредитировать любое другое лицо, или (d) пропагандировать
                    насилие, использование огнестрельного оружия или незаконные
                    предметы или виды деятельности, или (e) иметь
                    клеветнический, дискредитирующий, вводящий в заблуждение,
                    порнографический или сексуально откровенный характер; (4)
                    Контент не содержит какие-либо вирусы, рекламное программное
                    обеспечение, шпионское программное обеспечение,
                    самораспространяющиеся программы или иной вредоносный код,
                    или (5) если Пользователь не получил предварительного
                    письменного разрешения, Контент не содержит предварительного
                    выпуска или закрытой бета-версии программного обеспечения,
                    или игрового контента, или конфиденциальной информации
                    SaaS-провайдера или третьих лиц. SaaS-провайдер оставляет за
                    собой все права и средства правовой защиты в отношении
                    Пользователей, которые нарушают данные заверения и гарантии.
                </li>
            </ol>
            <ol>
                <li>
                    Любой Контент может полностью или частично защищаться
                    авторским правом, товарным знаком и / или другими законами
                    соответствующего государства. Пользователь признает и
                    соглашается с тем, что любой Контент, включая все связанные
                    с ним права интеллектуальной собственности, является
                    исключительной собственностью соответствующих владельцев.
                    Пользователь обязуется не удалять, не изменять или не
                    затенять никаких уведомлений об авторских правах, товарных
                    знаках, знаках обслуживания или других правах собственности,
                    включенных в Контент SaaS-провайдера или любой Контент или
                    сопровождающих их. Пользователь не будет использовать,
                    копировать, адаптировать, модифицировать, готовить
                    производные работы, распространять, лицензировать,
                    продавать, передавать, публично показывать, публично
                    выполнять, передавать, транслировать или иным образом
                    использовать Контент SaaS-провайдера или Контент, за
                    исключением случаев, когда Пользователь является законным
                    владельцем определенного Контента или как это прямо
                    разрешено в настоящем Соглашении. Никакие лицензии или права
                    не предоставляются Пользователю по смыслу или иным образом в
                    отношении любых прав интеллектуальной собственности,
                    принадлежащих или контролируемых SaaS-провайдером или ее
                    лицензиарами, за исключением лицензий и прав, явно
                    предоставленных в настоящем Соглашении.
                </li>
                <li>
                    Если Пользователь распространяет или предоставляет любой
                    Контент через Сервис, он тем самым предоставляет
                    SaaS-провайдеру для отображения Контента на Платформе и
                    обеспечения функционирования Платформы действующее на
                    территории всего мира, неисключительное, безвозмездное,
                    бессрочное, передаваемое и полностью сублицензируемое право
                    на использование, размещение, конвертирование для потоковой
                    передачи, воспроизведение, изменение, адаптацию,
                    распространение, публичный показ, доведение до всеобщего
                    сведения, отображение и иные способы использования Контента.
                    Этот пункт должен оставаться в силе после прекращения
                    действия Соглашения по любой причине, за исключением
                    случаев, когда это запрещено применимым законодательством.
                </li>
                <li>
                    SaaS-провайдер не принимает на себя какой-либо
                    ответственности за любой Контент, размещаемый, хранящийся
                    или загружаемый Пользователем или любым третьим лицом, либо
                    за любые убытки или ущерб в связи с ним, а также
                    SaaS-провайдер не несет ответственности за какие-либо
                    ошибки, клеветнические заявления, оскорбления, диффамацию,
                    упущения, ложные заключения, ругательства, порнографию или
                    профанацию, которые могут быть обнаружены на Пользователем
                    (третьим лицом). Использование Платформы Пользователем /
                    Посетителем осуществляется на страх и риск. Контроль
                    соблюдения правил в отношении Контента, изложенных в
                    настоящем Соглашении, осуществляется исключительно по
                    усмотрению SaaS-провайдера, и не приведение в исполнение
                    таких правил и норм в некоторых случаях не составляет отказа
                    от права SaaS-провайдера на приведение в исполнение таких
                    правил и норм в других случаях. Более того, данные правила и
                    нормы не создают какое-либо частное право на иск со стороны
                    любого третьего лица или не предусматривают разумных
                    ожиданий того, что Платформа не будет содержать какого-либо
                    Контента, который запрещен такими правилами и нормами. В
                    качестве поставщика интерактивных услуг SaaS-провайдер не
                    несет ответственности за какие-либо заявления, заверения или
                    Контент, предоставляемый Пользователями на Сервисе или в
                    рамках иного интерактивного элемента. SaaS-провайдер не
                    утверждает какой-либо Контент или заключение, рекомендацию
                    или консультацию, которые представлены в нем, и
                    SaaS-провайдер прямо отказывается от любой ответственности в
                    связи с Контентом. Невзирая на то, что SaaS-провайдер не
                    несет каких-либо обязательств по воспроизведению или
                    редактированию любого Контента, размещенного с
                    использованием любого интерактивного элемента,
                    SaaS-провайдер оставляет за собой исключительное право на
                    удаление, проверку или редактирование Контента, размещенного
                    или хранящегося на Платформе, в любое время и по любой
                    причине, без направления уведомления, а также Пользователь
                    несет исключительную ответственность за создание резервных
                    копий и замену любого Контента, который он размещает или
                    хранит на Платформе, исключительно за свой счет.
                    Использование интерактивных элементов или иных частей
                    Платформы (включая Сервис) в нарушение вышеизложенного
                    приводит к нарушению Соглашения и может привести, среди
                    прочего, к прекращению действия или приостановлению действия
                    прав на использование интерактивных элементов и/или
                    Платформы.
                </li>
                <li>
                    В любое время SaaS-провайдер оставляет за собой право на
                    удаление или блокирование любого Контента в случае нарушения
                    или предполагаемого нарушения Соглашения.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Правила использования Платформе</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    В настоящем разделе устанавливаются правила использования
                    Платформы, а также любых сервисов Платформы, предоставляемых
                    в результате приобретения Пакета услуг. Все ссылки на
                    Платформу означают как использование Платформы, так
                    использование любых сервисов Платформы, предоставляемых в
                    результате приобретения / использования Пакетов услуг.
                </li>
                <li>
                    Пользователь / Посетитель единолично несет ответственность
                    за соответствие любым и всем законодательным актам, правилам
                    и положениям, которые могут применяться к использованию
                    Платформы. В связи с вашим использованием Платформы
                    Пользователь / Посетитель не должен сам и не должен
                    позволять другим:
                </li>
            </ol>
            <ol>
                <li>
                    использовать Платформу в любых целях, кроме указанных в
                    Соглашении;
                </li>
                <li>
                    арендовать, сдавать в аренду, заимствовать, продавать,
                    перепродавать, передавать на условиях сублицензии,
                    распространять или иным образом передавать лицензии,
                    предоставляемые в соответствии с Соглашением;
                </li>
                <li>
                    размещать, загружать или распространять любой
                    дискредитирующий, клеветнический или недостоверный Контент
                    или Товар;
                </li>
                <li>
                    использовать Платформу для предоставления или побуждения к
                    предоставлению сексуальных услуг;
                </li>
                <li>
                    использовать Платформу для обеспечения доступа к
                    порнографическим материалам, такие как предоставление
                    доступа к веб-сайту или организацию доступа к
                    порнографической информации как на Платформу, так и на любой
                    другой платформе. К порнографической информации относятся
                    произведения, содержащие изображение реальных людей,
                    участвующих в половых актах, например, мастурбирующих или
                    записывающих половой акт на камеру;
                </li>
                <li>
                    использовать Платформу для финансирования деятельности,
                    связанной с эскорт-услугами;
                </li>
                <li>
                    использовать Платформу в целях реализации Товаров / Контента
                    (включая безвозмездный Контент), относящийся к политике,
                    включая темы, связанные со специальной военной операции
                    (СВО);
                </li>
                <li>
                    предлагать пользователям эротические сеансы по веб-камере;
                </li>
                <li>
                    выдавать себя за какое-либо физическое или юридическое лицо,
                    обманным путем заявлять об установлении связи с любым
                    физическим или юридическим лицом или получать доступ к
                    учетным записям Платформы других лиц без разрешения,
                    подделывать цифровые подписи иных лиц, предоставлять
                    недостоверные сведения в отношении источника, принадлежности
                    или содержания информации, передаваемой через Платформу, или
                    осуществлять прочую подобную мошенническую деятельность;
                </li>
                <li>
                    удалять уведомления об авторских правах или иных правах
                    собственности в отношении Контента SaaS-провайдера или иного
                    Контента / Товара;
                </li>
                <li>
                    предоставлять незатребованные предложения, рекламные
                    объявления, предложения или отправлять спам другим
                    пользователям Платформы, включая в том числе незатребованные
                    рекламные объявления, рекламные материалы или иные
                    маркетинговые материалы, массовые коммерческие объявления,
                    информационные сообщения, запросы на денежные пожертвования,
                    заявки на подписи или иные вышеуказанные материалы,
                    связанные с рекламными мероприятиями (такими как лотереи и
                    конкурсы), а также осуществлять иную подобную деятельность;
                </li>
                <li>
                    осуществлять сбор адресов электронной почты или иной
                    контактной информации других пользователей Платформы с целью
                    отправки спама или иных коммерческих сообщений;
                </li>
                <li>
                    использовать Платформу с любой незаконной целью или в
                    нарушение любого местного, регионального, национального или
                    международного закона, включая в том числе законодательство,
                    регулирующее права интеллектуальной собственности и иные
                    права собственности, а также защиту и конфиденциальность
                    данных;
                </li>
                <li>
                    дискредитировать, преследовать, оскорблять, угрожать или
                    обманывать Пользователей / Посетителей Платформы, собирать
                    или пытаться собирать личную информацию о пользователях или
                    третьих лицах без их согласия;
                </li>
                <li>
                    удалять, обходить, блокировать, нарушать или иным образом
                    создавать помехи для элементов, связанных с обеспечением
                    безопасности Платформы или Контента / Товара, элементов,
                    которые препятствуют или ограничивают использование или
                    копирование любого контента, доступного через Платформу, или
                    элементов, которые приводят в исполнение ограничения по
                    использованию Платформы или Контента;
                </li>
                <li>
                    переконструировать, декомпилировать, дизассемблировать или
                    иным образом пытаться открыть исходный код Платформы или
                    любой его части;
                </li>
                <li>
                    изменять, адаптировать, преобразовывать или создавать
                    производные работы на основе Платформы или любой его части;
                </li>
                <li>
                    намеренно создавать помехи или нарушать функционирование
                    Платформы или его использование любым пользователем любыми
                    способами, в том числе путем загрузки или иного
                    распространения вирусов, рекламного программного
                    обеспечения, шпионского программного обеспечения,
                    самораспространяющихся программ или иного вредоносного кода;
                </li>
                <li>
                    пересылать электронные письма с почтовых серверов третьего
                    лица без разрешения такого третьего лица;
                </li>
                <li>
                    использовать роботы, программы глобального поиска, программы
                    поиска контента, поисковые боты или иные автоматизированные
                    способы для получения доступа к Платформе с любой целью или
                    обходить любые меры, которые SaaS-провайдер может
                    использовать для предотвращения или ограничения доступа к
                    Платформе;
                </li>
                <li>
                    управлять идентификаторами с целью изменения происхождения
                    Контента, передаваемого через Платформу;
                </li>
                <li>
                    препятствовать или нарушать работу Платформы или серверов,
                    или сетей, связанных с Платформой или нарушать любые
                    требования, процедуры, политики или нормы работы сетей,
                    относящихся к Платформе;
                </li>
                <li>
                    использовать Платформу любым способом, который может
                    создавать помехи, нарушать, негативно влиять или запрещать
                    другим пользователям использовать в полном объеме Платформу,
                    или который может причинять ущерб, блокировать, создавать
                    сложности или нарушать функционирование Платформы любым
                    способом;
                </li>
                <li>
                    использовать или пытаться использовать учетную запись
                    другого пользователя без разрешения такого пользователя и
                    SaaS-провайдера;
                </li>
                <li>
                    пытаться обойти любые технические средства, осуществляющие
                    выборку Контента, которую SaaS-провайдер использует, или
                    пытаться получить доступ к любому сервису или элементу
                    Платформы, к которым изначально нет доступа;
                </li>
                <li>
                    собирать, передавать, отправлять, показывать, публично
                    показывать или каким-либо образом раскрывать любую
                    информацию о любом Пользователе, которая была собрана или
                    получена при использовании Платформы, включая адреса
                    электронной почты; или пытаться указать любым способом, что
                    вы связаны с нами какими-либо отношениями, или что
                    SaaS-провайдер предоставила подтверждение в отношении
                    Пользователя / Посетителя или любых продуктов или услуг с
                    любой целью.
                </li>
            </ol>
            <ol>
                <li>
                    Кроме того, ИСПОЛЬЗУЯ ПЛАТФОРМУ, ПОЛЬЗОВАТЕЛЬ / ПОСЕТИТЕЛЬ
                    СОГЛАШАЕТСЯ НЕ размещать, не загружать, не передавать, не
                    распространять, не хранить, не создавать или иным образом не
                    публиковать через Платформу или использовать в связи с
                    Платформой следующие материалы:
                </li>
            </ol>
            <ol>
                <li>
                    Товары / Контент, который будет составлять, способствовать
                    или представлять инструкции и указания в отношении уголовных
                    преступлений, нарушать права любой стороны, или который
                    будет иным образом обосновывать ответственность или нарушать
                    любой местный, региональный, национальный или международный
                    закон или нормативный акт;
                </li>
                <li>
                    Товары / Контент, который может нарушать любые патенты,
                    товарные знаки, коммерческую тайну, авторские права или иные
                    права интеллектуальной собственности или права собственности
                    любой стороны. Размещая любой Контент, вы представляете
                    заверения и гарантии того, что вы обладаете законным правом
                    на распространение и воспроизведение такого Контента;
                </li>
                <li>
                    Товары / Контент, который является незаконным,
                    клеветническим, дискредитирующим, оскорбительным,
                    порнографическим, непристойным, неприличным, двусмысленным,
                    преследующим, угрожающим, вторгающимся в права
                    конфиденциальности или гласности, оскорбительным,
                    провокационным, мошенническим или иным образом
                    предосудительным;
                </li>
                <li>
                    Товары / Контент, который позволяет выдавать себя в качестве
                    иного физического или юридического лица, а также иным
                    образом давать неверное представление о вашей связи с
                    физическим или юридическим лицом;
                </li>
                <li>
                    Товары / Контент, на котором изображены реальные люди,
                    участвующие в сексуальных действиях, таких как мастурбация
                    или половой акт, на камеру;
                </li>
                <li>
                    Обнаженные изображения любого человека в возрасте до 18 лет,
                    включая самого Пользователя / Посетителя;
                </li>
                <li>
                    Товары / Контент, который создает или содержит
                    иллюстрированные, анимационные или другие изображения
                    зоофилии, изнасилования и эксплуатации детей (например,
                    сексуализированное изображение несовершеннолетних),
                    второстепенных сексуальных фетишных творений, таких как
                    инцест, некрофилия или прочие фетишные творения, которые
                    трудно отличить от без сексуальных действий, совершаемых по
                    обоюдному согласию;
                </li>
                <li>
                    конфиденциальная информация любого третьего лица, включая в
                    том числе адреса, номера телефонов, адреса электронной
                    почты, номера социального обеспечения и номера кредитных
                    карт;
                </li>
                <li>
                    вирусы, искаженные данные или иные вредоносные или
                    разрушающие файлы и контент, которые, по заключению
                    SaaS-провайдера, являются неприемлемыми, или которые
                    ограничивают или запрещают любому другому лицу использовать
                    интерактивные элементы или Платформу, или которые могут
                    причинять вред или налагать ответственность на
                    SaaS-провайдера или Пользователей / Посетителей.
                </li>
            </ol>
            <ol>
                <li>
                    Если Пользователь / Посетитель считает, что любой человек, с
                    которым он взаимодействует, будь то онлайн или лично,
                    действует или действует ненадлежащим образом, включая, но не
                    ограничиваясь, любого, кто (i) совершает оскорбительное,
                    насильственное или сексуально неприемлемое поведение, (ii)
                    подозреваете, что он ворует, или (iii) совершает любые
                    другие вызывающие подозрение действия, Пользователь /
                    Посетитель должен немедленно сообщить об этом лице в
                    соответствующие органы, а затем SaaS-провайдеру, при
                    условии, что такое обращение не обяжет SaaS-провайдера
                    принимать какие-либо меры, помимо тех, которые требуются по
                    закону (если они есть), или не заставляют SaaS-провайдера
                    нести никакой ответственности.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Права и обязанности сторон</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>SaaS-провайдер обязуется:</strong>
                </li>
            </ol>
            <ol>
                <li>
                    Предоставить Пользователю доступ к Платформе, Личному
                    кабинету, а также дополнительным возможностям Платформы, в
                    объеме, соответствующем оплаченному / предоставленному
                    Пакету услуг.
                </li>
                <li>
                    Самостоятельно и по своему усмотрению обновлять текущую
                    версию программных компонентов Платформы во время действия
                    Соглашения, что может повлечь за собой изменение функционала
                    Платформы (в том числе, добавление новых функций, или
                    отключение уже существующих).
                </li>
                <li>
                    Обеспечить доступность Платформы в круглосуточном режиме с
                    возможными технологическими перерывами совокупной
                    продолжительностью не более 1 (одного) часа в сутки, за
                    исключением времени проведения технических работ.
                </li>
                <li>
                    Предоставлять в Личном кабинете Пользователя информацию о
                    содержании Пакетов услуг, порядка их оплаты и подключения, а
                    также иную информацию, состав и содержание которой
                    определяется SaaS-провайдером самостоятельно.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>SaaS-провайдер имеет право:</strong>
                </li>
            </ol>
            <ol>
                <li>
                    Ограничить доступный Пользователю функционал Платформы при
                    отсутствии поступлений(я) от Пользователя оплаты Пакета
                    услуг за соответствующий период.
                </li>
                <li>
                    Изменить стоимость или содержание Пакета услуг в
                    одностороннем порядке, а также изменять условия
                    предоставления доступа к Пакету услуг, уведомив об этом
                    Пользователя путем публикации информации Платформе и/или в
                    Личном кабинете. При этом такие изменения не могут быть
                    внесены в соответствии с настоящим пунктом в уже оплаченный
                    Пакет услуг.
                </li>
                <li>
                    Приостановить / прекратить в одностороннем порядке доступ к
                    Платформе или любому сервису, предоставленному на условиях
                    настоящего Соглашения, в следующих случаях:
                </li>
            </ol>
            <ol>
                <li>
                    истек срок, на который был предоставлен сервис; в
                    соответствии с условиями действующего тарифа;
                </li>
                <li>
                    при использовании Платформы и(или) сервиса было допущено
                    нарушение условий настоящего Соглашения;
                </li>
                <li>
                    в отношении использования Платформы и(или) сервиса
                    заинтересованными лицами, включая органы власти, была
                    направлена жалоба, по результатам рассмотрения которой
                    SaaS-провайдером было установлено нарушение законодательства
                    или условий настоящего Соглашения;
                </li>
                <li>
                    в иных случаях, предусмотренных Соглашением или действующим
                    законодательством.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Пользователь обязуется:</strong>
                </li>
            </ol>
            <ol>
                <li>
                    При использовании Пакетов услуг - своевременно и в полном
                    объеме оплачивать соответствующий тариф Пакета услуг, как
                    это указано в Соглашении.
                </li>
                <li>
                    Строго придерживаться и не нарушать условий настоящего
                    Соглашения, в том числе, использовать Платформу только в
                    порядке и на условиях, указанных в Соглашении.
                </li>
                <li>
                    В том случае, если указанный в настоящем Соглашении сервис
                    предоставляется Пользователю путем интеграции виджета
                    (функционал, позволяющий воспроизводить визуальную часть
                    интерфейса Сервиса), то такой виджет может быть размещен
                    исключительно на находящемся в его ведении /
                    администрировании интернет-ресурсе / персональной странице
                    веб-ресурса, в порядке и на условиях, установленных
                    настоящим Соглашением, а также правилами соответствующего
                    веб-ресурса.
                </li>
            </ol>
            <ol>
                <li>
                    <strong>Пользователь имеет право:</strong>
                </li>
            </ol>
            <ol>
                <li>
                    Выбрать и оплатить один из предложенных тарифов Пакетов
                    услуг для использования Платформы.
                </li>
                <li>
                    Использовать предоставленные ему права доступа к Платформе в
                    соответствии с ее целевым назначением.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Порядок изменения Соглашения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    SaaS-провайдер вправе изменить содержание и условия
                    Соглашения, приложений к нему, в одностороннем порядке и в
                    любое время. Такие изменения вступают в силу с момента их
                    опубликования на Платформе. Последняя редакция Соглашения
                    всегда доступна для просмотра, печати и загрузки по
                    URL-ссылке, указанной в Соглашении.&nbsp;
                </li>
                <li>
                    SaaS-провайдер публикует измененную версию Соглашения сразу
                    после внесения изменений. Использование Платформы после
                    внесения изменений в Соглашение означает согласие
                    Пользователя со всеми внесенными изменениями.&nbsp;
                </li>
                <li>
                    SaaS-провайдер уведомляет Пользователя о внесении изменений
                    в Соглашение путем отправления уведомления на адрес
                    электронной почты, указанный при Регистрации и(или) путем
                    публикации измененной версии Соглашения на Платформе.&nbsp;
                </li>
                <li>
                    Пользователь обязуется на свой страх и риск регулярно
                    проверять наличие изменений в Соглашении (но не реже одного
                    раза в 14 дней), и несет все риски нарушения измененных
                    условий Соглашения. Использование Платформы после внесения
                    изменений в Соглашение означает согласие Пользователя со
                    всеми внесенными изменениями.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Срок действия Соглашения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Соглашение считается заключенным между SaaS-провайдером и
                    Пользователем с момента успешного прохождения Пользователем
                    Регистрации на Платформе (выражения согласия с условиями
                    Соглашения), и действует до удаления Личного кабинета, а в
                    части уже принятых обязательств - до полного исполнения
                    Сторонами таких обязательств.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Интеллектуальная собственность</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пользователь признает и соглашается, что Платформа и любой
                    Контент, который SaaS-провайдер делает доступным на или
                    через Платформу, включая любые товарные знаки, логотипы или
                    любые названия сервисов (
                    <strong>Контент SaaS-провайдера</strong>), и все связанные
                    права интеллектуальной собственности, являются
                    исключительной собственной SaaS-провайдера и/или его
                    лицензиаров или поставщиков, и не могут быть скопированы,
                    имитированы или использованы, в целом или в части, без
                    предварительного письменного согласия SaaS-провайдера. Любое
                    разрешенное использование такого Контента SaaS-провайдера
                    должно быть после получения письменного разрешения от
                    SaaS-провайдера. Пользователь не вправе использовать любые
                    метатэги или другой скрытый текст, использующий
                    &laquo;Spodial&raquo;, &laquo;Платформа Spodial&raquo; или
                    любое другое название, товарные знаки или название продукта
                    или сервиса SaaS-провайдера без предварительного разрешения
                    SaaS-провайдера. Отсылка на любые продукты, услуги, процессы
                    или другую информацию, через указание коммерческого
                    обозначения, товарного знака, производителя, поставщика или
                    другим образом, не означает одобрение, спонсорство или
                    рекомендацию SaaS-провайдера.
                </li>
                <li>
                    Платформа в целом, а также инструменты и сервисы Платформы,
                    в том числе, но не ограничиваясь: программы для ЭВМ, базы
                    данных, коды, лежащие в их основе ноу-хау, алгоритмы,
                    элементы дизайна, шрифты, логотипы, а также текстовые,
                    графические и иные материалы, являются объектами
                    интеллектуальной собственности, охраняемыми в соответствии с
                    действующим законодательством Российской Федерации и
                    международным правом, любое использование которых
                    допускается только на основании разрешения правообладателя.
                </li>
                <li>
                    Незаконное использование указанных объектов интеллектуальной
                    собственности влечет гражданскую, административную и
                    уголовную ответственность.
                </li>
                <li>
                    Пользователь не вправе осуществлять в отношении Платформы,
                    инструментов и сервисов Платформы и их составляющих
                    воспроизведение (тиражирование и иное копирование),
                    распространение, модификацию, переформатирование и иную
                    переработку. Любые составляющие Платформы и сервисов
                    Платформы запрещается использовать в составе сайтов,
                    программных приложений и продуктов, поисковых систем, других
                    произведений и объектов смежных прав, копировать или иным
                    способом использовать с целью извлечения материальной
                    выгоды, или без таковой.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Право на Контент</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Правообладателем Контента может являться SaaS-провайдер
                    (если такой Контент размещается на Платформе
                    SaaS-провайдером), либо Пользователь (если такой Контент
                    размещается на Платформе самим Пользователем).
                </li>
                <li>
                    Пользователям запрещено использовать Контент способом,
                    нарушающим законодательство Российской Федерации.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>
                            Ограничения ответственности SaaS-провайдера
                        </strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Продолжая использовать Платформу, Пользователь подтверждает,
                    что не пребывает в состоянии заблуждения (существенного или
                    несущественного) относительно ее функционала, условий
                    Соглашения и своих собственных действий. Пользователь
                    принимает, что в соответствии с Соглашением Платформа для
                    использования в указанных Соглашением пределах
                    предоставляется <em>&laquo;КАК ЕСТЬ&raquo;</em>, включая
                    любые обновления. SaaS-провайдер не предоставляет никаких
                    гарантий (прямых или подразумеваемых законодательством и/или
                    обычаями делового оборота) относительно чего бы то ни было,
                    в том числе, интегрируемости, удовлетворительного качества и
                    годности Платформы к использованию для выполнения конкретных
                    задач. SaaS-провайдер также не гарантирует, что Платформа не
                    содержит ошибок, будет удовлетворять требованиям
                    Пользователя, или что Платформа будет функционировать
                    должным образом при использовании совместно с любым другим
                    программным или аппаратным обеспечением. SaaS-провайдер не
                    гарантирует и не может гарантировать результаты, которые
                    Пользователь может получить путем использования Платформы.
                    Все риски, связанные с качеством работы и
                    производительностью Платформы, возлагаются на
                    Пользователя.&nbsp;
                </li>
                <li>
                    SaaS-провайдер не несет ответственности за прямые, косвенные
                    убытки или потери любого рода (включая, но не ограничиваясь,
                    потерю дохода или прибыли, а также использование данных),
                    возникшие при использовании Платформы
                </li>
                <li>
                    SaaS-провайдер не несет ответственности за качество доступа
                    Пользователя к сети Интернет и качеством
                    Интернет&ndash;соединения, а также в связи с
                    наличием/отсутствием у Пользователя оборудования и
                    программного обеспечения для доступа в Интернет, его
                    качеством.
                </li>
                <li>
                    SaaS-провайдер не несет ответственности за сбои или иные
                    проблемы в работе телефонных сетей или служб, компьютерных
                    систем, серверов или провайдеров, компьютерного или
                    телефонного оборудования, программного обеспечения, а также
                    за сбои в работе электронной почты или скриптов (программ).
                </li>
                <li>
                    SaaS-провайдер не несет перед Пользователем никакой
                    ответственности за убытки, вызванные возможными ошибками и
                    опечатками на Платформе. Вместе с тем SaaS-провайдер
                    обязуется принимать разумные меры для недопущения и
                    исправления таких ошибок и опечаток.&nbsp;
                </li>
                <li>
                    SaaS-провайдер не несет ответственности за недобросовестные
                    действия третьих лиц в отношении Платформы, включая, но не
                    ограничиваясь, недобросовестными действиями по получению
                    доступа к серверам Платформы, внедрению вирусов, иных
                    вредоносных программ, и совершению прочих недобросовестных
                    действий в отношении SaaS-провайдера и третьих лиц. При этом
                    SaaS-провайдер обязуется ответственно подходить к алгоритмам
                    защиты и предпринимать разумные меры для недопущения
                    подобных ситуаций.&nbsp;
                </li>
                <li>
                    SaaS-провайдер не несет ответственности за любой сбой или
                    задержку в исполнении своих обязательств, если задержка
                    вызвана обстоятельствами, находящимися вне разумного
                    контроля SaaS-провайдера, включая, но не ограничиваясь,
                    следующими обстоятельствами: <em>(1)</em> пожар,
                    землетрясение, шторм, наводнение, ураган, ненастная погода
                    или другие стихийные бедствия, военные действия, терроризм,
                    взрыв, саботаж, промышленные аварии или забастовки;{' '}
                    <em>(2)</em> атаки типа &laquo;отказ в обслуживании&raquo;
                    (DoS-атаки), сбой в электросвязи, аппаратный сбой или сбой
                    программного обеспечения, предоставляемого третьей стороной,
                    для функционирования в соответствии с его спецификациями;{' '}
                    <em>(3)</em> необычно высокий спрос на Платформу, приводящий
                    к неспособности программного или аппаратного обеспечения
                    функционировать должным образом; <em>(4)</em> неспособность
                    какой-либо третьей стороны (включая, помимо прочего, любой
                    банк или другое финансовое учреждение) выполнить какие-либо
                    обязательства перед SaaS-провайдером; <em>(5)</em> любые
                    другие обстоятельства или события, находящиеся за пределами
                    разумного контроля SaaS-провайдера, включая задержку
                    исполнения обязательств третьими лицами (например, банками,
                    иными финансовыми организациями).
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Ответственность Пользователя</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пользователь не вправе использовать Платформу в нарушение
                    Соглашения.
                </li>
                <li>
                    Пользователь не вправе распространять, продавать,
                    переводить, модифицировать, осуществлять обратный инжиниринг
                    или выполнять обратную компиляцию или декомпиляцию,
                    дизассемблирование или создание производных работ от
                    Платформы.
                </li>
                <li>
                    Пользователь не вправе осуществлять попыток получить
                    несанкционированный доступ к другим компьютерным системам
                    или сетям, к которым подключена Платформа.
                </li>
                <li>
                    Пользователь не вправе вмешиваться и предпринимать действия,
                    которые приведут к вмешательству или нарушению работы
                    Платформы, серверов, на которых размещена Платформа.
                </li>
                <li>
                    Пользователь не вправе использовать любые технологии
                    фрейминга, используя материалы Платформы, любые
                    мета&ndash;теги, рекламную модель &laquo;плати за
                    клик&raquo; (pay&ndash;per&ndash;click), любой
                    &laquo;скрытый текст&raquo;, deeplink&ndash;ссылки, ссылаясь
                    на Платформу.
                </li>
                <li>
                    Пользователь не вправе обходить любое шифрование или другие
                    средства безопасности, используемые на Платформе.
                </li>
                <li>
                    Пользователь не вправе использовать технологии &laquo;дата
                    майнинг&raquo; (data mining), &laquo;скрапинг&raquo;
                    (scraping) или аналогичные инструменты сбора и извлечения
                    данных с Платформы.
                </li>
                <li>
                    Пользователь не вправе продавать, сдавать в аренду,
                    передавать, распространять, использовать в качестве оказания
                    услуг или иным образом передавать третьим лицам доступ к
                    использованию Платформы, который предоставлен Пользователю
                    по условиям настоящего Соглашения.
                </li>
                <li>
                    Пользователь не вправе вносить какие-либо изменения в
                    исходный код Платформы и его составляющие и(или)
                    осуществлять действия, которые могут отрицательно повлиять
                    на корректную работу Платформы, а также репутацию и доброе
                    имя SaaS-провайдера, его руководителей, сотрудников,
                    клиентов и других пользователей.
                </li>
                <li>
                    Пользователь не вправе передавать доступ к своему Личному
                    кабинету другим пользователям или третьим лицам, а также
                    иным образом предоставлять Курс для прохождения /
                    ознакомления.
                </li>
                <li>
                    Пользователь не вправе совершать относительно Платформы
                    другие действия, нарушающие нормы применимого права, а также
                    международные нормы законодательства об авторском праве и
                    использовании программных средств.
                </li>
                <li>
                    Пользователь обязуется возместить SaaS-провайдеру убытки,
                    причиненные выплатами, взысканными с SaaS-провайдера
                    третьими лицами, в том числе покупателями, а также
                    административные штрафы со стороны контрольных и надзорных
                    органов, полученными SaaS-провайдером из-за нарушений
                    Пользователем положений Соглашения и/или законодательства.
                </li>
                <li>
                    Пользователь / Посетитель соглашается возмещать, защищать и
                    освобождать SaaS-провайдера от ответственности в отношении
                    любых претензий, убытков, ущерба, обязательств, включая
                    расходы на юридическое сопровождение, которые возникают в
                    результате нарушений настоящего Соглашения Пользователем /
                    Посетителем (например, нарушение Пользователем / Посетителем
                    прав любого третьего лица, нарушение настоящего Соглашения
                    или любых других применимых соглашений в отношении
                    использования Платформы) или нарушения заверений и гарантий,
                    сделанных Пользователем / Посетителем согласно настоящему
                    документу. SaaS-провайдер оставляет за собой право на
                    осуществление, за счет Пользователя / Посетителя, в
                    исключительном порядке защиты и контроля по любой претензии,
                    по которой требуется возмещение для SaaS-провайдера со
                    стороны Пользователя / Посетителя, и Пользователь /
                    Посетитель соглашается сотрудничать с SaaS-провайдером в
                    рамках защиты по таким претензиям. SaaS-провайдер будет
                    прилагать все разумные усилия для сообщения о любой такой
                    претензии, иске или разбирательстве, о которых ему
                    становится известно. Вышеуказанная гарантия возмещения
                    убытков и освобождения от ответственности включает в том
                    числе любые претензии, убытки, ущерб, обязательства, в том
                    числе расходы на юридическое сопровождение, которые
                    возникают в результате размещения Контента Пользователем /
                    Посетителем.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Применимое право и разрешение споров</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Соглашение регулируется и трактуется в соответствии с
                    законодательством Российской Федерации.
                </li>
                <li>
                    Любые споры и разногласия, вытекающие из Соглашения, в том
                    числе касающихся его исполнения, нарушения, прекращения или
                    недействительности, в том числе те, которые рассматриваются
                    в качестве таковых только одной из Сторон, подлежат
                    урегулированию в претензионном порядке.
                </li>
                <li>
                    Срок рассмотрения и ответа на претензию установлен не более{' '}
                    <strong>20 (Двадцати) рабочих дней </strong>с момента ее
                    официального поступления адресату.
                </li>
                <li>
                    Все споры, вытекающие из настоящего Соглашения или связанные
                    с ним или его действительностью, разрешаются сторонами
                    окончательно в компетентном суде в соответствии с
                    гражданским процессуальным правом Российской Федерации.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Совершение платежей</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Совершение платежных операций на Платформе происходит в
                    порядке и на условиях, указанных в Правилах совершения
                    платежей и возврата оплаты.
                </li>
                <li>
                    Пользователь обязуется самостоятельно нести ответственность
                    по уплате всех налогов и сборов, и признает, что
                    SaaS-провайдер ни при каких обстоятельствах не выступает его
                    налоговым агентом.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Заключительные положения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Все ссылки на Платформу, указанные в Соглашении, как и
                    содержащаяся по этим ссылкам информация, признается
                    неотъемлемой составной частью настоящего Соглашения.
                </li>
                <li>
                    Платформа (включая все Сервисы Платформы) может содержать
                    ссылки на сторонние веб-сайты или ресурсы (далее -{' '}
                    <strong>Сторонние сервисы</strong>). Такие Сторонние сервисы
                    могут регулироваться различным условиями и правилами
                    конфиденциальности. Saas-провайдер не несет ответственности
                    или не отвечает за доступность или точность таких Сторонних
                    сервисов или контента, продуктов или услуг, предоставляемых
                    такими Сторонними сервисами. Ссылки на такие Сторонние
                    сервисы не являются одобрением Saas-провайдера таких
                    Сторонних сервисов. Переход на такие Сторонние сервисы
                    осуществляется ПОльзователем на свой страх и риск.
                </li>
                <li>
                    Признание любого положения настоящего Соглашения
                    недействительным, либо неисполнимым и его аннулирование не
                    должно затрагивать остальных его положений, и такое
                    недействительное/неисполнимое положение подлежит замене
                    действительным/исполнимым и юридически значимым положением,
                    максимально приближенным по своему экономическому
                    содержанию, смыслу и эффекту к первоначальной редакции
                    Соглашения.
                </li>
                <li>
                    Пользователь не вправе передавать свои обязанности и(или)
                    права по Соглашению третьим лицам без письменного
                    согласования с SaaS-провайдером. В то же время,
                    SaaS-провайдер вправе передавать свои обязанности и(или)
                    права по Соглашению третьим лицам без ограничений, и без
                    необходимости получения согласия от Пользователя и(или) иных
                    третьих лиц.
                </li>
                <li>
                    Стороны признают, что вся электронная переписка, совершаемая
                    между Сторонами в ходе исполнения Соглашения, имеет
                    юридическую силу, признается Сторонами в качестве
                    составляющей части Соглашения и может использоваться в
                    качестве письменного доказательства при разрешении
                    возникающих споров.
                </li>
                <li>
                    Все документы, направленные с использованием электронной
                    почты, телефонов (включая мессенджеры), считаются
                    подписанными с использованием простой электронной цифровой
                    подписи, а также имеющими достоверный цифровой след
                    Стороны-отправителя, и равнозначны письменному документу.
                </li>
                <li>
                    Стороны договорились о возможности использования
                    факсимильного воспроизведения подписи с помощью средств
                    механического или иного копирования (факсимильная подпись) в
                    целях исполнения Соглашения. Стороны признают, что
                    факсимильная подпись имеет равную юридическую силу с
                    собственноручной подписью.
                </li>
            </ol>
        </Page>
    );
};

export default UserAgreement;
