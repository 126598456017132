import React from 'react';
import { Flex, Select, Typography } from 'antd';
import { FormikProps } from 'formik';

import { IProducts, ProductStatus } from '../../interfaces/products.interface';
import { options } from './options';

const filterOption = (
    input: string,
    option?: { label: string; value: ProductStatus }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

type TProps = {
    width: number;
    formik: FormikProps<IProducts>;
};
export const ProductStatusSwitcher: React.FC<TProps> = props => {
    const { formik, width } = props;

    return (
        <Flex gap={8} vertical>
            <Typography.Text>Отображение товара</Typography.Text>
            <Select
                style={{
                    width: width,
                }}
                showSearch
                defaultValue={formik.values.status || ProductStatus.active}
                placeholder='Выберите отображение товара'
                optionFilterProp='children'
                onChange={value => formik.setFieldValue('status', value)}
                filterOption={filterOption}
                options={options}
            />
        </Flex>
    );
};
