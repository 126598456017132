import { ProductsFilters } from '../interfaces/products.interface';
import { Shop } from '../interfaces/shop.interface';

export const initialFilters: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [2],
    page: 1,
};

export const IFiltersArchived: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [1, 3],
    page: 1,
};

export const authorsInitialFilters: Shop = {
    name: '',
    offset: 0,
    limit: 6,
};
