import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, Flex, Input, InputNumber } from 'antd';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import { Link } from 'react-router-dom';

import { LegalEntityValues } from '../../../interfaces/legalEntity.interface';

interface LegalForm {
    readonly checked: boolean;
    readonly setChecked: Dispatch<SetStateAction<boolean>>;
    readonly formik: FormikProps<LegalEntityValues>;
    readonly disabled?: boolean;
}

export const LegalForm: React.FC<LegalForm> = props => {
    const { setChecked, checked, formik, disabled } = props;

    const handleChange = (type: string, value: string) => {
        const text = value?.length > 1 ? value : value?.trim();
        formik.setFieldValue(type, text);
    };

    return (
        <Flex style={{ marginTop: '1.5rem' }} vertical gap={'1.5rem'}>
            <div>
                <StyledLabel>
                    <StyledRequired>*</StyledRequired>Почта
                </StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.email}
                    placeholder=''
                    onChange={event => {
                        handleChange(`email`, event.target.value);
                    }}
                    showCount
                />
            </div>
            <div>
                <StyledLabel>
                    <StyledRequired>*</StyledRequired>Название организации
                </StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.name}
                    placeholder=''
                    onChange={event => {
                        handleChange(`name`, event.target.value);
                    }}
                    showCount
                    maxLength={255}
                />
            </div>
            <div>
                <StyledLabel>
                    <StyledRequired>*</StyledRequired>ИНН
                </StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.inn}
                    placeholder=''
                    onChange={event => {
                        handleChange(`inn`, event.target.value);
                    }}
                    maxLength={12}
                    showCount
                />
            </div>
            <div>
                <StyledLabel>КПП</StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.kpp}
                    placeholder=''
                    onChange={event => {
                        handleChange(`kpp`, event.target.value);
                    }}
                />
            </div>
            <div>
                <StyledLabel>Номер телефона</StyledLabel>
                <InputNumber
                    disabled={disabled}
                    style={{ width: '100%' }}
                    prefix={'+'}
                    maxLength={11}
                    controls={false}
                    value={formik?.values?.phone}
                    placeholder='7900 000 00 00'
                    onChange={value => {
                        handleChange(`phone`, value?.toString());
                    }}
                />
            </div>
            <div>
                <StyledLabel>
                    <StyledRequired>*</StyledRequired>ОГРН
                </StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.ogrn}
                    placeholder=''
                    onChange={event => {
                        handleChange(`ogrn`, event.target.value.toString());
                    }}
                />
            </div>
            <div>
                <StyledLabel>
                    <StyledRequired>*</StyledRequired>Адрес
                </StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.address}
                    placeholder=''
                    onChange={event => {
                        handleChange(`address`, event.target.value);
                    }}
                />
            </div>

            <div>
                <StyledLabel>Иное</StyledLabel>
                <Input
                    disabled={disabled}
                    value={formik?.values?.additionally}
                    placeholder=''
                    onChange={event => {
                        handleChange(`additionally`, event.target.value);
                    }}
                />
            </div>

            <Flex
                vertical={false}
                justify={'space-between'}
                align={'flex-start'}
                gap={'1.5rem'}
            >
                <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                />
                <StyledLabel>
                    Нажатием на кнопку «Зарегистрироваться» подтверждаю, что я
                    ознакомлен с условиями{' '}
                    <Link to={`/user_agreement`}>
                        Пользовательского соглашения
                    </Link>
                    ,
                    <Link to={`/privacy_policy`}>
                        {' '}
                        Политика обработки персональных данных и файлов Cookie
                    </Link>{' '}
                    и даю
                    <Link to={'/personal-data-agreement'}>
                        {' '}
                        Согласие на обработку моих персональных данных
                    </Link>
                </StyledLabel>
            </Flex>
        </Flex>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const StyledRequired = styled.span`
    color: red;
    margin-right: 0.2rem;
`;
