import React, { useEffect, useState } from 'react';
import { App, Spin } from 'antd';

import { Settings } from '../Settings';
import { AuthorEditor } from './components/AuthorEditor';
import {
    useReadUserQuery,
    useUpdateUserAdditionalFieldsMutation,
} from '../../services';
import { getDeclension } from '../../../../utils/common/getDeclension';
import { IAdditionalFields } from '../../interfaces/additionalFields.interface';

export const Author: React.FC = () => {
    const { notification } = App.useApp();

    const [initialValues, setInitialValues] = useState<IAdditionalFields>(null);

    const { data: user, isSuccess, refetch } = useReadUserQuery();
    const [
        updateUserInfo,
        {
            isError: isErrorUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
        },
    ] = useUpdateUserAdditionalFieldsMutation();

    useEffect(() => {
        if (user && isSuccess) {
            setInitialValues({
                gender: user.gender,
                birthdate: user.birthdate,
                country: user.country,
            });
        }
    }, [user, isSuccess]);

    useEffect(() => {
        if (isSuccessUpdate) {
            refetch();
            notification.success({
                placement: 'top',
                message: 'Вы успешно рассказали о себе',
            });
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (isErrorUpdate) {
            notification.error({
                placement: 'top',
                message: 'Произошла ошибка при рассказе о себе',
            });
        }
    }, [isErrorUpdate]);

    const onSave = async values => {
        updateUserInfo(values);
    };

    const userInfoSet = !!(user?.gender || user?.birthdate || user?.country);
    const UserInfo = () => {
        const [transformGender, setTransformGender] = useState<string | null>(
            null
        );
        const [transformBirthdate, setTransformBirthdate] = useState<
            number | null
        >(null);
        const [lastDigit, setLastDigit] = useState<number | null>(0);

        useEffect(() => {
            if (user.gender)
                switch (user.gender) {
                    case 'f':
                        return setTransformGender('Женщина');
                    case 'm':
                        return setTransformGender('Мужчина');
                    default:
                        return setTransformGender('Мужчина');
                }
        }, [user.gender]);

        useEffect(() => {
            if (user?.birthdate) {
                const currentYear = new Date().getFullYear();
                const birthdate = +user.birthdate.split('.').slice(-1);

                const age = currentYear - birthdate;
                setTransformBirthdate(age <= 0 ? null : age);

                const handleLastDigit = (num = age) => {
                    const numStr = num.toString();
                    const lastChar = numStr.at(-1);
                    const lastDigitUnsigned = Number.parseInt(lastChar, 10);
                    setLastDigit(
                        num < 0 ? -lastDigitUnsigned : lastDigitUnsigned
                    );
                };
                handleLastDigit();
            }
        }, [user?.birthdate]);

        return isLoadingUpdate && isSuccess ? (
            <Spin />
        ) : (
            <>
                {transformGender && <span>{transformGender}, </span>}
                {transformBirthdate && (
                    <span>
                        {transformBirthdate}
                        {getDeclension(lastDigit, ' год', ' года', ' лет')}
                    </span>
                )}
                {user?.country && <span>, {user?.country}</span>}
            </>
        );
    };

    return (
        <Settings
            title='Соцдем'
            error={false}
            description={
                userInfoSet ? (
                    <UserInfo />
                ) : (
                    'Нам важно понимать, кто вы и откуда, чтобы предлагать лучший контент.'
                )
            }
        >
            {initialValues && (
                <AuthorEditor
                    initialValues={initialValues}
                    onSave={onSave}
                    loading={isLoadingUpdate}
                    success={isSuccessUpdate}
                    userInfoSet={userInfoSet}
                />
            )}
        </Settings>
    );
};
