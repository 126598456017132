type ENV = {
    API_URL: string;
};

class EnvManager {
    public env: ENV;
    public ADAPTER_API: string;
    public PUBLIC_PATH: string;
    public COURSES_PATH: string;
    public DADATA_KEY: string;

    constructor() {
        this.env = window.ENV;
        this.ADAPTER_API = window.ENV.API_URL.replace(/api\/v3/, 'gateway/api');
        this.PUBLIC_PATH = '/app';
        this.COURSES_PATH = '/courses';
        this.DADATA_KEY = 'a0a923ab827cea7808192b0997c0bf96d46e144f';
    }
}

let envManager: EnvManager;

const getEnvConfig = (): EnvManager => {
    if (!(envManager instanceof EnvManager)) {
        envManager = new EnvManager();
    }
    return envManager;
};

export default getEnvConfig;
