import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { App } from 'antd';
import { useFormik } from 'formik';

import { useCreateLegalInfoMutation } from '../../../services/legalEntity';
import { LegalForm } from '../views/LegalForm';
import { GenericModal } from '../../../../../shared/ui/GenericModal';
import { LegalEntityValues } from '../../../interfaces/legalEntity.interface';

interface CreateLegalContainer {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}
export const LegalCreateContainer: React.FC<CreateLegalContainer> = props => {
    const { open, setOpen } = props;
    const { notification } = App.useApp();
    const [checked, setChecked] = useState(false);

    const [createLegal, { isSuccess, isLoading, isError }] =
        useCreateLegalInfoMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: `Юридическая информация добавлена`,
            });

            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Вы некоректно заполнили одно или несколько полей',
            });
        }
    }, [isError]);

    const initialValues: LegalEntityValues = {
        name: '',
        inn: '',
        kpp: '',
        email: '',
        phone: '',
    };

    const onSave = async (values: LegalEntityValues) => {
        const payload = {
            ...values,
        };

        await createLegal(payload);
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    const disabled =
        formik.values.name === '' ||
        formik.values.inn.length < 10 ||
        formik.values.email.length < 3 ||
        !checked;

    return (
        <GenericModal
            open={open}
            onCancel={() => setOpen(false)}
            title={'Укажите сведения'}
            okButtonProps={{
                disabled: disabled,
            }}
            okText={'Сохранить'}
            destroyOnClose={true}
            onConfirm={() => onSave(formik.values)}
        >
            <LegalForm
                formik={formik}
                checked={checked}
                setChecked={setChecked}
            />
        </GenericModal>
    );
};
