import React from 'react';
import { Tabs, TabsProps } from 'antd';

import { Page } from '../../../../shared/ui/Page';
import { FeedsSettingsContainer } from './FeedsSettingsContainer';
import { FeedsHistoryContainer } from './FeedsHistoryContainer';

export const FeedsContainer = () => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Настройка',
            children: <FeedsSettingsContainer />,
        },
        {
            key: '2',
            label: 'История',
            children: <FeedsHistoryContainer />,
            disabled: true,
        },
    ];

    return (
        <Page title='Настройка фида'>
            <Tabs defaultActiveKey='1' items={items} />
        </Page>
    );
};
