import React from 'react';
import { Button, Modal, ModalProps } from 'antd';
import styled from 'styled-components';

type TProps = React.PropsWithChildren<ModalProps> & {
    readonly onConfirm?: () => void | any;
    readonly onCancel?: () => void;
};

export const GenericModal: React.FC<TProps> = props => {
    return (
        <StyledModal
            destroyOnClose
            width={props.width || 380}
            onCancel={props.onCancel}
            footer={props.footer || null}
            open={props.open}
            title={props.title}
        >
            {props.children}
            {props.footer !== null && (
                <StyledActions>
                    <Button
                        {...props.okButtonProps}
                        onClick={() => {
                            props.onConfirm && props.onConfirm();
                        }}
                        type='primary'
                    >
                        {props.okText || 'Ок'}
                    </Button>
                    <Button
                        onClick={() => {
                            props.onCancel && props.onCancel();
                        }}
                    >
                        {props.cancelText || 'Отмена'}
                    </Button>
                </StyledActions>
            )}
        </StyledModal>
    );
};

const StyledModal = styled(Modal)``;
const StyledActions = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
