import React from 'react';
import { Button, Card, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../../hooks/useTheme';
import getEnvConfig from '../../../utils/config/env';

const { Text } = Typography;

export const Warning = ({ text }) => {
    const { boxShadow } = useTheme();
    const navigate = useNavigate();

    return (
        <StyledCard
            type='inner'
            style={{
                boxShadow,
            }}
        >
            <Flex vertical={false} justify={'space-between'} align={'center'}>
                <Text>{text || ''}</Text>
                <Button
                    type={'primary'}
                    onClick={() =>
                        navigate(`${getEnvConfig().PUBLIC_PATH}/profile`)
                    }
                >
                    Перейти в профиль
                </Button>
            </Flex>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    width: 100%;
    margin-bottom: 1.5rem;
`;
