import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnvConfig from 'utils/config/env';

import { ISubscription } from '../interfaces';
import {
    ICheckSubscription,
    ISubscriptions,
} from '../interfaces/subscription.interface';
import { IUser } from '../../user/interfaces';

export const subscriptionsApi = createApi({
    reducerPath: 'subscriptions',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Subscription', 'User'],
    endpoints: builder => ({
        updateSubscription: builder.mutation<ISubscription, any>({
            query: () => ({
                url: '/subscription/recurrent',
                method: 'PATCH',
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Subscription', 'User'],
        }),
        checkSubscription: builder.query<any, void>({
            query: () => ({
                url: '/check/user/subscription',
            }),
            transformResponse: response => {
                return response;
            },
            providesTags: ['Subscription'],
        }),
        paySubscription: builder.mutation<
            { subscriptionId: number; successUrl: string },
            void
        >({
            query: (subscriptionId, successUrl) => ({
                url: '/subscription/pay',
                method: 'POST',
                body: {
                    subscription_id: subscriptionId,
                    success_url: successUrl,
                },
            }),
            transformResponse: response => {
                return response;
            },
        }),
        readSubscriptions: builder.query<any, void>({
            query: () => ({
                url: '/subscriptions_list',
            }),
            transformResponse: (response: ISubscriptions) => {
                return response?.data;
            },
            providesTags: ['Subscription'],
        }),
    }),
});

export const {
    usePaySubscriptionMutation,
    useUpdateSubscriptionMutation,
    useCheckSubscriptionQuery,
    useReadSubscriptionsQuery,
} = subscriptionsApi;
