import React, { useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import { Page } from '../../../../shared/ui/Page';
import { PromocodesSettingsContainer } from './PromocodesSettingsContainer';
import { PromocodesHistoryContainer } from './PromocodesHistoryContainer';

export const PromocodesContainer = () => {
    const [activeTab, setActiveTab] = useState<string>('settings');

    const items: TabsProps['items'] = [
        {
            key: 'settings',
            label: 'Настройка',
            children: (
                <PromocodesSettingsContainer setActiveTab={setActiveTab} />
            ),
        },
        {
            key: 'history',
            label: 'История',
            children: <PromocodesHistoryContainer />,
        },
    ];

    const onChange = (key: string) => {
        setActiveTab(key);
    };

    return (
        <Page title='Настройки промокодов'>
            <Tabs
                onChange={key => onChange(key)}
                activeKey={activeTab}
                defaultActiveKey='settings'
                items={items}
            />
        </Page>
    );
};
