import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

export const GuestLayout: React.FC = () => {
    return (
        <StyledLayout>
            <Outlet />
        </StyledLayout>
    );
};

const StyledLayout = styled.div`
    height: 100%;
    background-color: #f4f0ff;

    @media (max-width: 575px) {
        background-color: white;
    }
`;
