import React, { useEffect } from 'react';

import { Page } from '../../../ui/Page';

const PersonalDataAgreement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={'Согласие на обработку моих персональных данных'}>
            <p>
                В соответствии с п.4 ст.9 Федерального закона от 27.07.2006 N
                152-ФЗ &laquo;О персональных данных&raquo; Вы подтверждаете, что
                предоставляете Обществу с ограниченной ответственностью
                &laquo;Фабрика информационных технологий&raquo; (ИНН:
                3123164163, ОГРН: 1073123023160, адрес для направления почтовой
                корреспонденции: 308033, г. Белгород, ул. Королева, д 2А, пом.
                8, адрес электронной почты:{' '}
                <a href='mailto:info@spodial.com'>info@spodial.com</a>) (далее -{' '}
                <strong>Оператор</strong>) согласие на обработку Персональных
                данных, предоставляемых Вами Оператору на условиях Политики
                обработки персональных данных и файлов Cookie на Платформе
                (далее - <strong>Согласие</strong>), в порядке и на условиях,
                предусмотренных Политикой обработки персональных данных и файлов
                Cookie по адресу:{' '}
                <a href='https://spodial.com/privacy_policy'>
                    https://spodial.com/privacy_policy
                </a>{' '}
                (далее - <strong>Политика</strong>). Данное Согласие является
                неотъемлемой частью Политики. Все термины, указанные с большой
                буквы, используются в том значении, которое закреплено в
                Политике.
            </p>
            <p>
                <strong>
                    Вы соглашаетесь с тем, что Оператор будет обрабатывать Ваши
                    персональные данные в порядке и способом, указанном в
                    Политике, если иной срок не предусмотрен требованиями
                    законодательством РФ, либо больший срок не потребуется для
                    достижения следующих целей обработки:
                </strong>
            </p>
            <ol>
                <li>
                    Обеспечение соблюдения законодательства Российской
                    Федерации;
                </li>
                <li>
                    Предоставление Вам услуг в соответствии с условиями
                    Пользовательского соглашения, иных договоров / соглашений,
                    заключаемых с Оператором.
                </li>
            </ol>
            <p>
                <strong>
                    Настоящим Вы предоставляете конкретное, предметное,
                    информированное, сознательное и однозначное согласие на
                    обработку Ваших персональных данных, и соглашаетесь с тем,
                    что Оператор вправе совершать следующие действия с Вашими
                    данными в соответствии с п.3 ст.3 Федерального закона от
                    27.07.2006 N 152-ФЗ &laquo;О персональных данных&raquo;, а
                    также условиями Политики:
                </strong>
            </p>
            <p>
                сбор, запись, систематизация, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передача
                (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных,
                осуществляемые как с использованием средств автоматизации, так и
                без использования средств автоматизации (смешанная обработка).
            </p>
            <p>
                <strong>
                    Будучи Покупателем, Вы соглашаетесь с тем, что Оператор
                    имеет право передать ваши Персональные данные Продавцам для
                    обеспечения возможности исполнения Пользовательского
                    соглашения.
                </strong>
            </p>
            <p>
                Настоящее Согласие действует до истечения срока обработки Ваших
                Персональных данных, либо дня отзыва согласия, путем направления
                запроса на почту{' '}
                <a href='mailto:info@spodial.com'>info@spodial.com</a>, или в
                течение сроков хранения информации, установленных
                нормативно-правовыми актами Российской Федерации.
            </p>
            <p>
                НЕ ВВОДИТЕ СВОИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ И НЕ ИСПОЛЬЗУЙТЕ ПЛАТФОРМУ,
                ЕСЛИ ВЫ НЕ СОГЛАСНЫ С УКАЗАННЫМИ ВЫШЕ УСЛОВИЯМИ ИХ ОБРАБОТКИ.
            </p>
            <p>
                Вы подтверждаете, что, давая такое согласие, Вы действуете по
                собственной воле и в своих интересах.
            </p>
            <p>
                Если у Вас возникнут какие-либо вопросы, пожалуйста, направляйте
                их по адресу электронной почты{' '}
                <a href='mailto:info@spodial.com'>info@spodial.com</a>
            </p>
        </Page>
    );
};

export default PersonalDataAgreement;
