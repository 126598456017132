import React, { useEffect, useState } from 'react';
import { Badge, Typography } from 'antd';

export const Benefits = props => {
    const { source } = props;
    const [benefits, setBenefits] = useState([]);

    useEffect(() => {
        if (source) {
            setBenefits(JSON.parse(source));
        }
    }, [source]);

    return (
        <div>
            {benefits?.map((benefit, index) => (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '.5rem',
                    }}
                >
                    <div
                        style={{
                            marginRight: '.5rem',
                        }}
                    >
                        <Badge color='rgba(37, 33, 41, 0.88)' />
                    </div>
                    <div>
                        <Typography.Text>{benefit.name}</Typography.Text>
                        {benefit?.description && (
                            <strong>{benefit?.description}</strong>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
