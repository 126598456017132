import React, { useEffect } from 'react';

import { Page } from '../../../ui/Page';

const Oferta = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page
            title={
                'ПУБЛИЧНАЯ ОФЕРТА' +
                ' об условиях предоставления сервиса «Безопасная сделка для осуществления сбора» (далее – ОФЕРТА)'
            }
        >
            <p>
                <strong>ПУБЛИЧНАЯ ОФЕРТА</strong>
            </p>
            <p>
                <strong>
                    об условиях предоставления сервиса &laquo;Безопасная сделка
                    для осуществления сбора&raquo; (далее &ndash; ОФЕРТА)
                </strong>
            </p>
            <p>
                В настоящей Оферте содержатся условия предоставления Обществом с
                ограниченной ответственностью &laquo;Фабрика информационных
                технологий&raquo; услуг, возникающих при использовании сервиса
                &laquo;Безопасная сделка&raquo; на указанных в настоящей Оферте
                условиях.
            </p>
            <p>
                Изложенный ниже текст Оферты является официальным публичным
                предложением адресованным физическим лицам, являющимся
                Пользователями https://t.me/SpodialBot.
            </p>
            <p>
                Акцепт условий настоящей Оферты осуществляется в форме
                конклюдентных действий, предусмотренных Офертой, и означает
                безоговорочное принятие физическим лицом всех условий настоящей
                Оферты без каких-либо изъятий или ограничений на условиях
                присоединения согласно ст. 437 и ст. 428 Гражданского кодекса
                Российской Федерации.
            </p>
            <p>
                Акцепт условий настоящей оферты подтверждает факт заключения
                Договора об использовании сервиса &laquo;Безопасная
                сделка&raquo;.
            </p>
            <p>Актуальная версия Оферты размещена в сети Интернет по адресу:</p>
            <ul>
                <li>
                    <strong>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</strong>
                </li>
            </ul>
            <p>
                В настоящем документе термины, написанные с заглавной буквы,
                имеют следующее значение:
            </p>
            <p>
                <strong>Авторизация</strong> &ndash; процедура запроса и
                получения Банком ответа на такой запрос в виде разрешения или
                запрета на проведение операции по Карте от Банка-эмитента Карты
                или от Платежной системы.
            </p>
            <p>
                <strong>Банк&nbsp;</strong>&ndash; Акционерное общество
                &laquo;Тинькофф Банк&raquo; (лицензия
                ЦБ&nbsp;РФ&nbsp;№&nbsp;2673, ОГРН 1027739642281,
                местонахождение: 127287, г. Москва, ул. Хуторская 2-я, д. 38А,
                стр. 26), предоставляющее Пользователям Услуги Банка на
                условиях, предусмотренных настоящей Офертой.
            </p>
            <p>
                <strong>Банк-эмитент</strong> &ndash; кредитная организация,
                осуществляющая эмиссию Карты.
            </p>
            <p>
                <strong>Верификация</strong> &ndash; процедура дополнительной
                проверки Банком Держателя карты и Карты, осуществляемая с целью
                снижения рисков проведения несанкционированной/незаконной
                операции по Карте отправителя.
            </p>
            <p>
                <strong>Держатель Карты</strong> &ndash; Пользователь, на имя
                которого Банком или Банком-эмитентом эмитирована Карта.
            </p>
            <p>
                <strong>Личный кабинет</strong>&nbsp;&mdash; персональный раздел
                Пользователя на Сайте/ Приложении, связанный с Учетной записью
                Пользователя, в котором Пользователю доступно управление
                Сервисом.
            </p>
            <p>
                <strong>Общество</strong>&nbsp;&mdash; Обществом с ограниченной
                ответственностью &laquo;Фабрика информационных
                технологий&raquo;, ОГРН 1073123023160, обеспечивающее
                использования Сервиса Пользователями.
            </p>
            <p>
                <strong>Объявление&nbsp;</strong>&mdash; информационное
                сообщение с предложением о Сборе (включая контактную информацию,
                фотографии и любую сопутствующую информацию), размещаемое
                Получателем на Сайте/Приложении, адресованное неопределенному
                кругу лиц.
            </p>
            <p>
                <strong>Платежная страница Банка</strong> &mdash; интерфейс
                Банка, который размещен на Сайте/Приложении, используемый
                Пользователем для ввода реквизитов Карты и составлении, и
                передаче Поручения.
            </p>
            <p>
                <strong>Пользователь</strong>&nbsp;&mdash; дееспособное
                физическое лицо, зарегистрированное на Сайте/Приложении, и
                присоединившееся к настоящей Оферте, а также Получатель и
                Отправитель при совместном упоминании.
            </p>
            <p>
                <strong>Отправитель</strong>&nbsp;<strong>&mdash;</strong>{' '}
                Пользователь Сайта/Приложения, зарегистрированный на Сайте/в
                Приложении, осуществляющий просмотр размещенного Получателем
                Объявления, взаимодействие с Получателем в отношении Сбора.
            </p>
            <p>
                <strong>Поручение</strong> &mdash; направленные в Банк
                посредством использования Сайта /Приложения и Платежной страницы
                Банка акцепт Пользователя, содержащий информацию о присоединении
                к настоящей оферте и распоряжение Пользователя на совершение
                Банком перевода на основании предоставленной Клиентом и
                Обществом информации.
            </p>
            <p>
                <strong>Получатель&nbsp;&mdash;</strong> Пользователь
                Сайта/Приложения, зарегистрированный на Сайте/в Приложении и
                размещающий там Объявления с предложением участвовать в Сборе с
                использованием Сервиса.
            </p>
            <p>
                <strong>Сайт </strong>&mdash; интернет ресурс Общества,
                размещенный в сети интернет по
                адресу&nbsp;https://t.me/SpodialBot, предоставляющий возможность
                размещения Получателями Объявлений о Сборе, а также
                предоставляющий Отправителям возможность поиска, просмотра
                предложений Получателей с целью участия в Сборе с использованием
                Сервиса.
            </p>
            <p>
                <strong>Сервис &laquo;Безопасная сделка&raquo; (Сервис)</strong>
                &nbsp;- сервис, позволяющий Получателю и Отправителю при
                исполнении ими своих обязательств по Сбору использовать услуги,
                оказываемые Банком и Обществом в соответствии с настоящей
                Офертой, в порядке, предусмотренном настоящей Офертой.
            </p>
            <p>
                <strong>Сбор&nbsp;&mdash;</strong> способ привлечения
                добровольных Взносов, в отношении которого Продавец размещает на
                Сайте/в Приложении Объявление.
            </p>
            <p>
                <strong>Взнос - </strong>сумма денежных средств, выраженная
                исключительно в рублях Российской Федерации, которая была
                согласована Отправителем и Получателем или определена
                Отправителем самостоятельно.
            </p>
            <p>
                <strong>Учетная запись Пользователя</strong> - хранимая
                Обществом совокупность данных о Пользователе, предоставляющая
                собой комбинацию уникального логина и пароля, используемая для
                аутентификации Пользователя при входе в Личный кабинет с целью
                получения доступа к Сервису.
            </p>
            <ul>
                <li>
                    <strong>ОБЩИЕ ПОЛОЖЕНИЯ</strong>
                </li>
            </ul>
            <p>
                2.1. Общество и Банк предлагает Пользователям на условиях
                настоящей Оферты воспользоваться Сервисом.
            </p>
            <p>
                2.2. В рамках Сервиса Общество оказывает услуги по
                предоставлению Пользователям функциональности Сайта/Приложения,
                обеспечивающей возможность оплаты Отправителем и получения
                Получателем Взноса в безналичном порядке с использованием Карты
                (ее реквизитов), а также услуги по обмену информацией между
                Получателем и Отправителем в рамках Сбора.
            </p>
            <p>
                Расчеты в рамках Сервиса осуществляются Банком на условиях
                настоящей Оферты.
            </p>
            <p>
                2.3. Настоящая Оферта считается акцептованной Пользователем в
                момент совершения Пользователем соответствующих действий:
            </p>
            <p>
                2.3.1. Настоящая Оферта считается акцептованной Отправителем в
                момент подтверждения Отправителем своего согласия на оплату
                Взноса в пользу Получателя с использованием Сервиса путем
                нажатия специальной кнопки в интерфейсе Сайта/Приложения;
            </p>
            <p>
                2.3.2. Настоящая Оферта считается акцептованной Получателем в
                момент подтверждения Получателем своего согласия участвовать в
                Сборе с использованием Сервиса путем нажатия специальной кнопки
                в интерфейсе Сайта/Приложения.
            </p>
            <p>
                2.4. Фиксация факта акцепта настоящей Оферты осуществляется
                Обществом в электронном виде и хранится в аппаратно-программном
                комплексе Общества. Выписки из аппаратно-программного комплекса
                Общества могут использоваться в качестве доказательств при
                рассмотрении споров, в том числе в судебном порядке.
            </p>
            <p>
                2.5. Сайт является площадкой, позволяющей Получателям
                самостоятельно размещать предложения, адресованные
                неопределенному кругу лиц на участие в Сборе, а Отправителям
                принимать на свое усмотрение и под свою ответственность
                предложения, размещенные на Сайте.
            </p>
            <p>
                При оплате Взноса Отправитель вступает в прямые договорные
                отношения с Получателем. Общество не является стороной отношений
                по Сбору, организатором Сбора, Отправителем, Получателем,
                посредником, агентом или представителем какого-либо Пользователя
                и/или иным заинтересованным лицом.
            </p>
            <p>
                2.6. Осуществляя доступ к Сервису и заключая договоры, путем
                акцепта настоящей Оферты, Пользователь гарантирует, что обладает
                всеми правами и полномочиями, необходимыми для заключения и
                исполнения таких договоров, в том числе является
                совершеннолетним и полностью дееспособным лицом, либо
                несовершеннолетним лицом, объявленным по решению уполномоченного
                органа полностью дееспособным (эмансипация) либо
                несовершеннолетним лицом, достигшим четырнадцати лет и
                получившим письменное разрешение в требуемой законом форме от
                своих родителей или иных законных представителей на заключение
                договоров. Общество вправе в любое время потребовать от
                Пользователя предоставление информации и документов,
                подтверждающих права и полномочия, как указано выше.
            </p>
            <ul>
                <li>
                    <strong>ПОРЯДОК И УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ СЕРВИСА</strong>
                </li>
            </ul>
            <ul>
                <ul>
                    <li>
                        Размещение Объявления осуществляется при помощи
                        программно-технических средств Сайта/Приложения через
                        Личный кабинет. Объявление считается размещенным
                        Получателем с момента его публикации на
                        Сайте/Приложении. Объявление должно содержать в себе всю
                        необходимую информацию, которая предусмотрена
                        требованиями действующего законодательства Российской
                        Федерации.
                    </li>
                    <li>
                        Условия осуществления Сбора, указываются Получателем в
                        Объявлении. До внесения Взноса с использованием Сервиса
                        Отправитель обязуется внимательно ознакомиться со всеми
                        условиями Сбора, указанными Получателем в Объявлении.
                        Отправитель вправе обратиться к Получателю с целью
                        уточнения условий продажи Сбора путем направления
                        текстового сообщения в Личном кабинете или иным
                        способом. Внесение Взноса означает согласие Отправителя
                        со всеми условиями, указанными в Объявлении.
                    </li>
                    <li>
                        Для использования Сервиса Отправитель вправе привязать к
                        его Личному кабинету Карту - для более удобной и быстрой
                        оплаты Взноса, для осуществления возврата/частичного
                        возврата денежных средств в случаях, предусмотренных в
                        настоящей Оферте. В случае привязки Карты Банк
                        осуществляет сохранение реквизитов (привязку) Карты
                        Пользователя, с целью предоставления возможности
                        проведения повторных операций с данной Картой в рамках
                        Сервиса без повторного ввода ее реквизитов. Также
                        Отправитель вправе осуществлять оплату Взноса путём
                        ввода данных своей Карты каждый раз при внесении Взноса
                        (без привязки платежной карты).
                    </li>
                    <li>
                        .&nbsp;При подтверждении Отправителем согласия на оплату
                        Взноса путем нажатия на специальную кнопку в интерфейсе
                        Сайта/Приложения с использованием Сервиса выводится
                        Платежная страница Банка для ввода данных платежной
                        карты Отправителя, если она не была привязана ранее, и
                        подтверждения оплаты Взноса.&nbsp;Совершая указанные в
                        настоящем пункте действия Отправитель дает Поручение
                        Банку на списание денежных средств в размере Стоимости
                        Взноса с Карты Отправителя с целью осуществления
                        последующих расчётов с Получателем.
                    </li>
                    <li>
                        При наличии у Отправителя привязанной к его Личному
                        кабинету Карты либо при вводе данных Карты на
                        соответствующей Платежной странице Банка при оплате
                        заказа, при условии, что Банком получено разрешение на
                        проведение операции по Карте в результате Авторизации
                        и/или Верификации, при наличии на счете Карты денежной
                        суммы, равной размеру Взноса, а также комиссии Банка,
                        происходит списание денежных средств с Карты Отправителя
                        и их зачисление на Карту Получателя. Зачисление денежных
                        средств на Карту Получателя осуществляется по факту
                        получения от Общества информации о наступлении условий
                        по перечислению Взноса. В случае неполучения от Общества
                        соответствующей информации, Банк осуществляет возврат
                        денежных средств в размере стоимости Товара за вычетом
                        комиссии Банка по реквизитам Карты Отправителя. Расчеты
                        в рамках Договора осуществляются исключительно в валюте
                        Российской Федерации.
                    </li>
                    <li>
                        При размещении Объявления о Сборе Получатель должен
                        привязать к своему Личному кабинету платежную карту
                        путём ввода данных Карты на соответствующей Платежной
                        страницы Банка. В случае привязки Карты Банк
                        осуществляет сохранение реквизитов (привязку) Карты
                        Пользователя, с целью предоставления возможности
                        проведения повторных операций с данной Картой в рамках
                        Сервиса без повторного ввода ее реквизитов. Также
                        Получатель вправе ввести данные своей Карты без ее
                        привязки.
                    </li>
                    <li>
                        Отправитель и Получатель самостоятельно уплачивают все
                        необходимые налоги и сборы, подлежащие уплате в связи со
                        сделанным Взносом, в соответствии с применимым правом и
                        самостоятельно несут соответствующие риски и
                        ответственность в случае их неуплаты.
                    </li>
                    <li>
                        Отправитель и Получатель понимают и соглашаются, что при
                        переводах денежных средств на карты и с карту могут
                        взиматься дополнительные комиссии. Банк не несет
                        ответственности за комиссии, взимаемые
                        банком-получателем и/или банком-отправителем денежных
                        средств.
                    </li>
                    <li>
                        За оказание услуг в рамках Сервиса Банк имеет право
                        взимать с Клиента комиссию согласно Тарифам по платежам,
                        переводам и дополнительным услугам, размещенным по
                        адресу
                        https://static.tinkoff.ru/documents/tariffs/acquiring.pdf.
                        Комиссия рассчитывается от суммы операции и включается в
                        общую сумму авторизационного запроса, проводимого по
                        Карте, и подлежит списанию без дополнительных
                        распоряжений с Карты отправителя сверх суммы операции в
                        дату списания с Карты отправителя суммы операции.
                    </li>
                    <li>При использовании Сервиса Пользователи обязуются:</li>
                    <ul>
                        <li>
                            Надлежащим образом исполнять обязанности,
                            предусмотренные настоящей Офертой,Отправитель
                            обязуется соблюдать условия, установленые в
                            Объявлении;
                        </li>
                        <li>
                            При осуществлении расчетов соблюдать условия его
                            предоставления, предусмотренные настоящей Офертой;
                        </li>
                        <li>
                            Не использовать Сервис в целях осуществления
                            предпринимательской деятельности;
                        </li>
                        <li>При участии в Сборе проявлять осмотрительность.</li>
                    </ul>
                    <li>
                        Информационно-технологическое взаимодействие между
                        Банком и Отправителем/Получателем в части осуществления
                        переводов денежных средств в рамках Сбора обеспечивается
                        Обществом с использованием программных, аппаратных и
                        технических средств Сайта/Приложения.
                    </li>
                </ul>
            </ul>
            <p>
                Банк при осуществлении переводов денежных средств
                руководствуется Поручениями Отправителя, данными в интерфейсе
                Сайта/Приложения.
            </p>
            <ul>
                <ul>
                    <li>
                        Стороны считают данные, полученные от Общества,
                        достаточным и надлежащим подтверждением тех или иных
                        событий и действий Сторон, связанных с исполнением
                        обязательств Пользователей в рамках Сервиса. Факт
                        предоставления Пользователю услуг в рамках Сервиса и их
                        объем определяются на основании статистических данных
                        учетной системы Общества.
                    </li>
                </ul>
            </ul>
            <ul>
                <li>
                    <strong>ОБМЕН ИНФОРМАЦИЕЙ ПРИ ИСПОЛЬЗОВАНИИ СЕРВИСА</strong>
                </li>
            </ul>
            <p>
                4.1. В рамках Сервиса Общество и Банк могут направлять
                Пользователям информационные сообщения, связанные с оказанием
                услуг в рамках Сервиса. Сообщения Общества и Банка,
                предназначенные для Пользователей, могут рассылаться
                индивидуально по электронным адресам или на абонентские номера
                Пользователей, предоставленные Пользователями при регистрации
                Личного кабинета, размещении Объявлений на Сайте или оформлении
                заказа. При этом Пользователь понимает, принимает и соглашается,
                что рассылаемые сообщения и/или их отдельные части могут иметь
                рекламный характер, а также могут содержать рекламные,
                информационные и иные сообщения контрагентов Общества.
                Сообщения, опубликованные на Сайте/Приложении, считаются
                доставленными Пользователю с момента их публикации. Общество
                может направлять Пользователям транзакционные и сервисные
                сообщения, связанные с действиями Пользователей в рамках
                Сервиса.
            </p>
            <p>
                4.2. Сообщения, обращения Пользователей, предусмотренные
                настоящей Офертой, а также любые иные обращения Пользователей,
                предназначенные для Общества, пересылаются способами,
                предложенными на Сайте/Приложении, включая форму обратной связи
                для обращений на Сайте/Приложении.
            </p>
            <p>
                4.3. Обращения Пользователя к Обществу по вопросам, связанным с
                использованием Сервиса, рассматриваются в порядке,
                предусмотренном настоящей Офертой. Взаимодействие Общества с
                Пользователем в рамках рассмотрения обращения Пользователя
                осуществляется с использованием указанного Пользователем адреса
                электронной почты.
            </p>
            <p>
                4.4. Сообщения Пользователей друг другу могут быть направлены
                посредством специально предложенной формы связи для
                Пользователей в рамках Сервиса. Пользователь понимает и
                соглашается с тем, что переписка с другими Пользователями в
                рамках Сервиса, осуществляемая с помощью специально предложенной
                формы связи, не является личной. Общество в любой момент имеет
                право осуществлять просмотр отправляемых через специально
                предложенную форму связи сообщений, включая, без ограничений, в
                целях исполнения условий настоящей Оферты.
            </p>
            <p>
                Общество не гарантирует, что на сообщение, направленное одним
                Пользователем другому Пользователю, будет дан ответ, а также не
                гарантирует доставку такого сообщения Пользователю.
            </p>
            <p>4.5. Порядок рассмотрения Обществом обращений Пользователей:</p>
            <p>
                4.5.1. В случае наличия претензий Пользователя в отношении
                Сервиса или спора между Пользователями, связанного с исполнением
                Пользователями своих обязательств по Сбору, Пользователь вправе
                обратиться в Общество с целью разрешения указанных претензий и
                споров, направив соответствующее сообщение в службу поддержки;
            </p>
            <p>
                4.5.2. Обращение должно содержать описание проблемы, документы,
                подтверждающие позицию Пользователя, документы, предусмотренные
                условиями настоящей Оферты, а также иные необходимые документы.
                Общество вправе запросить у Пользователя иные документы и
                сведения, необходимые для рассмотрения обращения (в т.ч., без
                ограничений, заключение независимой экспертизы, если таковое
                необходимо для рассмотрения обращения). В случае
                непредоставления Пользователем запрошенных Обществом документов
                и сведений, Общество вправе рассмотреть обращение Пользователя
                без учета таких документов или приостановить рассмотрение
                обращения до момента предоставления Пользователем необходимых
                документов или сведений;
            </p>
            <p>
                4.5.3. В ходе рассмотрения обращения Общество вправе обращаться
                к другой стороне Сбора с целью выяснения обстоятельств спора
                между Пользователями и получения необходимых документов или
                сведений. В случае рассмотрения Обществом спора между
                Пользователями, каждый из Пользователей вправе предоставлять
                аргументы и доказательства надлежащего исполнения своих
                обязательств по Сбору;
            </p>
            <p>
                4.5.4. По результатам рассмотрения обращения Пользователя
                Общество принимает решение на основании имеющихся у Общества
                документов и доводов;
            </p>
            <p>
                4.5.5. Пользователи принимают и соглашаются, что решение,
                принятое Обществом по результатам рассмотрения обращения
                Пользователей, может служить основанием для осуществления
                расчетов между Пользователями в Сервиса (возврата денежных
                средств Покупателю или перевода денежных средств Получателю).
            </p>
            <ul>
                <li>
                    <strong>ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ</strong>
                </li>
            </ul>
            <p>
                5.1. Пользователь несет ответственность за действия, совершаемые
                в рамках Сервиса, в соответствии с действующим законодательством
                Российской Федерации и условиями настоящей Оферты, включая
                ответственность за содержание размещаемой им информации и
                нарушение прав третьих лиц в отношении Сбора и/или информации,
                размещаемой на Сайте/приложении и пр.
            </p>
            <p>
                5.2. Пользователь несет ответственность за цели Сбора. Все
                Взносы в рамках Сбора осуществляются между Пользователями
                напрямую. Общество/Банк не являются участниками и/или
                посредниками в рамках Сбора в рамках Сервиса, совершаемых
                Пользователями исходя из информации, полученной на
                Сайте/Приложении, не контролируют и не несут ответственности за
                такие сделки.
            </p>
            <p>
                5.3. Сервис предназначен для использования в целях, не связанных
                с осуществлением предпринимательской деятельности, получением
                денежных средств в качестве инвестиций, использованием схемы
                &laquo;пирамид&raquo;, многоуровневого (сетевого) маркетинга и
                др. предложений заработка. Отношения, между Пользователями,
                регулируются законодательством Российской Федерации, при этом
                Пользователи понимают, что отдельные положения законодательства
                Российской Федерации, в т.ч. в части защиты прав потребителей,
                могут не применяться к отношениям Пользователей.
            </p>
            <p>
                5.4. Пользователь согласен, что Общество и/или Банк не несет
                ответственности за возможные убытки, причиненные Пользователю в
                связи с принятием мер пресечения или предотвращения нарушений на
                Сайте, связанных с ограничением/блокировкой доступа
                Пользователей к Сервису.
            </p>
            <p>
                5.5. Общество не несет ответственности за неисполнение или
                затруднения в исполнении обязательств Пользователей, в т.ч.
                обязательств по Сбору, вследствие обстоятельств непреодолимой
                силы, последствия которых невозможно избежать или преодолеть.
            </p>
            <ul>
                <ul>
                    <li>
                        Банк не несет ответственности в случаях, когда
                        зачисление средств на Карту Получателя осуществлено с
                        нарушениями сроков и иных требований, установленных
                        правилами ПС и законодательством РФ по вине
                        Банка-эмитента или иных лиц.
                    </li>
                    <li>
                        Банк не несет ответственность за несвоевременное
                        сообщение Отправителем и/или Обществом информации об
                        оплате Взноса и отсутствии претензий к Получателю.
                    </li>
                    <li>
                        Банк не несет ответственности за ошибки, допущенные
                        Пользователями при оформлении Поручения, а также за
                        предоставление некорректных реквизитов Карты. В
                        указанных случаях услуги считаются оказанными Банком
                        Покупателю надлежащим образом и в полном соответствии с
                        настоящим договором, и Отправитель самостоятельно
                        урегулирует дальнейшие взаиморасчеты с Получателем или
                        Обществом.
                    </li>
                    <li>
                        Банк имеет право отказать Пользователю в оказании услуг
                        Банка в одностороннем порядке и без объяснения причин, в
                        том числе, если у Банка отсутствует техническая
                        возможность для их оказания, если параметры операции не
                        соответствуют установленным Банком и/или ПС и/или
                        применимым законодательством ограничениям по суммам и
                        количеству операций по Картам, в случае выявления
                        операций Пользователей, содержащих признаки необычных
                        операций или несущих репутационные и/или финансовые
                        риски для Банка, а также, если у Банка возникли
                        подозрения в том, что операция осуществляется с
                        нарушением требований законодательства РФ и/или правил
                        ПС.
                    </li>
                </ul>
            </ul>
            <ul>
                <li>
                    <strong>СРОК ДЕЙСТВИЯ ДОГОВОРА</strong>
                </li>
            </ul>
            <p>
                6.1. Заключаемые путем акцепта настоящей Оферты договоры
                вступают в силу с момента акцепта условий настоящей Оферты
                Пользователем в порядке, предусмотренном настоящей Офертой, и
                действует до полного исполнения Сторонами своих обязательство по
                нему.
            </p>
            <p>
                6.2. Общество оставляет за собой право по собственному
                усмотрению прекратить или ограничить доступ Пользователя к
                Сервису.
            </p>
            <p>
                6.3. Общество и/или Банк вправе изменять и/или дополнять условия
                Оферты в той мере, в какой это допускается действующим
                законодательством, а также отозвать Оферту в любое время. Датой
                изменения Оферты является дата опубликования новой редакции
                Оферты. Пользователь должен ознакомиться с действующей редакцией
                Оферты, размещенной на Сайте/Приложении, и с условиями каждого
                Сбора до присоединения к Оферте.
            </p>
            <p>
                6.4. Участие Пользователей в Сборе с использованием Сервиса
                после вступления в силу изменений в Оферте означает, что стороны
                пришли к согласию о внесении соответствующих изменений и/или
                дополнений в Оферте.
            </p>
            <p>
                6.5. Общество вправе расторгнуть договоры в порядке,
                предусмотренном действующим законодательством Российской
                Федерации, с уведомлением об этом Пользователя через интерфейс
                Сайта, по адресу электронной почты или иным способом.
            </p>
            <p>
                6.6. Действие договора об использовании Сервиса может быть
                прекращено досрочно по инициативе Пользователя путем обращения
                Пользователя в Компанию в порядке, предусмотренном настоящей
                Офертой.
            </p>
            <ul>
                <li>
                    <strong>СПОРЫ И ДЕЙСТВУЮЩЕЕ ЗАКОНОДАТЕЛЬСТВО</strong>
                </li>
            </ul>
            <p>
                7.1. При разрешении всех споров, вытекающих из настоящей Оферты,
                применяется действующее законодательство Российской Федерации.
            </p>
            <p>
                7.2. Споры, возникшие в рамках настоящего договора, должны быть
                переданы на рассмотрение в соответствии с действующим
                законодательством Российской Федерации.
            </p>
            <p>
                7.3. Признание отдельных частей настоящей Оферты
                недействительными не отменяет действие других ее положений.
            </p>
            <ul>
                <li>
                    <strong>ПРОЧИЕ УСЛОВИЯ</strong>
                </li>
            </ul>
            <p>
                8.1. Общество и/или Банк имеет право, а Пользователь
                соглашается, на обработку любой информации, относящейся к
                персональным и/или контактным данным Пользователя, с
                использованием средств автоматизации или без таковых, включая
                сбор, систематизацию, накопление, хранение, уточнение,
                использование, распространение (в том числе передачу партнерам
                Банка и/или Общества, трансграничную передачу), обезличивание,
                блокирование, уничтожение персональных данных, предоставленных
                Банку в связи с использованием Сервиса, и иные действия,
                предусмотренные Федеральным законом от 27.07.2006 № 152-ФЗ
                &laquo;О персональных данных&raquo;. Пользователь выражает
                согласие Банку на предоставление сведений, указанных в настоящем
                пункте, Банку-эмитенту Карты получателя и/или ПС, для целей
                исполнения условий настоящей Оферты, а также в любых других
                целях, прямо или косвенно связанных с исполнением Банком
                обязательств в рамках настоящей Оферты, и предложение продуктов
                Банка.
            </p>
            <p>
                8.2. Пользователь гарантирует, что все условия настоящей Оферты
                ему понятны, и он принимает условия без оговорок и в полном
                объеме.
            </p>
            <p>
                8.3. В случае, если отдельные положения настоящей Оферты
                прекращают действие по основаниям, предусмотренным
                законодательством Российской Федерации, это не влечет
                прекращения или недействительности заключенных между сторонами
                договоров и его приложений в целом.
            </p>
            <p>
                8.4. Все действия Пользователей, совершаемые в соответствии с
                настоящей Офертой, обрабатываются и учитываются Банком и
                Обществом по московскому времени. В качестве языка Договора,
                заключаемого на условиях настоящей Оферты, а также языка,
                используемого при любом взаимодействии сторон (включая ведение
                переписки, предоставление требований/уведомлений/разъяснений,
                предоставление документов и т.д.), стороны определили русский
                язык.
            </p>
        </Page>
    );
};

export default Oferta;
