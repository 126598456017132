import React, { Dispatch, memo, SetStateAction } from 'react';
import { Button, Flex, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import getEnvConfig from '../../../../utils/config/env';
import { CategoryEditorContainer } from '../../containers/Categories/CategoryEditorContainer';
import { ProductsFilters } from '../../interfaces/products.interface';

type ProductTabs = {
    loading: boolean;
    shopId: number;
    filters: ProductsFilters;
    setFilters: Dispatch<SetStateAction<ProductsFilters>>;
};
export const ProductTabs = memo((props: ProductTabs) => {
    const navigate = useNavigate();

    const { loading, shopId, filters, setFilters } = props;

    const handleCreateProduct = () => {
        navigate(
            `${getEnvConfig().PUBLIC_PATH}/shops/${+shopId}/product-create`
        );
    };

    return (
        <Flex justify={'space-between'} wrap={'wrap'} gap={20}>
            <Flex vertical={false} gap={12}>
                <Button
                    disabled={loading}
                    type={'primary'}
                    onClick={handleCreateProduct}
                >
                    Добавить товар
                </Button>

                <div>
                    <Input
                        allowClear
                        onChange={e =>
                            setFilters({
                                ...filters,
                                title: e.target.value,
                            })
                        }
                        placeholder={'Поиск товаров'}
                    />
                </div>
            </Flex>

            <CategoryEditorContainer
                type={'page'}
                filters={filters}
                setFilters={setFilters}
                shopId={+shopId}
            />
        </Flex>
    );
});
