export const gender = [
    {
        value: 'm',
        label: 'Мужской',
    },
    {
        value: 'f',
        label: 'Женский',
    },
];

export const genderPlaceholder = 'Укажите ваш пол';
export const countryPlaceholder = 'Выберите страну';
export const birthdateFormat = 'DD.MM.YYYY';
//Устанавливаем с какого года рождения можно регистрироваться в качестве автора
export const disabledYear = 2007;

export const countriesUrl =
    'https://gist.githubusercontent.com/sanchezzzhak/8606e9607396fb5f8216/raw/8a7209a4c1f4728314ef4208abc78be6e9fd5a2f/ISO3166_RU.json';
