import { RouteObject } from 'react-router';

import { Subscriptions } from 'features/subscriptions/pages';

const routes: RouteObject[] = [
    {
        path: 'subscriptions',
        element: <Subscriptions />,
    },
];

export default routes;
