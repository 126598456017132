import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex } from 'antd';

import { useToggle } from '../../../../hooks/useToggle';
import { CategoryModal } from '../../views/Categories/CategoryModal';
import { CategoryFilter } from '../../views/Categories/CategoryFilter';

interface CategoryEditor {
    filters: any;
    shopId: number;
    type: string | undefined;
    setFilters: Dispatch<SetStateAction<any>>;
}
export const CategoryEditorContainer: React.FC<CategoryEditor> = props => {
    const { filters, setFilters, shopId, type } = props;
    const { open, setOpen } = useToggle();

    return (
        <Flex gap={12} wrap={'wrap'}>
            <CategoryFilter
                filters={filters}
                setFilters={setFilters}
                shopId={shopId}
                type={type}
            />
            <Button onClick={() => setOpen(true)}>
                Редактировать категории
            </Button>
            <CategoryModal open={open} setOpen={setOpen} />
        </Flex>
    );
};
