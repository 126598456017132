import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SubscriptionSlug } from '../../../../subscriptions/interfaces/subscription.interface';

const { Title, Text } = Typography;

export const LegalErrorContainer = () => {
    const navigate = useNavigate();

    return (
        <Flex vertical gap={'1.5rem'}>
            <Flex vertical>
                <Title style={{ marginBottom: 12 }} level={1}>
                    Ошибка оплаты
                </Title>
                <Text>При оплате произошла ошибка.</Text>
            </Flex>
            <div>
                <Button
                    onClick={() =>
                        navigate(
                            `/app/subscriptions?type=${SubscriptionSlug.payJuriPlan}`
                        )
                    }
                    type={'primary'}
                >
                    Вернуться к подпискам
                </Button>
            </div>
        </Flex>
    );
};
