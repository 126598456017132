import { Button, Space } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ITelegramAccount } from 'features/user/interfaces';
import getEnvConfig from 'utils/config/env';

import { Settings } from '../Settings';
import TelegramAccount from './components/TelegramAccount';
import { AddTelegramAccount } from './components/AddTelegramAccount';

type TProps = {
    accounts: Array<ITelegramAccount>;
    isAccountPrimary: boolean;
};

export const TelegramAccounts: React.FC<TProps> = props => {
    return (
        <Settings
            title='Telegram'
            error={!props.accounts.length}
            description='Добавлять сообщества можно, только если привязан хотя бы
                    один аккаунт Telegram.'
        >
            <Fragment>
                {!!props.accounts.length && (
                    <Wrapper>
                        {props.accounts.map((account, index) => (
                            <TelegramAccount account={account} key={index} />
                        ))}
                    </Wrapper>
                )}
                <StyledActions wrap>
                    <AddTelegramAccount
                        isAccountPrimary={props.isAccountPrimary}
                    />

                    {/*{!!props.accounts.length && (
                       <Link to={`${getEnvConfig().PUBLIC_PATH}/communities`}>
                            <Button type='primary'>
                                Управлять сообществами
                            </Button>
                        </Link>
                    )}*/}
                </StyledActions>
            </Fragment>
        </Settings>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 8px;
`;

const StyledActions = styled(Space)`
    display: flex;
    gap: 16px;
`;
