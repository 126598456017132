import { Dispatch, SetStateAction, useCallback, useState } from 'react';

interface IUseToggle {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setTrue: () => void;
    setFalse: () => void;
    toggle: () => void;
}

export const useToggle = (defaultValue?: boolean): IUseToggle => {
    const [open, setOpen] = useState(!!defaultValue);

    const setTrue = useCallback(() => setOpen(true), []);
    const setFalse = useCallback(() => setOpen(false), []);
    const toggle = useCallback(() => setOpen(x => !x), []);

    return { open, setOpen, setTrue, setFalse, toggle };
};
