import React from 'react';
import { Typography } from 'antd';

import { Settings } from '../Settings';
import { PhoneEditor } from './components/PhoneEditor';

type TProps = {
    readonly phone: string;
    readonly confirmed: boolean;
    readonly isPhonePrimary: boolean;
};

export const Phone: React.FC<TProps> = props => {
    return (
        <Settings
            title='Телефон'
            error={!props.confirmed}
            description='Монетизация доступна только пользователям с подтверждённым номером телефона.'
        >
            <>
                <Typography.Text>{props.phone}</Typography.Text>
                <PhoneEditor isPhonePrimary={props.isPhonePrimary} />
            </>
        </Settings>
    );
};
