import React, { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router';

import getEnvConfig from 'utils/config/env';

export const ProtectedRoute: FC<PropsWithChildren> = props => {
    const { children } = props;

    const accessToken = localStorage.getItem('accessToken');

    const isAllowedToVisit = (): boolean => {
        return !!accessToken;
    };

    if (isAllowedToVisit()) {
        return <>{children}</>;
    }

    return <Navigate to={`${getEnvConfig().PUBLIC_PATH}/auth/sign-in`} />;
};
