import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';

import { getSrc } from '../../../../utils/common/getSrc';
import { ProductImage } from '../Product/ProductImage';

export const ShopImageUpload = props => {
    const { formik, isEdit } = props;
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>(formik?.values?.photo);

    const dummyRequest = async onSuccess => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const getBase64 = (img, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            callback(reader.result as string)
        );
        reader.readAsDataURL(img);
    };

    const handleChange: UploadProps['onChange'] = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, url => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    return (
        <ImgCrop
            rotationSlider
            quality={0.75}
            showReset
            showGrid
            modalTitle={'Отредактируйте изображение'}
            modalOk={'Применить'}
            resetText={'Отменить изменения'}
        >
            <Upload
                name='avatar'
                maxCount={1}
                customRequest={file => {
                    void dummyRequest(file.onSuccess);
                }}
                beforeUpload={file => {
                    formik.setFieldValue(`photo`, file);
                }}
                onChange={handleChange}
                listType='picture-card'
                showUploadList={false}
            >
                {imageUrl ? (
                    <Col span={24}>
                        <ProductImage
                            src={
                                isEdit &&
                                typeof formik.values.photo !== 'object'
                                    ? getSrc(`/storage/${imageUrl}`)
                                    : imageUrl
                            }
                            gutter={0}
                        />
                    </Col>
                ) : (
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Загрузить</div>
                    </div>
                )}
            </Upload>
        </ImgCrop>
    );
};
