import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
import styled from 'styled-components';

import { ISubscription } from 'features/subscriptions/interfaces';
import { useReadSubscriptionsQuery } from 'features/subscriptions/services';
import getEnvConfig from 'utils/config/env';

import { useProfileData } from '../../../../hooks/useProfileData';

export const CurrentSubscription = props => {
    const [currentSubscription, setCurrentSubscription] =
        useState<ISubscription>(null);

    const { data: subscriptions = [] } = useReadSubscriptionsQuery();
    const { user } = useProfileData();

    const isPaidSubscription = user?.subscription.subscription_id === 2;

    useEffect(() => {
        if (user && subscriptions) {
            const userSubscription = subscriptions.find(
                s => s.id === user.subscription?.subscription_id
            );
            setCurrentSubscription(userSubscription);
        }
    }, [user, subscriptions]);

    return (
        <StyledWrapper>
            <Space
                size={'large'}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Space direction={'vertical'}>
                    <StyledTitle level={5}>Пакет услуг</StyledTitle>
                    <StyledTitle level={3}>
                        {currentSubscription?.name}
                    </StyledTitle>
                </Space>

                {props.isEdit ? (
                    <div>
                        {user && (
                            <Link
                                to={`${
                                    getEnvConfig().PUBLIC_PATH
                                }/subscriptions`}
                            >
                                <Button
                                    type={
                                        isPaidSubscription
                                            ? 'default'
                                            : 'default'
                                    }
                                >
                                    Управлять подпиской
                                </Button>
                            </Link>
                        )}
                    </div>
                ) : null}
            </Space>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    width: 100%;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0;
`;
