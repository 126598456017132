import React, { Fragment, useState } from 'react';
import { Button, notification } from 'antd';
import { FormikProps } from 'formik';

import { PasswordChangeForm } from 'shared/widgets/PasswordChangeForm';
import { useChangePasswordMutation } from 'features/user/services';
import { GenericModal } from 'shared/ui/GenericModal';

import { Settings } from '../Settings';
import { useProfileData } from '../../../../hooks/useProfileData';

export const Password: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>();

    const { user } = useProfileData();
    const [changePassword] = useChangePasswordMutation();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (
        passwords: {
            password: string;
            password_confirmation: string;
        },
        formikProps: FormikProps<unknown>
    ) => {
        try {
            await changePassword(passwords).unwrap();
            handleCancel();
            notification.open({
                placement: 'top',
                message: 'Уведомление',
                description: 'Пароль был сменен',
                duration: 4.5,
                type: 'success',
                onClick: () => {},
            });
        } catch (e) {
            formikProps.setErrors({
                password: e.data.message,
            });
        }
    };

    return (
        <Settings title='Пароль' description=''>
            <Fragment>
                <Button onClick={() => setIsModalOpen(true)}>
                    Сменить пароль
                </Button>
                <GenericModal
                    destroyOnClose
                    width={380}
                    onCancel={handleCancel}
                    footer={null}
                    open={isModalOpen}
                    title='Смена пароля'
                >
                    <p>Задайте новый пароль для {user?.email}</p>
                    <PasswordChangeForm
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                    />
                </GenericModal>
            </Fragment>
        </Settings>
    );
};
