import React, { useEffect } from 'react';

import { Page } from '../../../ui/Page';

const PaymentsRules = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={'Правила совершения платежей и возврата оплаты'}>
            <p>
                Настоящие Правила устанавливают правила совершения платежных
                операций на Платформе, и возврата оплаты от SaaS-провайдера к
                Пользователю. Правила являются приложением к Соглашению.
            </p>
            <ul>
                <li>
                    <strong>
                        <strong>Основные и новые термины и определения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    <strong>Банк </strong>- банковская или иная финансовая
                    организация.
                </li>
                <li>
                    <strong>Банк-эмитент</strong> &ndash; кредитная организация,
                    которая осуществила эмиссию Карты.
                </li>
                <li>
                    <strong>Карта</strong> &ndash; банковская карта,
                    эмитированная Банком или Банком-эмитентом.
                </li>
                <li>
                    <strong>Saas-провайдер </strong>&ndash; Общество с
                    ограниченной ответственностью &laquo;Фабрика информационных
                    технологий&raquo; (ООО &laquo;ФИТ&raquo;), ИНН: 3123164163,
                    ОГРН: 1073123023160, адрес для направления почтовой
                    корреспонденции: 308033, г. Белгород, ул. Королева, д 2А,
                    пом. 8, адрес электронной почты:{' '}
                    <a href='mailto:info@spodial.com'>info@spodial.com</a>
                </li>
                <li>
                    <strong>ПС </strong>&ndash; международные платежные системы
                    Visa International или MasterCard Worldwide, а также
                    Национальная система платежных карт &laquo;МИР&raquo;.
                </li>
                <li>
                    <strong>
                        Политика обработки персональных данных и файлов Cookie{' '}
                    </strong>
                    &ndash; приложение к Соглашению, регулирующее порядок
                    обработки SaaS-провайдером персональных данных, размещенное
                    в сети Интернет по ссылке:{' '}
                    <a href='https://spodial.com/privacy_policy'>
                        https://spodial.com/privacy_policy
                    </a>
                </li>
                <li>
                    <strong>Платформа </strong>&ndash; принадлежащий
                    SaaS-провайдеру программно-аппаратный комплекс{' '}
                    <strong>&laquo;Spodial&raquo; </strong>по адресу:{' '}
                    <a href='https://spodial.com'>https://spodial.com</a>, а
                    также отдельный функционал в мессенджере Telegram
                    @SpodialBot.
                </li>
                <li>
                    <strong>Платежные сервисы</strong> &ndash; совокупность
                    программных и аппаратных средств, предоставляемых
                    SaaS-провайдером, обеспечивающих информационное и
                    технологическое взаимодействие между Пользователем и
                    SaaS-провайдером при совершении платежей с использованием
                    сети Интернет, предоставляемых, в том числе, путем
                    привлечения банковских и иных финансовых организаций.
                    Порядок совершения платежей осуществляется в соответствии с
                    настоящими Правилами и/или правилами таких привлеченных
                    банковских и иных финансовых организаций. Список Платежных
                    сервисов указывается на Платформе.&nbsp;
                </li>
                <li>
                    <strong>Платежная операция</strong> &ndash; совокупное
                    упоминание следующих операций с использованием доступных
                    Платежных сервисов: Совершение Пользователем платежа в
                    пользу SaaS-провайдера.
                </li>
                <li>
                    <strong>Соглашение </strong>&ndash; Пользовательское
                    соглашение, размещенное в сети Интернет по ссылке:{' '}
                    <a href='https://spodial.com/user_agreement'>
                        https://spodial.com/user_agreement
                    </a>
                </li>
                <li>
                    Термины, которые употребляются в настоящем документе, но
                    которым не было дано определение, понимаются в значении,
                    которое придается им Соглашением, далее - приложениями к
                    нему, а в последнюю очередь - в значении, которое придается
                    им в сети Интернет.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Предмет правил</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Настоящие Правила регулируют способы, порядок и условия
                    совершения Пользователями Платежных операций на Платформе с
                    целью оплаты Пакетов услуг.
                </li>
                <li>
                    Если иное не предусмотрено Соглашением или имеющимся на
                    Платформе функционалом, Пользователь при совершении
                    Платежной операции может использовать любой доступный
                    Платежный сервис, использование которого предусмотрено
                    SaaS-провайдером. При безналичном перечислении денежных
                    средств, датой оплаты считается дата зачисления денежных
                    средств на расчетный счет SaaS-провайдера.
                </li>
                <li>
                    Поскольку настоящие Правила являются приложением к
                    Соглашению, во всем остальном, что не предусмотрено
                    Правилами, Стороны руководствуются положениями Соглашения.
                </li>
                <li>
                    SaaS-провайдер вправе вносить в Правила изменения, которые
                    вступают в силу с момента публикации на Платформе, и не
                    требуют какого-либо предварительного уведомления (одобрения,
                    согласия) Пользователей. Правила в новой редакции действует
                    как для новых Пользователей, так и для Пользователей,
                    принявших условия Правил до внесения изменений.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Платеж в пользу SaaS-провайдера</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Совершение Платежной операции Пользователем в пользу
                    SaaS-провайдера может быть осуществлено в качестве
                    встречного предоставления со стороны Пользователя за
                    оказание услуг SaaS-провайдером, входящих в Пакет услуг, и
                    предусмотренных Соглашением или приложениями к нему.
                </li>
                <li>
                    Указанное в настоящем разделе вознаграждение SaaS-провайдера
                    уплачивается Пользователем в порядке 100% (Стопроцентной)
                    предварительной оплаты. Размер Платежной операции
                    предусматривается положениями Соглашения, либо указываться
                    непосредственно на Платформе.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Правила возврата денежных средств</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Пользователь вправе потребовать возврата произведенной
                    оплаты без объяснения причин в срок, не превышающий 24
                    (Двадцати четырех) часов с момента произведенной оплаты за
                    Пакет услуг.
                </li>
                <li>
                    Пользователь вправе потребовать возврата произведенной
                    оплаты в случае, если после успешной оплаты доступ к
                    функционалу Платформы в объеме, соответствующем объему
                    оплаченного Пакета услуг, не был предоставлен
                    SaaS-провайдером в течение 48 (Сорока восьми) часов с
                    момента оплаты.
                </li>
                <li>
                    За исключением случаев, указанных в настоящем разделе,
                    услуги SaaS-провайдера по предоставлению доступа к
                    функционалу Платформы в объеме, соответствующем объему
                    оплаченного Пакета услуг, считаются оказанными в полном
                    объеме, и возврат оплаченных средств не предусмотрен.&nbsp;
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Условия использования банковских карт</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>К оплате принимаются карты VISA, MasterCard, МИР;</li>
                <li>
                    На странице для ввода данных банковской карты потребуется
                    ввести: номер банковской карты, имя владельца карты, срок
                    действия карты, трехзначный код безопасности (CVV2 для Visa,
                    CVC2 для MasterCard, Код Дополнительной Идентификации для
                    МИР). Все необходимые данные находятся на самой карте, CCV
                    код безопасности &mdash; это три цифры, которые размещены на
                    обратной стороне карты. После чего вы будете направлены на
                    страницу Вашего банка для введения кода безопасности,
                    который вы получите в СМС. Если код не придёт, вам следует
                    обратиться в банк, в котором происходила выдача карты.
                </li>
            </ol>
            <ol>
                <li>
                    Операции по банковским картам совершаются только держателем
                    карты.
                </li>
                <li>
                    Авторизация операций по банковским картам осуществляется
                    банком. Если у банка есть основания полагать, что операция
                    носит мошеннический характер, то банк вправе отказать в
                    осуществлении данной операции. Мошеннические операции с
                    банковскими картами являются уголовным преступлением.
                </li>
                <li>
                    Во избежание случаев мошенничества платежи, осуществленные с
                    помощью банковской карты, могут проверяться SaaS-провайдером
                    в рамках имеющихся возможностей. Пользователь-держатель
                    карты, оформивший такой платеж, обязан по запросу,
                    поступившему от SaaS-провайдера, предоставить копию
                    необходимых SaaS-провайдеру документов для подтверждения
                    правомерного использования банковской карты. В случае
                    непредставления запрошенных документов Пользователем в
                    течение 3 (Трех) календарных дней от даты оформления платежа
                    или наличия сомнений в их подлинности, SaaS-провайдер вправе
                    приостановить Пользователю доступ к использованию Платформы
                    полностью или частично.
                </li>
                <li>
                    Пользователь самостоятельно и за свой счет несет все
                    расходы, связанные с перечислением денежных средств
                    SaaS-провайдеру, включая различные сборы и комиссии банков и
                    операторов платежных систем. Размер таких сборов и комиссий
                    определяется исключительно соответствующими банками и
                    операторами платежных систем. При этом
                    Пользователь-плательщик понимает и соглашается с тем, что
                    при совершении покупок может взиматься дополнительные
                    комиссии третьим лицам, обеспечивающим осуществление
                    Платежных операций. Размер комиссии, а также условия
                    перечисления денежных средств по каждому способу оплаты
                    указываются в интерфейсе платежной системы лица,
                    обеспечивающего осуществление платежей. SaaS-провайдер не
                    несет ответственности за действия лица, обеспечивающего
                    осуществление платежей.
                </li>
                <li>
                    При оплате банковской картой, обработка платежа (включая
                    ввод номера карты) происходит на защищенной странице
                    процессинговой системы, которая прошла международную
                    сертификацию. Это значит, что конфиденциальные данные
                    Пользователя (реквизиты карты, регистрационные данные и др.)
                    не поступают SaaS-провайдеру, их обработка полностью
                    защищена и никто, в том числе SaaS-провайдер (через
                    Платформу или иным образом), не может получить персональные
                    и банковские данные Пользователя. При работе с карточными
                    данными применяется стандарт защиты информации,
                    разработанный международными платёжными системами Visa и
                    Masterсard-Payment Card Industry Data Security Standard (PCI
                    DSS), что обеспечивает безопасную обработку реквизитов
                    Банковской карты держателя. Применяемая технология передачи
                    данных гарантирует безопасность по сделкам с Банковскими
                    картами путем использования протоколов Secure Sockets Layer
                    (SSL), Verifiedby Visa, Secure Code,и закрытых банковских
                    сетей, имеющих высшую степень защиты.
                </li>
            </ol>
            <ul>
                <li>
                    <strong>
                        <strong>Заключительные положения</strong>
                    </strong>
                </li>
            </ul>
            <ol>
                <li>
                    Во всем остальном, что не урегулировано положениями
                    настоящих Правил, Стороны руководствуются положениями
                    Соглашения и приложениями к нему, а также законодательством
                    РФ.
                </li>
                <li>
                    Признание любого положения настоящих Правил недействительным
                    либо неисполнимым и его аннулирование не должно затрагивать
                    остальных его положений, и такое
                    недействительное/неисполнимое положение подлежит замене
                    действительным/исполнимым и юридически значимым положением,
                    максимально приближенным по своему экономическому
                    содержанию, смыслу и эффекту к первоначальной редакции
                    Правил.
                </li>
                <li>
                    SaaS-провайдером могут быть реализованы иные способы
                    совершения Платежных операций, порядок совершения которых
                    будет регулироваться документом, размещенным на Платформе.
                    При использовании такого средства совершения Платежных
                    операций, Пользователь до совершения Платежной операции
                    обязуется ознакомиться с соответствующими документами,
                    регулирующими порядок совершения такой операции, и совершать
                    Платежную операцию только после принятия их условия в полном
                    объеме.
                </li>
                <li>
                    В целях дополнительной идентификации Пользователя,
                    SaaS-провайдер оставляет за собой право запросить у
                    Пользователя предоставления дополнительных сведений.&nbsp;
                </li>
                <li>
                    Продолжая использование Платформы в части совершения
                    Платежных операций с использованием любых Платежных
                    сервисов, Пользователь этим подтверждает свое согласие с
                    соответствующими пользовательскими соглашениями, иными
                    документами SaaS-провайдера, а также банковских или иных
                    организаций, предоставляющих Платежные сервисы. Пользователь
                    в максимально возможной степени подтверждает свое согласие
                    на осуществление обработки персональных данных по поручению
                    SaaS-провайдера третьими лицами, в том числе, но не
                    ограничиваясь: платежной системой, оператором фискальных
                    данных, а также соглашается на получение электронного чека
                    на телефонный номер или адрес электронной почты
                    Пользователя.
                </li>
            </ol>
            <p>
                <br />
                <br />
            </p>
        </Page>
    );
};

export default PaymentsRules;
