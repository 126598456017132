import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';
import { LegalEntityValues } from '../interfaces/legalEntity.interface';

export const legalEntityApi = createApi({
    reducerPath: 'legalEntity',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['LegalEntity'],

    endpoints: builder => ({
        createLegalInfo: builder.mutation<any, any>({
            query: body => ({
                url: '/user/legal-info',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['LegalEntity'],
        }),

        readLegalInfo: builder.query<LegalEntityValues, void>({
            query: () => {
                return {
                    url: `/user/legal-info`,
                };
            },
            transformResponse: (res: { data: LegalEntityValues }) => {
                return res.data;
            },
            providesTags: ['LegalEntity'],
        }),

        updateLegalInfo: builder.mutation<
            { code: number; message: string },
            { id: number }
        >({
            query: ({ id, ...payload }) => {
                return {
                    url: `/user/legal-info/${id}`,
                    method: 'PUT',
                    body: payload,
                };
            },
            transformResponse: (res: { code: number; message: string }) => {
                return res;
            },
            invalidatesTags: ['LegalEntity'],
        }),

        createLegalBill: builder.mutation<
            { url: string },
            { subscription_id: number; legal_id: number }
        >({
            query: body => ({
                url: '/bill/subscription',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: { url: string } }) => {
                return response.data;
            },
            invalidatesTags: ['LegalEntity'],
        }),
    }),
});

export const {
    useCreateLegalInfoMutation,
    useLazyReadLegalInfoQuery,
    useUpdateLegalInfoMutation,
    useCreateLegalBillMutation,
} = legalEntityApi;
