import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    GlobalToken,
    Row,
    Skeleton,
    Space,
    Typography,
} from 'antd';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';

import { PaymentConfirmation } from '../PaymentConfirmation';
import { format } from '../../../../lib/date';
import { Recurrent } from '../Recurrent';
import { useProfileData } from '../../../../hooks/useProfileData';
import { SubscriptionSlug } from '../../interfaces/subscription.interface';
import { Benefits } from '../Benefits';
import { LegalCreateContainer } from '../../../user/components/LegalEntity/containers/LegalCreateContainer';
import { LegalEditContainer } from '../../../user/components/LegalEntity/containers/LegalEditContainer';
import { LegalEntityValues } from '../../../user/interfaces/legalEntity.interface';

interface SubscriptionCard extends LegalEntityValues {
    subscriptionSlug: SubscriptionSlug;
    setOpenLegalInfo: any;
    openLegalInfo: boolean;
    legalBillUrl: string;
    errorCreateBill: boolean;
}
export const SubscriptionCard: React.FC<SubscriptionCard> = props => {
    const {
        name,
        email,
        phone,
        inn,
        kpp,
        id,
        loadingLegal,
        createLegalBill,
        successCreateBill,
        errorCreateBill,
        legalBillUrl: string,
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [expirationDate, setExpirationDate] = useState<string>();
    const [selectedSubscription, setSelectedSubscription] = useState({});

    const { user, subscriptions, subscription } = useProfileData();

    const token = useTheme();

    useEffect(() => {
        if (props.subscriptionSlug && subscriptions) {
            const selected = subscriptions?.filter(
                elem => elem.slug === props.subscriptionSlug
            );
            setSelectedSubscription(...selected);
        }
    }, [props.subscriptionSlug, subscriptions]);

    useEffect(() => {
        if (user) {
            setExpirationDate(
                format(user.subscription?.expiration_date)('DD.MM.YYYY')
            );
        }
    }, [user]);

    const isPaid = user?.subscription.subscription_id === 2;
    const isRecurrent = user?.subscription.isRecurrent;
    const activatedSubscription = subscriptions?.filter(
        elem => elem.slug === props.subscriptionSlug
    );

    return user && selectedSubscription ? (
        <StyledCard token={token} type='inner' style={token.Card}>
            <Row>
                <Col md={18}>
                    <Space direction={'vertical'} size={'small'}>
                        <StyledTitle level={5}>Пакет услуг</StyledTitle>
                        <StyledTitle level={3}>
                            {selectedSubscription?.name}
                        </StyledTitle>
                    </Space>
                </Col>

                <Col
                    md={6}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Space direction={'vertical'} align={'end'}>
                        <Space direction={'horizontal'}>
                            {props.subscriptionSlug ===
                            SubscriptionSlug.payJuriPlan ? (
                                <Button
                                    loading={loadingLegal}
                                    disabled={loadingLegal}
                                    type={'primary'}
                                    onClick={() => props.setOpenLegalInfo(true)}
                                >
                                    Перейти
                                </Button>
                            ) : (
                                <>
                                    {isPaid && !isRecurrent && (
                                        <Typography.Text>
                                            Действует до{' '}
                                        </Typography.Text>
                                    )}

                                    {isPaid && isRecurrent && (
                                        <Typography.Text>
                                            Автопродление
                                        </Typography.Text>
                                    )}

                                    {!isPaid && (
                                        <Typography.Text>
                                            Бесплатно до{' '}
                                        </Typography.Text>
                                    )}

                                    <Typography.Text strong={true}>
                                        {expirationDate}
                                    </Typography.Text>
                                </>
                            )}
                        </Space>

                        {props.subscriptionSlug ===
                        SubscriptionSlug.payJuriPlan ? null : isPaid ? (
                            <Recurrent expirationDate={expirationDate} />
                        ) : (
                            <Button
                                size={'small'}
                                type={'primary'}
                                onClick={() => setOpen(true)}
                            >
                                Купить за {selectedSubscription?.price}₽ в месяц
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>

            <Row>
                <Col md={18}>
                    <StyledDescription>
                        <Benefits source={selectedSubscription?.description} />
                    </StyledDescription>
                </Col>
                <Col
                    md={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <StyledPrice>
                        {selectedSubscription?.price}₽ в месяц
                    </StyledPrice>
                </Col>
            </Row>

            {open && activatedSubscription && (
                <PaymentConfirmation
                    open={open}
                    setOpen={setOpen}
                    expirationDate={user?.subscription?.expiration_date}
                    subscription={selectedSubscription}
                />
            )}

            {id ? (
                <LegalEditContainer
                    open={props.openLegalInfo}
                    setOpen={props.setOpenLegalInfo}
                    name={name}
                    email={email}
                    phone={phone}
                    inn={inn}
                    kpp={kpp}
                    id={id}
                    extraAction={createLegalBill}
                    successCreateBill={successCreateBill}
                    errorCreateBill={errorCreateBill}
                    legalBillUrl={props.legalBillUrl}
                />
            ) : (
                <LegalCreateContainer
                    open={props.openLegalInfo}
                    setOpen={props.setOpenLegalInfo}
                />
            )}
        </StyledCard>
    ) : (
        <Skeleton active />
    );
};

const StyledCard = styled(Card)<{
    $active?: boolean;
    token: GlobalToken;
    style?: any;
}>`
    flex: 1;
    width: 100%;
`;

const StyledDescription = styled.div`
    margin-top: 1.25rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
`;

const StyledPrice = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgba(37, 33, 41, 0.88);
    margin-top: 1.5rem;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0 !important;
`;
