import React, { useEffect } from 'react';
import { App, Button, Flex } from 'antd';
import { useFormik } from 'formik';

import { ShopForm } from '../../views/Shops/ShopForm';
import { useToggle } from '../../../../hooks/useToggle';
import { CREATE } from '../../../../shared/contants/actions';
import { useCreateShopMutation } from '../../services/shops';
import { createFormData } from '../../../../utils/common/createFormData';
import { ShopValues } from '../../interfaces/shop.interface';
import { useCreateUnitPayKeysMutation } from '../../../user/services';

type TShopCreate = {
    loading: boolean;
};
export const ShopCreateContainer: React.FC<TShopCreate> = props => {
    const { loading } = props;

    const { notification } = App.useApp();
    const { open, setOpen } = useToggle();

    const [createShop, { isSuccess, data: shopData }] = useCreateShopMutation();
    const [createShopKeys] = useCreateUnitPayKeysMutation();

    useEffect(() => {
        if (isSuccess && shopData) {
            notification.success({
                message: `Магазин ${shopData.name} успешно создан`,
            });

            setOpen(false);
        }
    }, [isSuccess]);

    const initialValues: ShopValues = {
        name: '',
        about: '',
        photo: null,
        buyable: false,
    };

    const onSave = async (values: ShopValues) => {
        await createShop(createFormData(values));

        if (values.buyable && shopData) {
            const data = {
                project_id: values.project_id,
                secretKey: values.secretKey,
            };
            await createShopKeys(data);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    return (
        <>
            <Flex justify={'flex-end'}>
                <Button
                    loading={loading}
                    disabled={loading}
                    onClick={() => setOpen(true)}
                    type={'primary'}
                >
                    Создать магазин
                </Button>
            </Flex>

            <ShopForm
                title={CREATE}
                setOpen={setOpen}
                open={open}
                initialValues={initialValues}
                onSave={onSave}
                isSuccess={isSuccess}
                formik={formik}
            />
        </>
    );
};
