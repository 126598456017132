import { App, Button, Form, Input, InputRef } from 'antd';
import React, { Ref, useEffect, useRef } from 'react';
import styled from 'styled-components';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import { useRequestPhoneConfirmationMutation } from '../../../../services';

const PhoneMaskedInput = React.forwardRef(
    (props: InputMaskProps, ref: Ref<InputRef>) => (
        <InputMask {...props}>
            <Input ref={ref} prefix={'+7'} />
        </InputMask>
    )
);

const isValidPhone = (phone: string): boolean => {
    const regex =
        /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/g;
    return regex.test(phone);
};

type TProps = {
    readonly phone: string;
    readonly onCancel: () => void;
    readonly onChange: (phone: string) => void;
};

export const PhoneInput: React.FC<TProps> = props => {
    const { notification } = App.useApp();

    const [requestConfirmation, { isLoading, isSuccess, isError, error }] =
        useRequestPhoneConfirmationMutation();

    const inputRef = useRef<InputRef>();

    const [form] = Form.useForm();

    const handleCancel = () => {
        props.onCancel();
    };

    const handleCodeRequest = async () => {
        const raw = form.getFieldValue('phone').replace(/[^0-9]/g, '');

        if (isValidPhone(raw)) {
            const res = await requestConfirmation({ phone: raw, code: '+7' });
            if (res.error.status === 422) {
                return notification.error({
                    message: 'Данный номер уже используется',
                });
            }
            if (res.error.status === 500) {
                return notification.error({
                    message: 'Неверный номер телефона',
                });
            }
        } else {
            form.setFields([
                {
                    name: 'phone',
                    errors: ['Неверный формат'],
                },
            ]);
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });

    useEffect(() => {
        if (isSuccess) {
            props.onChange(form.getFieldValue('phone').replace(/[^0-9]/g, ''));
        }
    }, [isSuccess]);

    return (
        <Container>
            <Form form={form} noValidate>
                <Form.Item name='phone'>
                    <Input
                        ref={inputRef}
                        prefix={'+7'}
                        maxLength={10}
                        placeholder={'(999) 999-99-99'}
                    />
                    {/*<PhoneMaskedInput ref={inputRef} mask={'(999) 999-99-99'} />*/}
                </Form.Item>
            </Form>
            <Actions>
                <Button
                    block
                    type='primary'
                    //loading={isLoading}
                    onClick={handleCodeRequest}
                >
                    Получить код
                </Button>
                <Button block onClick={handleCancel}>
                    Отмена
                </Button>
            </Actions>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
