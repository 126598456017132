import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { App, Button, Flex, Skeleton, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import {
    useLazyReadProductCategoryQuery,
    useDeleteProductCategoryMutation,
} from '../../services/categories';
import { CategoryTable } from './CategoryTable';
import { CategoryEditorModal } from './CategoryEditorModal';
import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { Uncategorized } from './categoryFilters';

export const CategoryModal = props => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const [openEditor, setOpenEditor] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [categoryId, setCategoryId] = useState(0);

    const [isEdit, setIsEdit] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const { open, setOpen } = props;

    const [getCategories, { data, isLoading }] =
        useLazyReadProductCategoryQuery();
    const [
        deleteCategory,
        { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
    ] = useDeleteProductCategoryMutation();

    const handleDeleteCategory = async (id?: number) => {
        if (id && typeof id === 'number') {
            await deleteCategory(id);
            return;
        } else {
            await Promise.all(selectedRows.map(deleteCategory));
        }
    };

    useEffect(() => {
        if (isSuccessDelete) {
            setSelectedRows([]);
            notification.success({
                message: 'Успешно удалено',
            });
        }
    }, [isSuccessDelete]);

    useEffect(() => {
        if (open) {
            getCategories({ shop_id: +id });
        }
    }, [open]);

    const handleRenameCategory = category => {
        setCategoryId(category.id);
        setCategoryName(category.name);
        setOpenEditor(true);
        setIsEdit(true);
    };

    return (
        <GenericModal
            footer={null}
            title={'Категории'}
            width={760}
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
        >
            {isLoading ? (
                <Skeleton />
            ) : (
                <Flex vertical justify={'flex-start'} gap={20}>
                    <div style={{ marginTop: '1rem' }}>
                        {selectedRows.length > 0 ? (
                            <Flex align={'center'} gap={10}>
                                <ConfirmationDialog
                                    activator={
                                        <Button
                                            disabled={isLoadingDelete}
                                            type={'primary'}
                                        >
                                            Удалить
                                        </Button>
                                    }
                                    onConfirm={() => handleDeleteCategory()}
                                    title='Удалить?'
                                    description={`Вниамние! Товары будут перенесены в "${Uncategorized}"`}
                                    withCheckbox
                                />
                                <Typography.Text>
                                    Выбрано: {selectedRows.length}
                                </Typography.Text>
                            </Flex>
                        ) : (
                            <Button
                                onClick={() => {
                                    setIsEdit(false);
                                    setOpenEditor(!openEditor);
                                }}
                                type={'primary'}
                            >
                                <PlusOutlined /> Добавить категорию
                            </Button>
                        )}
                    </div>

                    <CategoryEditorModal
                        open={openEditor}
                        setOpen={setOpenEditor}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        setCategoryId={setCategoryId}
                        setCategoryName={setCategoryName}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        categories={data?.items}
                    />

                    <CategoryTable
                        source={data}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        loading={isLoading || isLoadingDelete}
                        handleRenameCategory={handleRenameCategory}
                        handleDeleteCategory={handleDeleteCategory}
                    />
                </Flex>
            )}
        </GenericModal>
    );
};
