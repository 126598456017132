import React from 'react';
import styled from 'styled-components';
import { Flex, Image, Typography } from 'antd';

import MozhnoCoin from 'static/images/mozhno_coin.svg';

export const MozhnoLayout = () => {
    const targetUrl = 'https://t.me/mozhnostore_bot/drops';

    const handleOpenShop = () => {
        window.open(targetUrl, '_self');
    };

    return (
        <StyledLayout justify={'space-between'} vertical>
            <Flex
                gap={8}
                onClick={handleOpenShop}
                style={{ margin: 'auto', cursor: 'pointer' }}
                justify={'center'}
                align={'center'}
                vertical
            >
                <Image preview={false} src={MozhnoCoin} />
                <Typography.Text
                    style={{ color: '#ffffff', textDecoration: 'underline' }}
                >
                    mzhn.store
                </Typography.Text>
            </Flex>
        </StyledLayout>
    );
};

const StyledLayout = styled(Flex)`
    width: 100%;
    height: 100vh;
    padding: 40px;
    background: #000000;
`;
