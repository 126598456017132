export const getSrc = (s: string): string => {
    if (s && s.length) {
        let str = s;
        if (s[0] === '/') {
            str = s.slice(1);
        }
        const domain = new URL(window.ENV.API_URL).origin;
        return `${domain}/${str}`;
    }
};

export const prepareSrc = (src: string): string => {
    if (src && src.length && src.slice(0, 5) === 'https') {
        const domain = new URL(window.ENV.API_URL).origin;
        return src.replace(new RegExp(domain + '/', 'g'), '');
    } else {
        return src;
    }
};
