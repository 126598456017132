import { createBrowserRouter } from 'react-router-dom';
import { RouteObject } from 'react-router';

import { AppLayout } from 'shared/layouts';
import { ProtectedRoute } from 'shared/ui/ProtectedRoute';
import { NotFoundPage } from 'features/common/pages';
import AuthFeature from 'features/auth';
import SubscriptionFeature from 'features/subscriptions';
import UserFeature from 'features/user';
import { PublicLayout } from 'shared/layouts/PublicLayout';

import { PayLayout } from './shared/layouts/PayLayout';
import Shops from './features/shops';
import UserAgreement from './shared/layouts/PublicLayout/pages/UserAgreement';
import PersonalDataPolicy from './shared/layouts/PublicLayout/pages/PersonalDataPolicy';
import PersonalDataAgreement from './shared/layouts/PublicLayout/pages/PersonalDataAgreement';
import PaymentsRules from './shared/layouts/PublicLayout/pages/PaymentsRules';
import Oferta from './shared/layouts/PublicLayout/pages/Oferta';
import { MozhnoLayout } from './shared/layouts/MozhnoLayout';

export const routes: RouteObject[] = [
    {
        path: '/pay',
        element: (
            <ProtectedRoute>
                <PayLayout />
            </ProtectedRoute>
        ),
    },
    {
        path: '/',
        element: <MozhnoLayout />,
    },

    {
        path: '/user_agreement',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <UserAgreement />,
            },
        ],
    },
    {
        path: '/privacy_policy',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <PersonalDataPolicy />,
            },
        ],
    },
    {
        path: '/personal-data-agreement',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <PersonalDataAgreement />,
            },
        ],
    },
    {
        path: '/payment_rules',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <PaymentsRules />,
            },
        ],
    },
    {
        path: '/oferta',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <Oferta />,
            },
        ],
    },
    ...AuthFeature.routes,
    {
        path: '/app',
        element: (
            <ProtectedRoute>
                <AppLayout />
            </ProtectedRoute>
        ),
        children: [
            ...UserFeature.routes,
            ...SubscriptionFeature.routes,
            ...Shops.routes,
            {
                path: '*',
                element: <NotFoundPage />,
            },
        ],
    },
];

export const router = createBrowserRouter(routes);
