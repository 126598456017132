import React from 'react';
import { Avatar, Button, Card } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useDeleteBankCardMutation } from 'features/user/services';

type TProps = {
    readonly card: {
        readonly CardId: string;
        readonly CardType: number;
        readonly ExptDate: string;
        readonly Pan: string;
        readonly Status: string;
        readonly RebillId: string;
    };
};
export const BankCard: React.FC<TProps> = props => {
    const [deleteCard, { isLoading }] = useDeleteBankCardMutation();

    return (
        <Card type='inner'>
            <Container>
                <StyledAvatar icon={<CreditCardOutlined />} />
                <div>
                    <CardPan>{props.card.Pan}</CardPan>
                    <StyledButton
                        type='link'
                        loading={isLoading}
                        onClick={() => {
                            deleteCard(props.card.CardId);
                        }}
                    >
                        Отвязать
                    </StyledButton>
                </div>
            </Container>
        </Card>
    );
};

const Container = styled.div`
    min-width: 280px;
    display: flex;
    align-items: start;
    gap: 24px;
`;

const CardPan = styled.div`
    font-size: 16;
    font-weight: 600;
`;

const StyledButton = styled(Button)`
    padding: 0;
`;

const StyledAvatar = styled(Avatar)`
    display: flex;
    justify-content: center;
`;
