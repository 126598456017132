import { createGlobalStyle } from 'styled-components';

export const LandingStyle = createGlobalStyle`

  /* unbounded-regular - cyrillic_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Unbounded';
    font-style: normal;
    font-weight: 400;
    src: url('../../static/landing/fonts/unbounded-v7-cyrillic_latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* unbounded-600 - cyrillic_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Unbounded';
    font-style: normal;
    font-weight: 600;
    src: url('../../static/landing/fonts/unbounded-v7-cyrillic_latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

  body {
    width: 100%;
    overflow-x: hidden;
  }
  
`;
