import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { App, Button, Flex, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';

import { useLazyReadPromocodesQuery } from '../../services/promocode';

export const PromocodesHistoryContainer = () => {
    const { id } = useParams();
    const { notification } = App.useApp();

    const [filters, setFilters] = useState({
        shop_id: +id,
        limit: 10,
        offset: 0,
    });

    const [columnFilters, setColumnFilters] = useState();

    const [selectedRows, setSelectedRows] = useState([]);
    const [getPromocodes, { data, isLoading, isFetching }] =
        useLazyReadPromocodesQuery();

    useEffect(() => {
        if (filters) {
            getPromocodes(filters, true);
        }
    }, [filters]);

    const pagination = {
        total: data?.total,
        showTotal: () => `Промокодов: ${data?.total}`,
        onChange: (page, pageSize) => {
            setFilters({
                ...filters,
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
    };

    const columns = [
        {
            title: 'Промокод',
            dataIndex: 'code',
            render: code => code,
        },
        {
            title: 'Скидка',
            dataIndex: 'discount',
            render: discount => `${discount}%`,
            sorter: (a, b) => a.discount - b.discount,
        },
        {
            title: 'Кол-во использований',
            dataIndex: 'usage_limit',
            render: usage_limit => usage_limit,
            sorter: (a, b) => a.usage_limit - b.usage_limit,
        },
        {
            title: 'Активен до',
            dataIndex: 'end_date',
            render: end_date => dayjs(end_date).format('DD.MM.YYYY'),
            sorter: (a, b) =>
                dayjs(a.end_date).unix() - dayjs(b.end_date).unix(),
        },
        {
            title: 'Статус',
            dataIndex: 'is_active',
            render: isActive => (
                <Tag>{isActive ? 'Активен' : 'Не активен'}</Tag>
            ),
            sorter: (a, b) => a.isActive - b.isActivet,
        },
    ];

    const onSelectChange = (key, record) => {
        setSelectedRows(record);
    };

    const rowSelection = {
        selectedRows,
        onChange: (key, record) => onSelectChange(key, record),
    };

    const Actions = () => {
        const handleCopyPromocodes = () => {
            const text = selectedRows.map(elem => elem.code);
            navigator.clipboard
                .writeText(text)
                .then(() =>
                    notification.success({ message: 'Промокоды скопированы' })
                );
        };

        return (
            <Flex vertical={false} align={'center'} gap={16}>
                <Button onClick={handleCopyPromocodes} type={'primary'}>
                    Скопировать
                </Button>
                <Typography.Text>
                    Выбрано промокодов: {selectedRows.length}
                </Typography.Text>
            </Flex>
        );
    };

    return (
        <Flex vertical gap={16}>
            <Typography.Title style={{ margin: 0 }} level={4}>
                История промокодов
            </Typography.Title>

            {selectedRows.length > 0 && <Actions />}

            <Table
                rowSelection={rowSelection}
                loading={isLoading || isFetching}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data?.items}
                pagination={pagination}
            />
        </Flex>
    );
};
