export const createFormData = (values: any): FormData => {
    const formData = new FormData();
    const keys = Object.keys(values);
    keys.forEach(key => {
        if (Array.isArray(values[key])) {
            if (key === 'restricted_words') {
                formData.append('restricted_words[]', values[key].toString());
            } else {
                values[key].forEach(elem => {
                    formData.append(`${key}[]`, elem);
                });
            }
        } else if (
            typeof values[key] === 'object' &&
            !(values[key] instanceof File)
        ) {
            formData.append(key, JSON.stringify(values[key]));
        } else {
            formData.append(key, values[key]);
        }
    });
    return formData;
};
