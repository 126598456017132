import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Divider, Space, Typography } from 'antd';

import Logo from '../../../../static/images/logo.png';
import { useTheme } from '../../../../hooks/useTheme';
import {
    usePaySubscriptionMutation,
    useReadSubscriptionsQuery,
} from '../../services';
import getEnvConfig from '../../../../utils/config/env';
import { rub } from '../../../../shared/contants/styles';
import { Benefits } from '../Benefits';
import {
    ActivatedSubscription,
    SubscriptionSlug,
} from '../../interfaces/subscription.interface';

const initialData = {
    name: '',
    description: '',
    price: 0,
};

export const PayModal = props => {
    const { children, meta } = props;

    const token = useTheme();
    const navigate = useNavigate();
    const { data: subscriptions, isSuccess } = useReadSubscriptionsQuery();

    const [activatedSubscription, setActivatedSubscription] =
        useState<ActivatedSubscription>(initialData);

    const [paySubscription, { isLoading }] = usePaySubscriptionMutation();

    const handlePaySubscription = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const redirectUrl = await paySubscription(3).unwrap();
        window.location.replace((redirectUrl as any).data.redirect);
    };

    useEffect(() => {
        if (subscriptions) {
            const selected = subscriptions?.filter(
                elem => elem.slug === SubscriptionSlug.payJuriPlan
            );
            setActivatedSubscription(...selected);
        }
    }, [subscriptions]);

    const navigateAuthPage = () => {
        if (localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken');
        }

        navigate(`${getEnvConfig().PUBLIC_PATH}/auth/sign-in`);
    };

    return (
        isSuccess && (
            <Wrapper>
                <StyledCard type='inner' style={{ ...token.Card, width: 380 }}>
                    <img src={Logo} alt='Logo' height={30} />
                    <Heading>Продлите подписку</Heading>

                    <Description direction={'vertical'}>
                        <Typography.Text>
                            Доступ на платформу Spodial ограничен
                        </Typography.Text>
                        <Typography.Text>
                            Для возобновления доступа приобретите тариф{' '}
                            <strong>«{activatedSubscription?.name}»</strong>:
                        </Typography.Text>
                    </Description>

                    <Space direction={'vertical'}>
                        {activatedSubscription && (
                            <StyledDescription>
                                <Benefits
                                    source={activatedSubscription?.description}
                                />
                            </StyledDescription>
                        )}

                        <Typography.Title level={4}>
                            {activatedSubscription?.price}
                            {rub} в месяц
                        </Typography.Title>
                        <Button
                            disabled={isLoading}
                            type={'primary'}
                            onClick={() => handlePaySubscription()}
                        >
                            Оплатить
                        </Button>
                        <Divider />

                        <Typography.Link
                            onClick={() => navigateAuthPage()}
                            style={{ color: '#6257d2' }}
                        >
                            Вернуться на экран авторизации
                        </Typography.Link>

                        <Typography.Text>
                            Если в вашем личном кабинете остались денежные
                            средства, обратитесь в нашу службу поддержки.
                        </Typography.Text>
                    </Space>

                    {children}
                    {meta && (
                        <Actions>
                            <span>{meta?.text}</span>
                            <Link to={meta?.link}>{meta?.linkText}</Link>
                        </Actions>
                    )}
                </StyledCard>
            </Wrapper>
        )
    );
};

const StyledCard = styled(Card)`
    padding-top: 16px;

    @media (max-width: 575px) {
        box-shadow: none !important;
        border: none;
    }
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Heading = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(37, 33, 41, 0.88);
    margin-top: 24px;
`;

const Description = styled(Space)`
    margin: 1.5rem 0;
`;

const Actions = styled.div`
    margin-top: 40px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 12px;
    text-decoration: none;
`;

const StyledDescription = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
`;
