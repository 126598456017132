import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App, Skeleton } from 'antd';

import {
    useReadProductByIdQuery,
    useUpdateProductMutation,
} from '../../services/products';
import { ProductForm } from '../../views/Products/ProductForm';
import { createFormData } from '../../../../utils/common/createFormData';
import { IProducts } from '../../interfaces/products.interface';

export const ProductEditContainer = () => {
    const [initial, setInitial] = useState<IProducts>();

    const { notification } = App.useApp();
    const { id } = useParams();
    const navigate = useNavigate();

    const [updateProduct, { isSuccess: isSuccessUpdate }] =
        useUpdateProductMutation();

    const {
        data: product,
        isSuccess,
        isLoading,
    } = useReadProductByIdQuery(Number(id));

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message: 'Товар успешно изменен',
            });
            navigate(-1);
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (isSuccess) {
            setInitial({
                description: product.description,
                price: product.price,
                title: product.title,
                images: product.images,
                buyable: product.buyable,
                category_id: product.category_id,
                status: product?.status,
            });
        }
    }, [isSuccess]);

    const onSave = async (values: IProducts) => {
        const data = {
            title: values.title,
            description: values.description,
            price: values.price,
            images: values.images,
            buyable: values.buyable,
            category_id: values.category_id,
            status: values.status,
        };

        if (!values.description) {
            delete data.description;
        }

        await updateProduct({ id: Number(id), body: createFormData(data) });
    };

    return isLoading ? (
        <Skeleton />
    ) : (
        initial && (
            <ProductForm
                shopId={product?.shop_id}
                initialValues={initial}
                onSave={onSave}
            />
        )
    );
};
