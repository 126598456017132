import React from 'react';
import { Input } from 'antd';
import { FormikHelpers } from 'formik';

import { Field, Form } from 'shared/ui/Form';

import validationSchema from './validationSchema';

type TProps = {
    readonly onSubmit: (
        values: FormValues,
        formikHelpers?: FormikHelpers<FormValues>
    ) => void;

    readonly onCancel?: () => void;
};

type FormValues = {
    readonly password: string;
    readonly password_confirmation: string;
};

export const PasswordChangeForm: React.FC<TProps> = props => {
    return (
        <Form
            initialValues={{
                password: '',
                password_confirmation: '',
            }}
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}
            validationSchema={validationSchema}
        >
            <Field name='password' label='Пароль'>
                <Input.Password placeholder='От 8 до 30 знаков' />
            </Field>
            <Field name='password_confirmation' label='Повторите пароль'>
                <Input.Password placeholder='От 8 до 30 знаков' />
            </Field>
        </Form>
    );
};
