import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../../../static/images/logo.png';
import { contacts, links, payments } from './utils/data';
import { Size, useWindowSize } from '../../../hooks/useWindowSize';
import getEnvConfig from '../../../utils/config/env';

export const Footer = props => {
    const { size } = useWindowSize();

    return (
        <StyledWrapper ref={props.contactsRef}>
            <StyledContainer>
                <Row style={{ width: '100%' }}>
                    <StyledCol
                        xs={24}
                        lg={12}
                        style={{ marginBottom: '2rem' }}
                        size={size}
                    >
                        <Link to={'/'}>
                            <img height={22} src={Logo} alt={'logo spodial'} />
                        </Link>
                    </StyledCol>
                    <StyledCol xs={24} lg={5} size={size}>
                        <StyledContacts>
                            {contacts.map((c, index) => (
                                <StyledContact size={size} key={index}>
                                    <img src={c.src} alt={c.alt} />
                                    <Link
                                        to={c.href}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        <StyledLink>{c.name}</StyledLink>
                                    </Link>
                                </StyledContact>
                            ))}
                        </StyledContacts>
                    </StyledCol>
                    <StyledCol xs={24} lg={7} size={size}>
                        <Space direction={'vertical'} size={'middle'}>
                            {links.map((link, index) => (
                                <Link key={index} to={link.href}>
                                    <StyledLink>{link.name}</StyledLink>
                                </Link>
                            ))}
                        </Space>
                    </StyledCol>
                </Row>

                <StyledPayments size={size}>
                    <Space direction={'horizontal'} size={'large'}>
                        {payments.map((p, index) => (
                            <img key={index} src={p.src} alt={p.alt} />
                        ))}
                    </Space>
                </StyledPayments>
            </StyledContainer>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.footer`
    background: #f4f0ff;
`;

const StyledLink = styled(Typography.Text)`
    color: rgb(98, 87, 210);
`;

const StyledContainer = styled.div`
    display: flex;
    max-width: 1172px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 24px 3rem 24px;
    box-sizing: border-box;
    flex-direction: column;

    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledContacts = styled.div``;

const StyledContact = styled.div<{ size: string }>`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: ${props =>
        props.size === Size.large ? 'flex-start' : 'center'};
    align-items: center;
    img {
        margin-right: 0.25rem;
    }
`;

const StyledPayments = styled(Row)<{ size: string }>`
    margin-top: ${props => (props.size === Size.large ? '-2rem' : '1.25rem')};
    justify-content: ${props =>
        props.size === Size.large ? 'flex-start' : 'center'};
`;

const StyledCol = styled(Col)<{ size: string }>`
    display: flex;
    justify-content: ${props =>
        props.size === Size.large ? 'flex-start' : 'center'};
    text-align: ${props => (props.size === Size.large ? 'start' : 'center')};
`;
