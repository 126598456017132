import React, { HTMLInputTypeAttribute } from 'react';
import {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
    useField,
} from 'formik';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';

type RenderProps<T> = {
    readonly field: FieldInputProps<T>;
    readonly meta: FieldMetaProps<T>;
    readonly helpers: FieldHelperProps<T>;
};

type FieldProps<T> = {
    readonly label?: string;
    readonly required?: boolean;
    readonly name: string;
    readonly type?: HTMLInputTypeAttribute;
    readonly children:
        | ((props: RenderProps<T>) => React.ReactElement)
        | React.ReactElement;
};

export const Field = <T,>(props: FieldProps<T>) => {
    const { colorError } = useTheme();

    const { label, required, children, ...rest } = props;
    const [field, meta, helpers] = useField(rest);

    const hasError = meta.touched && meta.error;

    return (
        <StyledField>
            {label && (
                <StyledLabel required={required} color={colorError}>
                    {label}
                </StyledLabel>
            )}

            {typeof children === 'function'
                ? children({ field, meta, helpers })
                : React.cloneElement(children, { ...field })}
            {hasError && (
                <StyledError color={colorError}>{meta.error}</StyledError>
            )}
        </StyledField>
    );
};

const StyledField = styled.div`
    margin-top: 8px;
`;

const StyledError = styled.span<{ color: string }>`
    color: ${props => props.color};
`;

const StyledLabel = styled.label<{ color: string; required?: boolean }>`
    display: inline-block;
    margin-bottom: 8px;
    ${props =>
        props.required &&
        ` &::before {
        content: '* ';
        color: ${props.color};
    }`}
`;
