import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useFormik } from 'formik';

import { GenericModal } from '../../../../../../shared/ui/GenericModal';
import { GenderSelector } from '../Gender';
import { Age } from '../Age';
import { Country } from '../Country';
import { IEditorProps } from '../../../../interfaces/additionalFields.interface';

export const AuthorEditor: React.FC<IEditorProps> = props => {
    const [open, setOpen] = useState<boolean | null>(false);
    const [disabled, setDisabled] = useState<boolean | null>(false);

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSave,
    });

    useEffect(() => {
        setDisabled(formik.initialValues === formik.values);
    }, [formik]);

    useEffect(() => {
        if (props.success) {
            setOpen(false);
        }
    }, [props.success]);

    return (
        <Fragment>
            <Button onClick={() => setOpen(!open)}>
                {props.userInfoSet ? 'Изменить данные' : 'Рассказать о себе'}
            </Button>
            <GenericModal
                onConfirm={() => formik.handleSubmit()}
                title={'Рассказать о себе'}
                onCancel={() => setOpen(false)}
                okButtonProps={{
                    disabled: props.loading || disabled,
                }}
                cancelButtonProps={{
                    disabled: props.loading,
                }}
                okText={props.loading ? 'Сохраняю' : 'Сохранить'}
                open={open}
            >
                <GenderSelector formik={formik} />
                <Age formik={formik} />
                <Country formik={formik} />
            </GenericModal>
        </Fragment>
    );
};
